import { ACTION_TYPES } from "../../constants/store.constants";

const initialState = {
    refreshTokenAttempts: 0,
    allowedRetries: 0,
    refreshTokenRetryInterval: 0,
    refreshTokenExpiry: null,
    refreshTokenInterval: 0,
    refreshTokenWithRetryLogicEnabled: false
}

const SessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_APP_SETTINGS_SUCCESS:
            return {
                ...state,
                allowedRetries: action.payload?.clientConfigs?.refreshTokenMaxRetryAttemptsCount,
                refreshTokenRetryInterval: action.payload?.clientConfigs?.refreshTokenRetryInterval,
                refreshTokenExpiry: action.payload?.clientConfigs?.refreshTokenInterval,
                refreshTokenInterval: action.payload?.clientConfigs?.refreshTokenInterval,
                refreshTokenWithRetryLogicEnabled: action.payload?.clientConfigs?.enableRetryRefreshTokenLogic || false
            };
        case ACTION_TYPES.REFRESH_TOKEN:
            let refreshTokenExpiry = state.refreshTokenInterval;
            return {
                ...state,
                refreshTokenExpiry: refreshTokenExpiry,
                refreshTokenAttempts: 0
            }
        case ACTION_TYPES.REFRESH_TOKEN_FAILURE_WITH_RETRY:
            let refreshTokenAttempts = state.refreshTokenAttempts + 1;
            let failedRefreshTokenExpiry = refreshTokenAttempts < state.allowedRetries ? state.refreshTokenRetryInterval : null;
            return {
                ...state,
                refreshTokenExpiry: failedRefreshTokenExpiry,
                refreshTokenAttempts: refreshTokenAttempts
            }
        case ACTION_TYPES.REFRESH_TOKEN_FAILURE:
            return {
                ...state,
                refreshTokenExpiry: null
            }
        default:
            return state
    }
}

export default SessionReducer