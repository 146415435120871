import React from 'react';
import DialogBox from '../DialogBox/DialogBox';
import { truncateSpecialChar, getFormattedPhoneNumber} from '../../helper/commonHelper';
import translations from "../../translations";
import {
    NOTIFICATION_SERVICE_DOWN_MODAL_CONTEXT,
    MULTI_INVITE_FAILED_MODAL_CONTEXT,
    MULTI_INVITE_SPINNER_MODAL_CONTEXT,
    PHONE_NOTIFICATION_TYPES
} from '../../constants/guest.constants';

const MODAL_CONTEXT_MAPPED_DETAILS = {
    [MULTI_INVITE_FAILED_MODAL_CONTEXT]: {
        title: translations.INVITATIONS_FAILED_TITLE,
        body: translations.INVITATIONS_FAILED_BODY
    },
    [NOTIFICATION_SERVICE_DOWN_MODAL_CONTEXT]: {
        title: translations.NOTIFICATION_SERVICE_DOWN_TITLE,
        body: translations.NOTIFICATION_SERVICE_DOWN_BODY
    },
    [MULTI_INVITE_SPINNER_MODAL_CONTEXT]: {
        title: (<>
            {<div className="vve-loader"></div>}
            {translations.INVITE_SENDING}
        </>)
    }
};

export const NotificationServiceDownModal = ({ onCloseHandler }) => {
    const { title, body } = MODAL_CONTEXT_MAPPED_DETAILS[NOTIFICATION_SERVICE_DOWN_MODAL_CONTEXT];
    const dialogboxProps = { 
        showModal: true, 
        modalTitle: title, 
        modalBody: body, 
        classes: {
            modalRoot: 'serviceDownModal'
        },
        onClose: onCloseHandler 
    };
    return <DialogBox {...dialogboxProps}/>
};

export const FailedMultiInvitesModal = ({ failedNotifications, onCloseHandler}) => {
    const { title, body } = MODAL_CONTEXT_MAPPED_DETAILS[MULTI_INVITE_FAILED_MODAL_CONTEXT];
    const modalBody = <div style={{whiteSpace: "break-spaces"}}> {body} </div>;
    const modalBody2 = failedNotifications.map((notificationItem, index) =>  {
        const { notificationId, notificationType } = notificationItem;
        const formattedValue = PHONE_NOTIFICATION_TYPES.includes(notificationType) ? getFormattedPhoneNumber(truncateSpecialChar(notificationId)) : notificationId;
        return (<div key={notificationId} className={index === failedNotifications.length - 1 ? 'lastNotification' : ''}>{formattedValue}<br /></div>);
    });
    const dialogboxProps = {
        showModal: true, 
        modalTitle: title, 
        modalBody, 
        modalBody2, 
        classes: {
            modalRoot: 'multiInviteFailedModal'
        },
        onClose: onCloseHandler 
    };
    return <DialogBox {...dialogboxProps}/>
};

export const NotificationTrackingModal = ({ onCloseHandler }) => {
    const { title } = MODAL_CONTEXT_MAPPED_DETAILS[MULTI_INVITE_SPINNER_MODAL_CONTEXT];
    const dialogboxProps = { 
        showModal: true, 
        modalTitle: title, 
        classes: {
            modalRoot: 'loaderModalRoot'
        },
        onClose: onCloseHandler, 
        displayFooter: false 
    };
    return <DialogBox {...dialogboxProps}/>
};