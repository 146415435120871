import React from "react";
import Card from "react-bootstrap/Card";
import { Container } from "react-bootstrap";
import GroupClassTable from "../GroupClassTable/GroupClassTable";
import GroupDetailCard from "../GroupDetailCard/GroupDetailCard";

const GroupClassDetails = (groupClassDetailsProps) => {
  const { groupClassDetails: { visitTypeName }, participants } = groupClassDetailsProps;

  return (
    <Container className="vve-group-class-table">
      <Card>
        <Card.Header className="vve-text-bold">{visitTypeName}</Card.Header>
        <Card.Body>
          <GroupDetailCard {...groupClassDetailsProps} />
          <hr className="vve-divider vve-divider-top" />
          <hr className="vve-divider vve-divider-bottom" />
          <Card.Text>
            <GroupClassTable participantList={participants}/>
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default GroupClassDetails;
