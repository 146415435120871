import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListGroup from "react-bootstrap/ListGroup";
import { UpdateSelectedDepartment } from "../../store/actions/DepartmentListAction";
import {attachHoverforEllipsedText} from "../../helper/oncallUtils";
import { STORE_NAMES } from "../../constants/store.constants";
import facility_image from "../../assets/images/oncall_dept.svg";
import Image from "../Image/Image";
import { UpdateSelectedProvider } from "../../store/actions/ProviderListAction";
import Loader from "../Loader/Loader";

const DepartmentList = ({ fwdbtnclick, matrixindex }) => {
  const dispatch = useDispatch();
  const [selectedItemIndex, setSelectedItemIndex] = useState();
  const { departmentListData = [], selectedDepartment, loader } = useSelector(
    (state) => state[STORE_NAMES.ONCALL_DIRECTORY_DEPARTMENTLIST]
  );
  
  const onDepartmentItemClicked = (index, department) => {
    setSelectedItemIndex(index);
    dispatch(UpdateSelectedDepartment({id:department.id, name:department.name, mc: department.mc}));
    fwdbtnclick(matrixindex);
    dispatch(UpdateSelectedProvider(null));
  };

  const departmentList = departmentListData?.map((item, index) => {
    return (
      <ListGroup.Item
        action="true"
        id={item.id}
        key={index}
        role="button"
        className={`${(selectedDepartment && index === selectedItemIndex) ? "vve-oncall-selected-dept" : ""}`}
      >
        <div
          className="d-flex align-items-center"
          data-testid={"DeptItem"+index}
          onClick={() => {
            onDepartmentItemClicked(index, item);
          }}
        >
          <Image
            className="vve-oncall-facility-icon"
            src={facility_image}
            role="img"
            alt="facility_icon.svg"
          />
          <div className="ms-3">
            <p className="mb-1 vve-department-map-list">{`${item.name}`}</p>
          </div>
        </div>
      </ListGroup.Item>
    );
  });

  useEffect(() => {
    attachHoverforEllipsedText("mb-1 vve-department-map-list");
  }, [departmentListData]);

  return (
    <div className="vve-department-list-container">
      {loader ? <Loader showLoader={loader} /> : departmentListData.length === 0 ? ( //no results found
        <div data-testid="notfound" className="vve-oncall-no-results-found"></div>
      ) : (
        <ListGroup
          id="vve-department-list"
          data-testid="list-container"
          className="vve-department-list"
          role="document"
        >
          {departmentList}
        </ListGroup>
      )}
    </div>
  );
};

export default DepartmentList;
