import { ACTION_TYPES } from '../../constants/store.constants';

const CreateMedicalCentersList = (payload) => {
    return {
        type: ACTION_TYPES.CREATE_MEDICALCENTERSLIST,
        payload: payload
      };
}

export {
    CreateMedicalCentersList
}
