const APP_POPOUT_SIZE = { width: 360, height: 680 };
const APP_DEVICE_TYPE = {
    mobile: "mobile",
    desktop: "desktop"
}
const APP_CAPABILITIES = [
    //APIs
    "getMeetingContext",
    "getUserContext",
    "getMeetingJoinUrl",
    "getMeetingUUID",
    "openUrl"
    //"getMeetingParticipants",
    //Events
    //"onParticipantChange"
];

const DIRECTORY_APP_CAPABILITIES = [
    "openUrl",
];

export {
    APP_POPOUT_SIZE,
    APP_CAPABILITIES,
    APP_DEVICE_TYPE,
    DIRECTORY_APP_CAPABILITIES
};
