import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ListGroup from 'react-bootstrap/ListGroup';
import InterpreterAction from '../../store/actions/InterpreterAction';
import ServiceAreaAction from '../../store/actions/ServiceAreaAction';
import { focusTo } from '../../helper/domHelper';
import { STORE_NAMES } from '../../constants/store.constants';
import { SELECT_INTERPRETER_AUDIO } from '../../constants/interpreter.constants';
import { INVITE_BUTTON_ID } from "../../constants/dom.constants";

const ServiceAreaList = ({interpreterType}) => {
  const dispatch = useDispatch();
  const { contact_number: serviceAreaList = [] } = useSelector((state) => state?.[STORE_NAMES.INTERPRETER_AUDIO_INFO]);
  const { disableServiceArea } = useSelector((state) => state?.[STORE_NAMES.INTERPRETER]);
  const [ selectedServiceArea, setSelectedServiceArea ] = useState(null);

  const selectServiceArea = (serviceAreaItem) => {
    const serviceAreaId = serviceAreaItem ? Object.keys(serviceAreaItem).shift() : null;
    const serviceAreaDetails = serviceAreaId ? serviceAreaItem?.[serviceAreaId] : null;
    setSelectedServiceArea(serviceAreaDetails);
    dispatch(ServiceAreaAction.setInterpreterServiceArea(serviceAreaDetails));
  };

  const selectFirstServiceArea = () => {
    if(Array.isArray(serviceAreaList) && serviceAreaList.length > 0) {
      selectServiceArea([...serviceAreaList].shift());
    }
  };

  const onServiceAreaItemClick = (serviceAreaItem) => {
    selectServiceArea(serviceAreaItem);
    focusTo(INVITE_BUTTON_ID);
  };

  const toggleItemDisabledStatus = (status) => {
    if (Array.isArray(serviceAreaList) && serviceAreaList.length > 0 && status!==null) {
      serviceAreaList.forEach(serviceAreaItem => serviceAreaItem.disabled = status);
      status ? selectServiceArea(null) : selectFirstServiceArea();
    }
  };

  useEffect(() => {
    dispatch(InterpreterAction.setInterpreterType(interpreterType));
    dispatch(InterpreterAction.setInterpreterLanguage(null));
    dispatch(ServiceAreaAction.disableServiceArea(null));
    selectFirstServiceArea();
    toggleItemDisabledStatus(false);
  }, []);

  useEffect(() => {
    toggleItemDisabledStatus(disableServiceArea);
  }, [disableServiceArea]);

  const serviceAreaItems = Array.isArray(serviceAreaList) ?
                            serviceAreaList?.map(
                              (item, index) => {
                                const serviceAreaId = Object.keys(item).shift();
                                const serviceAreaDetails = item?.[serviceAreaId];
                                return <ListGroup.Item 
                                          action="true"
                                          role='button'
                                          as='button'
                                          data-testid="list-item"
                                          id={serviceAreaDetails?.name}
                                          key={serviceAreaId}
                                          eventKey={serviceAreaDetails?.name}
                                          active={selectedServiceArea?.name===serviceAreaDetails?.name}
                                          disabled={item?.disabled || false}
                                          onClick={() => onServiceAreaItemClick(item)}>
                                            {serviceAreaDetails?.name}
                                        </ListGroup.Item>
                            }) : null;
  
  return (
    Array.isArray(serviceAreaList) && serviceAreaList.length > 0 && (
      <>
        <p>{SELECT_INTERPRETER_AUDIO}</p>
        <ListGroup className="vve-language-list" role="document">
          {serviceAreaItems}
        </ListGroup>
      </>
    )
  );
};

ServiceAreaList.propTypes = {
  interpreterType: PropTypes.any
};

export default ServiceAreaList;