import React from "react";
import Table from "react-bootstrap/Table";
import { ATTENDANCE_TYPE, JOIN_TIME, LEAVE_TIME, NAME } from "../../constants/report.constant";

const GroupClassTable = ({ participantList }) => {
  return (
    <Table hover bordered className="vve-group-class-table">
      <thead>
        <tr className="vve-text-bold">
          <th>{NAME}</th>
          <th className="vve-text-center">{ATTENDANCE_TYPE}</th>
          <th className="vve-text-center">{JOIN_TIME}</th>
          <th className="vve-text-center">{LEAVE_TIME}</th>
        </tr>
      </thead>
      <tbody>
      {participantList?.map((participant) => (
         <tr key={participant.name}>
          <td>{participant.name}</td>
          <td className="vve-text-center">{participant.role}</td>
          <td className="vve-text-center">{participant.joinTime}</td>
          <td className="vve-text-center">{participant.leaveTime}</td>
        </tr>
      ))}
      </tbody>
    </Table>
  );
};

export default GroupClassTable;