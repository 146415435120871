/* Module Dependencies */
import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

/* Constants */
import BUTTON_CONFIG from "../../constants/button.constants";

/* Declare the component */
const FilterModal = ({
  showModal,
  onClose,
  modalTitle,
  modalBody,
  resetEnable,
  onReset,
  onApply,
}) => {
  
  // Return component
  return (
    <Modal
      className={`vve-zoom vve-filter-modal-root`}
      backdrop="static"
      show={showModal}
      onHide={onClose}
    >
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalBody}</Modal.Body>
      <Modal.Footer>
        <div className="vve-zoom-modal-footer vve-filter-modal-footer">
          <Button variant="secondary" disabled={!resetEnable} onClick={onReset}>
            {BUTTON_CONFIG.RESET_BUTTON.title}
          </Button>
          <div className="vve-filter-modal-actions">
            <Button variant="secondary" onClick={onClose}>
              {BUTTON_CONFIG.CANCEL_BUTTON.title}
            </Button>
            <Button variant="primary" onClick={onApply}>
              {BUTTON_CONFIG.APPLY_FILTERS_BUTTON.title}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

/* Export component */
export default FilterModal;
