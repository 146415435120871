import React, { useState } from 'react';
import { SHARE_POINT_HEADER, SERVICE_NOW_HEADER, CUSTOMER_SERVICE_CLINICIAN_HEADER, CUSTOMER_SERVICE_PATIENT_HEADER } from '../../constants/interpreter.constants';
import SupportNumbersList from '../SupportNumbersList/SupportNumbersList';
import { STORE_NAMES, ACTION_TYPES } from "../../constants/store.constants";
import { useSelector, useDispatch } from "react-redux";
import translations from "../../translations";
import Button from '../../components/Button/Button';

/** Share Point Logic Start */
import DialogBox from '../DialogBox/DialogBox';
import ZoomAppClient from '../../helper/zoomAppClient';
import { SharePointModalTitle, SharePointModalBody } from '../../constants/addSupport.constants';
import { MODAL_ACTION_BUTTON_CLASSES } from "../../constants/dom.constants";
/** Share Point Logic end */

const SupportContainer = () => {
    const dispatch = useDispatch();
    const { supportNumbers } = useSelector(state => state?.[STORE_NAMES.SUPPORT_TAB_INFO]);
    const patientNumbers = supportNumbers?.patients;
    const clinicianNumbers = supportNumbers?.clinician;
    const serviceNowTabSelected = () => {
        dispatch({ type: ACTION_TYPES.FETCH_SUPPORT_CURRENT_TAB, payload: 'SNOW' });
    };
    /** Share Point Logic Start */
    const { sharepointLink } = useSelector(state => state?.[STORE_NAMES.APP]);
    const [displaySharePointModal, setDisplaySharePointModal] = useState(false);
    const handleSharePointClick = () => {
        setDisplaySharePointModal(true);
    };
    const handleSharePointModalClose = () => {
        setDisplaySharePointModal(false);
    };
    const handleSharePointModalContinue = () => {
        ZoomAppClient.openExternalUrl(sharepointLink);
        setDisplaySharePointModal(false);
    };
    /** Share Point Logic end */

    return (
        <div className="vve-add-support-container">
            <section className='vve-padding-bottom'>

                <div className="row">
                    <div className="col col-md-10 vve-support-header">{SHARE_POINT_HEADER}</div>
                    <div className="col col-md-2 vve-text-right">
                        <Button onClick={handleSharePointClick} class="vve-caret-btn"></Button>
                    </div>
                </div>
                <p className='vve-support-content vve-text-alignment'>
                    {translations.SHARE_POINT_CONTENT}
                </p>
            </section>
            <hr className="row vve-padding-top" />
            <section className='vve-padding-bottom'>

                <div className="row">
                    <div className="col col-md-10  vve-support-header">{SERVICE_NOW_HEADER}</div>
                    <div className="col col-md-2 vve-text-right">
                        <Button class="vve-caret-btn" onClick={serviceNowTabSelected}></Button>
                    </div>

                </div>
                <p className='vve-support-content vve-text-alignment'>
                    {translations.SERVICE_NOW_CONTENT}
                </p>
            </section >
            <hr className="row vve-padding-top" />
            <section className='vve-padding-bottom'>

                <div className="row">
                    <div className="col col-md-12  vve-support-header">{CUSTOMER_SERVICE_CLINICIAN_HEADER}</div>
                    <div className="">
                    </div>
                </div>
                <p className='vve-support-content'>
                    {translations.CUSTOMER_SERVICE_CLINICIAN_CONTENT}
                    <span className='vve-support-info'>
                        {translations.CUSTOMER_SERVICE_CLINICIAN_CONTENT2}
                    </span>
                </p>
                <SupportNumbersList customerservicenumbers={clinicianNumbers} />
            </section>
            <hr className="row vve-padding-top" />
            <section className='vve-padding-bottom'>

                <div className="row">
                    <div className="col col-md-12  vve-support-header">{CUSTOMER_SERVICE_PATIENT_HEADER}</div>
                    <div className="">

                    </div>

                </div>
                <p className='vve-support-content'>
                    {translations.CUSTOMER_SERVICE_PATIENT_CONTENT1}
                    <span className='vve-support-info'>
                        {translations.CUSTOMER_SERVICE_PATIENT_CONTENT2} {translations.CUSTOMER_SERVICE_PATIENT_CONTENT3}
                    </span>
                </p>

                <SupportNumbersList customerservicenumbers={patientNumbers} />
            </section>
            <DialogBox showModal={displaySharePointModal}
                modalTitle={SharePointModalTitle}
                modalBody={SharePointModalBody}
                useStackedActionButtons={true}
                onContinue={handleSharePointModalContinue}
                onClose={handleSharePointModalClose}
                classes={MODAL_ACTION_BUTTON_CLASSES}>
            </DialogBox>
        </div>
    );


};

export default SupportContainer;