import apiClient from "../helper/apiClient";
import { SERVICE_ROUTES } from "../constants/service.constants";
import MOCK_CLINICIANLIST_RESPONSE from '../mock/clinician-list.json';

export const getClinicianList = async (
  params,
  callBackFn,
  abortController
) => {
  if (process.env.NODE_ENV !== 'development') {
    try {
      const httpClient = apiClient.getClincianAppBffInstance();
      const { region, ...otherParams } = params;
      const endPoint = SERVICE_ROUTES.FETCH_CLINICIAN_LIST.replace('{region}', region);
      const response = await httpClient.get(endPoint, { params: otherParams, signal: abortController?.signal });
      if (response?.data) {
        const pageParams = {
          ...params,
          canStopScrolling: +response.pageSettings?.totalNumberOfRecords <= +response.pageSettings?.nextStartIndex,
        };

        callBackFn(pageParams, response.data);
      }

    } catch (error) {
      return { error: error };
    }
  }
  else {
    callBackFn(params, MOCK_CLINICIANLIST_RESPONSE);
  }
};

export const getClinicianDetailnextOncall = async (
  params,
  callBackFn
) => {
  try {
    const httpClient = apiClient.getClincianAppBffInstance();
    const { region, ...otherParams } = params;
    const endPoint = SERVICE_ROUTES.FETCH_NEXT_ONCALL_CLINICIAN.replace('{region}', region);
    const response = await httpClient.get(endPoint, { params: otherParams });
    callBackFn(response);
  } catch (error) {
    callBackFn({ error: error });
  }
};

export const getClinicianMedicalCenters = async (selectedRegion, callBackFn) => {
  try {
    const httpClient = apiClient.getClincianAppBffInstance();
    const endPoint = SERVICE_ROUTES.FETCH_CLINICIAN_MEDICAL_CENTERS_ROUTE.replace('{region}', selectedRegion);
    const response = await httpClient.get(endPoint);

    if (response?.data) {
      callBackFn(response.data);
      fetchAllData(httpClient, endPoint, callBackFn, response.pageSettings);
    }
  } catch (error) {
    return { error: error };
  }
};

const fetchAllData = async (httpClient, endPoint, callBackFn, pageSettings) => {

  const canFetchFurther = (pageSettings?.totalNumberOfRecords > pageSettings?.nextStartIndex);
  if (canFetchFurther) {
    const defaultParams = {
      limit: 200,
      offset: pageSettings.nextStartIndex,
    };

    const response = await httpClient.get(endPoint, { params: defaultParams });
    if (response?.data) {
      callBackFn(response.data);
      fetchAllData(httpClient, endPoint, callBackFn, response.pageSettings);
    }
  }
}

export const getClinicianDeptListOnMC = async (
  uriParams,
  callBackFn,
) => {
  try {
    const httpClient = apiClient.getClincianAppBffInstance();
    let endPoint = SERVICE_ROUTES.FETCH_CLINICIAN_DEPARTMENT_BY_MEDICAL_CENTER_ROUTE;
    Object.keys(uriParams).forEach((key) => {
      endPoint = endPoint.replace(`{${key}}`, uriParams[key]);
    });
    const response = await httpClient.get(endPoint);

    if (response?.data) {
      callBackFn(response.data);
      fetchAllData(httpClient, endPoint, callBackFn, response.pageSettings);
    }
  } catch (error) {
    return { error: error };
  }
};

export const getFileteredClinicianList = async (
  uriParams,
  params,
  callBackFn
) => {
  if (process.env.NODE_ENV !== 'development') {
    try {
      const uriParamsKeys = Object.keys(uriParams); // Get the keys from the uirParams Object
      const isDeparmentAvailable = uriParamsKeys.indexOf('departmentid') > -1; // check department is present in uirParams
      let endPoint;
      if (isDeparmentAvailable) {
        endPoint = SERVICE_ROUTES.FETCH_CLINICIAN_FILTERED_LIST;
      } else {
        endPoint = SERVICE_ROUTES.FETCH_CLINICIAN_FILTERED_BY_LIST_MEDICAL_CENTERS;
      }
      const { ...otherParams } = params;

      const httpClient = apiClient.getClincianAppBffInstance();
      uriParamsKeys.forEach((key) => {
        endPoint = endPoint.replace(`{${key}}`, uriParams[key]);
      });
      const response = await httpClient.get(endPoint, { params: otherParams });
      if (response?.data) {
        const pageParams = {
          ...params,
          canStopScrolling: +response.pageSettings?.totalNumberOfRecords <= +response.pageSettings?.nextStartIndex,
        };

        callBackFn(pageParams, response.data);
      }

    } catch (error) {
      return { error: error };
    }
  }
  else {
    callBackFn(params, MOCK_CLINICIANLIST_RESPONSE);
  }
};
