import { ACTION_TYPES } from '../../constants/store.constants';
import { postSnowApi } from '../../service/snow.service';
import setErrorAction from "./errorActions";


const resetSnowForm = (data) => ({
	type: ACTION_TYPES.RESET_SNOW_FORM,
	payload: data
});

const postSupportAction = (snowPayload) => async (dispatch) => {
	try {
		if(snowPayload) {
			dispatch({type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: false});
			const response = await postSnowApi(snowPayload);
			if (response?.status) {
				dispatch({ type: ACTION_TYPES.INVITE_BUTTON_SNOW_INCIDENT, payload: response.incidentNumber});
				dispatch({ type: ACTION_TYPES.INVITE_BUTTON_SNOW_STATUS, payload: response.status});
				dispatch({ type: ACTION_TYPES.INVITE_BUTTON_CLICKED, payload: {inviteButtonClicked: true, status: true} });
			}
		}
        
	} catch (err) {
		dispatch(setErrorAction.setErrorHandler(err));
	}
}

export default {
	postSupportAction,
	resetSnowForm
};