/* Module Dependencies */
import React from 'react';
import PropTypes from 'prop-types';

/* Constants */
import BUTTON_CONFIG from '../../constants/button.constants';

/* Declare the component */
const Search = ({ ...props }) => {
  const { query, isFavoriteToggle, onSearchInput, toggleFilterModal } = props;

  // Return component
  return (
    <div className={`${isFavoriteToggle ? 'vve-search-filter-disable' : 'vve-search-filter-container'}`}>
      <div className="vve-directory-search-container">
        <input
          className={`vve-directory-search-input ${isFavoriteToggle ? 'vve-gray-magnifying-icon' : 'vve-magnifying-icon'}`}
          type="search"
          placeholder="Search"
          value={query}
          onChange={onSearchInput}
          disabled={isFavoriteToggle}
        />
      </div>
      <div className="vve-vertical-line"></div>
      <div className="vve-filter-container" onClick={() => !isFavoriteToggle ? toggleFilterModal() : null}>
        <span className='vve-filter-title'>{BUTTON_CONFIG.FILTER_BUTTON.title}</span>
        <div
          data-testid="filtericon"
          className="vve-filter-icon"
        ></div>
      </div>
    </div>

  );
};

/* PropTypes */
Search.propTypes = {
  query: PropTypes.string.isRequired,
  onSearchInput: PropTypes.func.isRequired,
  toggleFilterModal: PropTypes.func.isRequired
}

/* Export component */
export default Search;
