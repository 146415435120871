import { ALLOWED_ABRIDGE_STATUSES, NONE } from '../../constants/abridgePatientConsent.constants';
import { ACTION_TYPES } from '../../constants/store.constants';
import { fetchPatientAbridgeConsent } from '../../service/abridge.service';

const fetchPatientAbridgeConsentAction = (payload) => async (dispatch) => {
	try {
        const { consent: { status = NONE } = {} } = await fetchPatientAbridgeConsent(payload);
        if (ALLOWED_ABRIDGE_STATUSES.includes(status)) {
            dispatch({ type: ACTION_TYPES.SET_PATIENT_ABRIDGE_CONSENT, payload: {abridgeConsentDetails: {status}} });
        }
	} catch (err) {}
}


export default {
    fetchPatientAbridgeConsentAction
}
