import React from 'react';
import { Routes, Route } from "react-router-dom";

import Main from '../components/Main/Main';
import DirectoryTabs from '../components/DirectoryTabs/DirectoryTabs';
import GenerateAttendeeReport from '../components/Reports/GenerateAttendeeReport';
import Error from '../components/ErrorPage/Error';
import AuthConfirm from '../components/AuthConfirmationPage/AuthConfirm.js'
import Layout from '../container/Layout';
import RedirectTo from '../components/RedirectTo/RedirectTo';
import ErrorPageDecisionMaker from '../components/ErrorPageDecisionMaker/ErrorPageDecisionMaker';
import { 
  ERROR_TYPE_AUTH, 
  ERROR_TYPE_PANEL_MSG, 
  ERROR_TYPE_INITIALIZATION_ERROR, 
  ERROR_TYPE_APP_UNAVAILABLE,
  INTEGRATED_APP_UNAVAILABLE,
  NON_INTEGRATED_APP_UNAVAILABLE
} from '../constants/error.constants';

const AppRouter = () => {
  return (
    <Routes>
      <Route element={<ErrorPageDecisionMaker />}>
        <Route exact path="/home" element={<Main />} />
        <Route exact path="/directory" element={<DirectoryTabs />} />
      </Route>
      <Route exact path="/download-attendee-report" element={<GenerateAttendeeReport />} />
      <Route element={<Layout />}>
        <Route exact path="/unauthorized" element={<Error />} />
        <Route exact path="/authenticated" element={<AuthConfirm type={ERROR_TYPE_AUTH}/>} />
        <Route exact path="/panelmessage" element={<AuthConfirm type={ERROR_TYPE_PANEL_MSG}/>} />
        <Route exact path="/error" element={<AuthConfirm type={ERROR_TYPE_INITIALIZATION_ERROR}/>} />
        <Route exact path="/appunavailable" element={<AuthConfirm type={ERROR_TYPE_APP_UNAVAILABLE}/>} />
        <Route exact path="/integratedunavailable" element={<AuthConfirm type={INTEGRATED_APP_UNAVAILABLE}/>} />
        <Route exact path="/nonintegratedunavailable" element={<AuthConfirm type={NON_INTEGRATED_APP_UNAVAILABLE}/>} />
        <Route exact path="/directoryunavailable" element={<Error />} />
        <Route exact path="/redirect" element={<RedirectTo />} />
        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  )
};

export default AppRouter;