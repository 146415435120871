const buttonConstants = {
    INVITE_BUTTON: {
        title: "Invite"
    },
    REGION_SELECTION: {
        title: "Select"
    },
    RESET_BUTTON: {
        title: "Reset"
    },
    CANCEL_BUTTON: {
        title: "Cancel"
    },
    FILTER_BUTTON: {
        title: "Filter"
    },
    APPLY_FILTERS_BUTTON: {
        title: "Apply Filters"
    },
    BACK_BUTTON: {
        title: "Back"
    },
    DOWNLOAD_BUTTON: {
        title: "Download (.xls)"
    },
    REFRESH_BUTTON: {
        title: "Refresh"
    }
};
export default buttonConstants;
