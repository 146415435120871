import { ACTION_TYPES } from '../../constants/store.constants';

const setInviteButtonActiveStatus = (data) => ({
	type: ACTION_TYPES.ENABLE_INVITE_BUTTON,
	payload: data
});

const setInviteButtonClickedStatus = (data,status) => ({
	type: ACTION_TYPES.INVITE_BUTTON_CLICKED,
	payload: {
		inviteButtonClicked: data,
		status: status,
		loading: false 
	}
});


export default {
	setInviteButtonActiveStatus,
	setInviteButtonClickedStatus
};