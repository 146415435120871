import { NUID_ID_TYPE } from "../constants/app.constants";
import { 
    NOTIFICATIONS_SENT_STATUSES, 
    ALLOWED_POLLING_STATUSES,
    MULTI_INVITE_STATUS_CHECK_CANCEL,
    C2C_NOTIFICATION_TYPE_MAP
} from "../constants/guest.constants";
import { getTruncatedMobileNumbers } from "./commonHelper";

const getParsedTrackingDetails = (inviteGuestResponse, inviteGuestRequest) => {
    const { trackingDetails = null } = inviteGuestResponse;
    let parsedDetails = null;
    if(trackingDetails && Array.isArray(trackingDetails) && trackingDetails.length > 0) {
        const { invitee } = inviteGuestRequest;
        const mapTrackingDetailsWithNotificationData = trackingDetails.map((item, index) => {
            const { notificationId, notificationType } = invitee[index];
            return { ...item, notificationId, notificationType };
        });
        // only set valid tracking details
        const validTrackingData = mapTrackingDetailsWithNotificationData.filter((item) => {
            const { eventName = null, trackingId = null, correlationId = null } = item;
            return eventName && trackingId && correlationId;
        });
        parsedDetails = validTrackingData.length > 0 ? validTrackingData : null;
    }
    return parsedDetails;
};

const checkForAllNotificationsSentStatus = (trackingDetails) => {
    return trackingDetails.every(({ status }) => NOTIFICATIONS_SENT_STATUSES.includes(status));
};

const filterTrackingDetailsByStatus = (trackingDetails, statusListToCheck) => {
    return trackingDetails.filter(({ status }) => statusListToCheck.includes(status));
}

const prepareMultiInviteStatusCheckRequest = (trackingDetails, isLastStatusCheck = false) => {
    const pollingReadyTrackingDetails = filterTrackingDetailsByStatus(trackingDetails, ALLOWED_POLLING_STATUSES);
    const parsedDetails = pollingReadyTrackingDetails.map(({ status, notificationId, notificationType, ...parsedItem }) => parsedItem);
    const requestData = { trackingIds: parsedDetails };
    if(isLastStatusCheck) {
        requestData.action = MULTI_INVITE_STATUS_CHECK_CANCEL;
    }
    return requestData;
};

const updateTrackingDetailsStatus = (trackingDetails, statusCheckResponse) => {
    trackingDetails.forEach((trackingItem )=> {
        const statusMatchedItem = statusCheckResponse.find((statusCheckItem) => statusCheckItem?.trackingId===trackingItem?.trackingId);
        if(statusMatchedItem) {
            trackingItem.status = statusMatchedItem.deliveryStatus;
        }
    });
};

const getParsedTrackingDetailsForC2CInvite = (inviteClinicianResponse, { id, idType }) => {
    const {
        meetingDetails: {
            startUrl: zoomMeetingStartUrl ,
            joinUrl: zoomMeetingJoinUrl
        } = {},
        trackingDetails = []
    } = inviteClinicianResponse;

    trackingDetails.forEach(item => {
        item.notificationId = id;
        item.notificationType = idType;
    });

    const validTrackingDetails = trackingDetails.filter((item) => {
        const { eventName = null, trackingId = null, correlationId = null } = item;
        return eventName && trackingId && correlationId;
    });

    return (validTrackingDetails.length > 0 && zoomMeetingStartUrl && zoomMeetingJoinUrl) ? { validTrackingDetails, zoomMeetingStartUrl, zoomMeetingJoinUrl } : null;
};

const prepareC2CDependencies = ({ selectedClinicianData, clinicianToClinicianInviteConfig }) => {
    const {
        acctno: nuid,
        firstName,
        lastName,
        region,
        contactDetails: {
            mobilePhone = null
        } = {}
    } = selectedClinicianData ?? {};

    const { enableC2CInvite, dnpRegionMap } = clinicianToClinicianInviteConfig;
    const inviteeRegion = dnpRegionMap[region] ?? region;
    const parsedMobile = getTruncatedMobileNumbers(mobilePhone);

    return {
        enableC2CInvite,
        invitee: {
            id: nuid,
            idType: NUID_ID_TYPE,
            region: inviteeRegion,
            firstName,
            lastName
        },
        notification: {
            id: parsedMobile,
            idType: C2C_NOTIFICATION_TYPE_MAP.sms
        }
    };
};

export {
    getParsedTrackingDetails,
    checkForAllNotificationsSentStatus,
    filterTrackingDetailsByStatus,
    prepareMultiInviteStatusCheckRequest,
    updateTrackingDetailsStatus,
    getParsedTrackingDetailsForC2CInvite,
    prepareC2CDependencies
};