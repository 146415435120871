export const focusTo = (id) => {
    const elem = document.getElementById(id);
    if(elem) {
        elem.setAttribute('tabindex', '0');
        elem.addEventListener('focusout', function listener() {
            elem.removeAttribute('tabindex');
            elem.removeEventListener('focusout', listener);
        });
        elem.focus();
    }
};

export const downloadFileResource = (containerClass, url, fileName) => {
    const containerElement = document.querySelector(`.${containerClass}`);
    const fileAnchor = document.createElement("a");
    fileAnchor.setAttribute("href", url);
    fileAnchor.setAttribute("download", fileName);
    containerElement.appendChild(fileAnchor);
    fileAnchor.click();
    containerElement.removeChild(fileAnchor);
};