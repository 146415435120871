
import React from 'react';
import translations from "../../translations";
import InformationBlock from '../InformationBlock/InformationBlock';
import { 
  ERROR_TYPE_AUTH, 
  ERROR_TYPE_PANEL_MSG, 
  ERROR_TYPE_INITIALIZATION_ERROR, 
  ERROR_TYPE_APP_UNAVAILABLE,
  INTEGRATED_APP_UNAVAILABLE,
  NON_INTEGRATED_APP_UNAVAILABLE
} from '../../constants/error.constants';

const AuthConfirm = ({type}) => {

  const headerTitle = {
    [ERROR_TYPE_AUTH]: translations.AUTH_CONFIRMATION_HEADER_TITLE,
    [ERROR_TYPE_PANEL_MSG]: translations.IN_PANEL_HEADER_TITLE,
    [ERROR_TYPE_INITIALIZATION_ERROR]: translations.INITIALIZATION_ERROR_HEADER_TITLE,
    [ERROR_TYPE_APP_UNAVAILABLE]: translations.APP_UNAVAILABLE_HEADER_TITLE,
    [INTEGRATED_APP_UNAVAILABLE]: translations.INTEGRATED_APP_UNAVAILABLE_HEADER_TITLE,
    [NON_INTEGRATED_APP_UNAVAILABLE]: translations.NON_INTEGRATED_APP_UNAVAILABLE_HEADER_TITLE
  };

  const bodyContent = {
    [ERROR_TYPE_AUTH]: translations.AUTH_CONFIRMATION_BODY_CONTENT,
    [ERROR_TYPE_PANEL_MSG]: translations.IN_PANEL_BODY_CONTENT,
    [ERROR_TYPE_INITIALIZATION_ERROR]: translations.INITIALIZATION_ERROR_BODY_CONTENT,
    [ERROR_TYPE_APP_UNAVAILABLE]: translations.APP_UNAVAILABLE_BODY_CONTENT,
    [INTEGRATED_APP_UNAVAILABLE]: translations.INTEGRATED_APP_UNAVAILABLE_BODY_CONTENT,
    [NON_INTEGRATED_APP_UNAVAILABLE]: translations.NON_INTEGRATED_APP_UNAVAILABLE_BODY_CONTENT
  };
  
  return (
    <div className="vve-join-container">
      <div className="vve-kpgroup-logo"></div>
      <InformationBlock header={headerTitle[type]} body={bodyContent[type]} />
    </div>
  )
}

export default AuthConfirm;