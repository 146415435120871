import { ACTION_TYPES } from '../../constants/store.constants';
import _get from 'lodash.get';

const setDialPadDetails = (data) => (dispatch) => {
	dispatch({ type: ACTION_TYPES.SET_DIALPAD_DETAILS, payload: data });
	dispatch({ type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: data?.isInputValid });
};

const setDialPadClearButtonHiddenStatus = (data) => ({
	type: ACTION_TYPES.SET_DIALPAD_CLEAR_BTN_HIDDEN_STATUS,
	payload: data
});

const setDialPadDisablePhoneNumber = (data) => ({
	type: ACTION_TYPES.SET_DIALPAD_DISABLE_PHONENUMBER,
	payload: data
});

const setDialPadDefaultView = (data) => (dispatch) => {
	dispatch(setDialPadClearButtonHiddenStatus(true));
    dispatch(setDialPadDisablePhoneNumber(true));
    dispatch({type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: false});
    const timer = (timeout) => {
        setTimeout(() => {
            if (data.contact_number && typeof data.contact_number === 'string') {
                dispatch(setDialPadClearButtonHiddenStatus(false));
                dispatch(setDialPadDisablePhoneNumber(false));
                dispatch({type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: true});
            }
        }, timeout);
    }
	const defaultTime = _get(data.appConfig, "clientSettings.defaultViewTimer")
	if(defaultTime) {
		timer(defaultTime);
	}
}

export default {
	setDialPadDetails,
	setDialPadClearButtonHiddenStatus,
	setDialPadDisablePhoneNumber,
	setDialPadDefaultView
};