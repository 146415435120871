import React from 'react';
import translations from "../../translations";
import InformationBlock from '../InformationBlock/InformationBlock';

const Error = () => {
  return (
    <div className="vve-error-container">
       <div className="vve-unauthorized-logo"></div>
       <InformationBlock header={translations.UNAUTHORIZED_HEADER_TITLE} body={translations.UNAUTHORIZED_BODY_CONTENT} />
    </div>
  )
}

export default Error;