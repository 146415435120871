const INTERPRETER_AUDIO = 'audio';
const INTERPRETER_VIDEO = 'video';
const RADIO_BUTTONS = [
    {
        label: 'Video',
        name: 'interpreterType',
        value: INTERPRETER_VIDEO,
        checked: true
    },
    {
        label: 'Audio',
        name: 'interpreterType',
        value: INTERPRETER_AUDIO,
        checked: false
    }
];

const LANGUAGE_LIST = {
    defaultCode: 'asl',
}

const SELECT_INTERPRETER_VIDEO = "Select a language";
const SELECT_INTERPRETER_AUDIO = "Select a service area";
const SEARCH_INTERPRETER_VIDEO = "Search Language";
const SEARCH_INTERPRETER_AUDIO = "Search Service Area";


const SUPPORT_TAB_HEADER = "Helpful Resources and Support";
const SHARE_POINT_HEADER = "SharePoint";
const SERVICE_NOW_HEADER = "ServiceNow@KP";
const SNOW_HEADER = "Submit a ServiceNow ticket"
const CUSTOMER_SERVICE_CLINICIAN_HEADER = "Telehealth Provider Line for Clinicians";
const CUSTOMER_SERVICE_PATIENT_HEADER = "Customer Service for Patients";
const SNOW_BACK_BOTTON = "Back";


const DELAY_FIRST_FETCH_DISPLAY = 0; // milliseconds

const INTERPRETER_OPTIONS_LIST = [
    "interpreter-video",
    "interpreter-audio"
];

const INVITE_INTERPRETER_DIALOUT_ROLE = "DIALOUT";
const INVITE_INTERPRETER_AUDIO_ROLE = "INTERPRETER-AUDIO";

export {
    INTERPRETER_AUDIO,
    INTERPRETER_VIDEO,
    RADIO_BUTTONS,
    LANGUAGE_LIST,
    SELECT_INTERPRETER_VIDEO,
    SELECT_INTERPRETER_AUDIO,
    SEARCH_INTERPRETER_VIDEO,
    SEARCH_INTERPRETER_AUDIO,
    DELAY_FIRST_FETCH_DISPLAY,
    INTERPRETER_OPTIONS_LIST,
    INVITE_INTERPRETER_DIALOUT_ROLE,
    INVITE_INTERPRETER_AUDIO_ROLE,
    SUPPORT_TAB_HEADER,
    SHARE_POINT_HEADER,
    SERVICE_NOW_HEADER,
    CUSTOMER_SERVICE_CLINICIAN_HEADER,
    CUSTOMER_SERVICE_PATIENT_HEADER,
    SNOW_HEADER,
    SNOW_BACK_BOTTON
};
