import actionTypes from '../actionTypes';
import { getErrorModalProps } from "../../helper/commonUtils"

const initialState = {
    errorModal: false,
    modalProps: {},
    errorResponse: {}
};

const ErrorHandlerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ERROR_HANDLER:
            const modalDetails = getErrorModalProps(action.payload);
            return {
                errorModal: true,
                errorResponse: action.payload,
                modalProps: modalDetails
            };
        case actionTypes.SET_ERROR_HANDLER_OFF:
            return {
                errorModal: false,
                modalProps: {},
                errorResponse: {}
            };
        default:
            return state;
    }
};

export default ErrorHandlerReducer;