import { ACTION_TYPES } from "../../constants/store.constants";

const initialState = {
  startDate: null,
  endDate: null,
};

const ReportsReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case ACTION_TYPES.SET_START_END_DATE:
      return {
        ...state,
        startDate: payload?.startDate,
        endDate: payload?.endDate,
      };
    default:
      return state;
  }
};

export default ReportsReducer;