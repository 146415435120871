import MOCK_RESPONSE from "./zoomClient.mock.responses.json";
import { APP_DEVICE_TYPE } from "../constants/zoomApp.constants";

export const getMockClientDetails = () => {
	const meetingContextResponse = MOCK_RESPONSE.MOCK_MEETING_CONTEXT_RESPONSE;
	const userContextResponse = MOCK_RESPONSE.MOCK_USER_CONTEXT_RESPONSE;
	const meetingJoinUrlResponse = MOCK_RESPONSE.MOCK_MEETING_JOIN_URL_RESPONSE;
	const meetingUUIDResponse = MOCK_RESPONSE.MOCK_MEETING_UUID_RESPONSE;
	const browserOSInfo = MOCK_RESPONSE.MOCK_BROWSER_OS_RESPONSE;
	return {
		meetingID: meetingContextResponse.meetingID,
		meetingJoinUrl: meetingJoinUrlResponse.joinUrl,
		meetingUUID: meetingUUIDResponse.meetingUUID,
		userContext: userContextResponse,
		deviceType: APP_DEVICE_TYPE.desktop,
		browserOSInfo: browserOSInfo
		// participantList: participantListResponse
	};
};


