import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BUTTON_CONFIG from '../../constants/button.constants';
import BackButtonComponent from '../BackButton/BackButton';
import ClinicianInfo from '../ClinicianInfo/ClinicianInfo';
import { STORE_NAMES } from '../../constants/store.constants';

const ClinicianDetails = ({ backbtnclick, loadmatrix, matrixindex }) => {

    const { selectedClinicianData } = useSelector(state => state?.[STORE_NAMES.CLINICIAN_DIRECTORY_CLINICIANLIST]);
    useEffect(() => {
        if (selectedClinicianData)
            loadmatrix(matrixindex);
    }, [selectedClinicianData])
    return (
        <>
            {selectedClinicianData !== null ?
                <div>
                    <BackButtonComponent
                        onClick={() => backbtnclick(matrixindex)}
                        buttonText={BUTTON_CONFIG.BACK_BUTTON.title}
                    />
                    <ClinicianInfo/>
                </div>
                : null}
        </>
    );
}

ClinicianDetails.propTypes = {
    backbtnclick: PropTypes.any,
    loadmatrix: PropTypes.any,
    matrixindex: PropTypes.any,
}

export default ClinicianDetails;