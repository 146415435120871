import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Form from 'react-bootstrap/Form';
import ErrorCircleSmallImage from "../../assets/images/error-circle-small.svg";
import Image from "../Image/Image";
import { NotificationServiceDownModal, FailedMultiInvitesModal, NotificationTrackingModal } from "./MultiInviteModals";
import GuestAction from '../../store/actions/GuestAction';
import { 
    truncateSpecialChar, 
    validateEmail as isValidEmail, 
    getFormattedPhoneNumber, 
    isValidMobile
} from '../../helper/commonHelper';
import {
    checkForAllNotificationsSentStatus,
    filterTrackingDetailsByStatus,
    prepareMultiInviteStatusCheckRequest, 
    updateTrackingDetailsStatus
} from '../../helper/inviteHelper';
import { checkMultiInviteStatus } from '../../service/guest.service';
import { ACTION_TYPES, STORE_NAMES } from '../../constants/store.constants';
import { 
    MULTI_INVITE_TYPE,
    MULTI_INVITE_OPTIONS, 
    GUEST_INVITE_TEXT, 
    GUEST_INVITE_EMAIL,
    DISPLAY_FAILED_NOTIFICATIONS_STATUSES,
    MAX_TIME_LIMIT_CHECKER_INTERVAL,
    ALLOWED_POLLING_STATUSES,
    POLLING_START_DELAY
} from '../../constants/guest.constants';

const MultiParticipantInvite = () => {
    const dispatch = useDispatch();
    const { headerText, 
            infoText, 
            validationErrorText, 
            inviteeCountLimit, 
            inviteeControlPlaceholder, 
            inviteeControlKeyPrefix,
            errorClassName,
            validClassName } = MULTI_INVITE_OPTIONS;
    const { inviteButtonClicked } = useSelector(state => state?.[STORE_NAMES.INVITE_BUTTON]);
    const { multiInviteTrackingDetails } = useSelector(state => state?.[STORE_NAMES.GUEST]);
    const {
        appConfig: {
            clientSettings: {
                dnp: {
                    statusCheckLimit = null,
                    pollingRetryInterval = null,
                    statusCheckTimeout = null
                } = {}
            } = {}
        } 
    } = useSelector((state) => state?.[STORE_NAMES.APP]);

    const inviteeControlRefs = useRef({});
    const trackMultiInviteTimerRef = useRef([]);
    const statusRequestControllerRef = useRef([]);
    const maxTimeLimitCheckTimerRef = useRef(null);
    const [isAnyInviteeInvalid, setIsAnyInviteeInvalid] = useState(false);
    const [validInviteeCount, setValidInviteeCount] = useState(0);
    const [failedNotifications, setFailedNotifications] = useState(null);

    // modal toggles
    const [displayNotificationTrackingModal, setDisplayNotificationTrackingModal] = useState(false);
    const [displayNotificationServiceDownModal, setDisplayNotificationServiceDownModal] = useState(false);
    const [displayFailedNotificationsModal, setDisplayFailedNotificationsModal] = useState(false);

    // process invitee controls and set validation logic, apply error styling logic
    const parseInviteeControls = () => {
        let validInviteeCount = 0;
        let isAnyInviteeInvalid = false;
        for(const key in inviteeControlRefs.current) {
            const inviteeControl = inviteeControlRefs.current[key].control;
            const inputValue = inviteeControl.value;
            const isEmpty = inputValue.length===0;
            const isInputValidEmail = isValidEmail(inputValue);
            const isInputValidMobile = isValidMobile(inputValue);
            const isValidEmailOrMobile = isInputValidEmail || (inputValue.includes("@") ? false : isInputValidMobile);

            // logic for validation message and styling
            inviteeControl.classList.remove(errorClassName, validClassName);
            if(!isEmpty) {
                if(isValidEmailOrMobile) {
                    validInviteeCount++;
                    inviteeControl.classList.add(validClassName);
                }
                else {
                    isAnyInviteeInvalid = true;
                    inviteeControl.classList.add(errorClassName);
                }
            }

            // set individual control validity status
            inviteeControlRefs.current[key].isValidEmailOrMobile = isValidEmailOrMobile;
            inviteeControlRefs.current[key].isEmail = isInputValidEmail;
            inviteeControlRefs.current[key].isMobile = isInputValidMobile;
        }
        setValidInviteeCount(validInviteeCount);
        setIsAnyInviteeInvalid(isAnyInviteeInvalid);
    };

    // add/remove formatting for mobile or numbers only scenarios
    const addOrRemoveFormatting = (currentInputControl) => {
        const currentInputValue = currentInputControl.value;
        // if @ exists then treat string as email and skip formatting
        if(currentInputValue.includes("@")) return;
        if(isValidMobile(currentInputValue)) {
            currentInputControl.value = getFormattedPhoneNumber(truncateSpecialChar(currentInputValue));
        }
    };

    // prepare and save invitee notification payload 
    const updateInviteeNotificationPayload = () => {
        const storeData = [];
        for(const key in inviteeControlRefs.current) {
            const { control: { value }, isValidEmailOrMobile, isMobile } = inviteeControlRefs.current[key];
            if(value.length > 0 && isValidEmailOrMobile) {
                const notificationId = isMobile ? truncateSpecialChar(value) : value.trim();
                const notificationType = isMobile ? GUEST_INVITE_TEXT : GUEST_INVITE_EMAIL;
                storeData.push({ notificationId, notificationType });
            }
        }
        dispatch(GuestAction.setMultiGuestInvitees(storeData));
    };

    const resetFormControls = () => {
        for(const key in inviteeControlRefs.current) {
            inviteeControlRefs.current[key].control.value = "";
        }
    };

    const populateFormControls = (notificatiions) => {
        let trackedIndex = 0;
        for(const key in inviteeControlRefs.current) {
            if(trackedIndex > notificatiions.length - 1) {
                inviteeControlRefs.current[key].control.value = "";
            }
            else {
                const { notificationId } = notificatiions[trackedIndex];
                inviteeControlRefs.current[key].control.value = notificationId;
                addOrRemoveFormatting(inviteeControlRefs.current[key].control);
            }
            trackedIndex++;
        }
    };

    const resetFormDetails = () => {
        resetFormControls();
        parseInviteeControls();
        updateInviteeNotificationPayload();
    };

    /****************************************** Polling logic here *****************************************************/

    const startMaxTimeLimitCheckTimer = (startTimeStamp) => {
        clearMaxTimeLimitCheckerTimer();
        maxTimeLimitCheckTimerRef.current = setInterval(() => {
            const currentTimeStamp = dayjs();
            const maxTimeLimitForPolling = pollingRetryInterval*statusCheckLimit;
            if(currentTimeStamp.diff(startTimeStamp, "ms") >= maxTimeLimitForPolling) {
                stopNotificationStatusPolling("maxTimeLimitReached");
            }
        }, MAX_TIME_LIMIT_CHECKER_INTERVAL);
    };

    const clearMaxTimeLimitCheckerTimer = () => {
        if(maxTimeLimitCheckTimerRef.current) {
            clearInterval(maxTimeLimitCheckTimerRef.current);
        }
        maxTimeLimitCheckTimerRef.current = null;
    };

    const cancelAllStatusRequests = () => {
        statusRequestControllerRef.current.forEach(abortRequestController => abortRequestController.abort());
        statusRequestControllerRef.current = [];
    };

    const clearStatusCheckTimers = () => {
        trackMultiInviteTimerRef.current.forEach(timerId => clearTimeout(timerId));
        trackMultiInviteTimerRef.current = [];
    }

    const stopNotificationStatusPolling = (type) => {

        // clear all timers and cancel all pending network requests for status check
        cancelAllStatusRequests();
        clearStatusCheckTimers();
        clearMaxTimeLimitCheckerTimer();

        // reset all modals
        setDisplayNotificationTrackingModal(false);
        setFailedNotifications(null);

        switch (type) {
            case "allInvitesSent":
                // display successfull invitation modal
                dispatch({ type: ACTION_TYPES.INVITE_BUTTON_CLICKED, payload: { inviteButtonClicked: true, status: true } });
                break;
            case "maxTimeLimitReached": // same as failedNotificationsError case so fallthrough
                    dispatch({ type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: true });
            case "noMoreNotificationsToProcess": // same as failedNotificationsError case so fallthrough
            case "failedNotificationsError":
                    const failedTrackingDetails = filterTrackingDetailsByStatus(multiInviteTrackingDetails, DISPLAY_FAILED_NOTIFICATIONS_STATUSES);
                    if(failedTrackingDetails.length > 0) {
                        setFailedNotifications(failedTrackingDetails);
                        setDisplayFailedNotificationsModal(true);
                    }
                    else {
                        // display successfull invitation modal
                        dispatch({ type: ACTION_TYPES.INVITE_BUTTON_CLICKED, payload: { inviteButtonClicked: true, status: true } });
                    }
                break;
            case "serviceDownError":
                    setDisplayNotificationServiceDownModal(true);
                break;
            default:
                break;
        }
        // clear guest invite notifiation details from store
        dispatch(GuestAction.clearMultiInviteTrackingDetails());
    };

    const handleNoticaitionStatusSuccess = (successResponse) => {
        updateTrackingDetailsStatus(multiInviteTrackingDetails, successResponse);

        const allInvitesSentStatus = checkForAllNotificationsSentStatus(multiInviteTrackingDetails);
        if(allInvitesSentStatus===true) {
            stopNotificationStatusPolling("allInvitesSent");
            return;
        }

        const notificationsToProcess = filterTrackingDetailsByStatus(multiInviteTrackingDetails, ALLOWED_POLLING_STATUSES);
        if(notificationsToProcess.length === 0) {
            stopNotificationStatusPolling("noMoreNotificationsToProcess");
            return;
        }
    };

    const handleNotificationStatusError = (exception, isLastStatusCheck) => {
        if(isLastStatusCheck) {
            const serverErrors = exception?.response?.data?.errors;
            if (serverErrors && Array.isArray(serverErrors) && serverErrors.length > 0) {
                stopNotificationStatusPolling("serviceDownError");
            }
        }
    };

    const parseNotificationStatusResponse = ({ successResponse = null,  exception = null, isLastStatusCheck }) => {
        if(successResponse && Array.isArray(successResponse) && successResponse.length > 0) {
            handleNoticaitionStatusSuccess(successResponse);
        }

        if(exception) {
            handleNotificationStatusError(exception, isLastStatusCheck);
        }
    };

    const checkNotificationStatus = async ({ multiInviteStatusCheckRequest, isLastStatusCheck }) => {
        const responseBody = {};
        try {
            const abortRequestController = new AbortController();
            statusRequestControllerRef.current.push(abortRequestController);
            responseBody.successResponse = await checkMultiInviteStatus({ payload: multiInviteStatusCheckRequest, customTimeout: statusCheckTimeout, abortRequestController });
        }
        catch(exception) {
            responseBody.exception = exception;
        }
        finally {
            parseNotificationStatusResponse({ ...responseBody, isLastStatusCheck });
        }
    };

    const startMultiInviteStatusCheckPolling = async (iterationCount) => {
        iterationCount++;
        const isLastStatusCheck = iterationCount===+statusCheckLimit;

        const multiInviteStatusCheckRequest = prepareMultiInviteStatusCheckRequest(multiInviteTrackingDetails, isLastStatusCheck);
        if(multiInviteStatusCheckRequest.trackingIds.length === 0) {
            stopNotificationStatusPolling("noMoreNotificationsToProcess");
            return;
        }

        // Trigger polling call here
        checkNotificationStatus({ multiInviteStatusCheckRequest, iterationCount, isLastStatusCheck });
        
        // stop recursion for safety
        if(isLastStatusCheck) {
            return;
        }

        // create next polling interval
        const pollingTimerId = setTimeout(startMultiInviteStatusCheckPolling, pollingRetryInterval, iterationCount);
        trackMultiInviteTimerRef.current.push(pollingTimerId);
    };

    /******************************************* Polling logic end ****************************************************/

    const handleOnChange = (event) => {
        parseInviteeControls();
        addOrRemoveFormatting(event.target);
        updateInviteeNotificationPayload();
    };

    const handleFailedInvitesModalClose = () => {
        populateFormControls(failedNotifications);

        // always process and update notification control and store value
        parseInviteeControls();
        updateInviteeNotificationPayload();

        // clear failed notifications and modal close logic
        setFailedNotifications(null);
        setDisplayFailedNotificationsModal(false);

        // enable the invite button after modal is closed
        dispatch({ type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: true });
    };

    const handleNotificationServiceDownModalClose = () => {
        // always process and update notification control and store value
        parseInviteeControls();
        updateInviteeNotificationPayload();

        // clear failed notifications and modal close logic
        setFailedNotifications(null);
        setDisplayNotificationServiceDownModal(false);

        // enable the invite button after modal is closed
        dispatch({ type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: true });
    };

    const getGuestInviteeControls = () => {
        const inviteeControls = [];
        for(let index = 0; index < inviteeCountLimit; index++) {
            const inviteeKey = `${inviteeControlKeyPrefix}${index}`;
            inviteeControls.push(<p key={inviteeKey}>
                <Form.Control type="search"
                                placeholder={inviteeControlPlaceholder} 
                                onChange={handleOnChange} 
                                ref={(el) => inviteeControlRefs.current[inviteeKey] = { control: el }}/>
            </p>);
        }
        return inviteeControls;
    };

    const getValidationErrorElement = () => {
        return <>
                <div className="vve-invitee-validation-error">
                    <Image className="error-image-circle"
                            src={ErrorCircleSmallImage}
                            role="img"
                            alt="multi-invite-error-circle"/>
                    {validationErrorText}
                </div>
        </>;
    };

    const cleanUpMultiInviteDependencies = () => {
        // reset store value for saved invitees
        dispatch(GuestAction.setMultiGuestInvitees([]));

        // cleanup polling dependencies
        clearStatusCheckTimers();
        clearMaxTimeLimitCheckerTimer();
        cancelAllStatusRequests();
        setDisplayNotificationTrackingModal(false);
        dispatch(GuestAction.clearMultiInviteTrackingDetails());
    };

    // handle invite sent success modal close logic
    useEffect(() => {
        if(inviteButtonClicked===false) {
            resetFormDetails();
        }
    }, [inviteButtonClicked]);

    // logic to enable disable invite button
    useEffect(() => {
        const enableInviteButtonStatus = !isAnyInviteeInvalid && validInviteeCount > 0;
        dispatch({ type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: enableInviteButtonStatus });
    }, [validInviteeCount, isAnyInviteeInvalid]);

    // polling logic for multi invite status check
    useEffect(() => {
        let firstPollDelayTimer;
        if(multiInviteTrackingDetails && statusCheckLimit && pollingRetryInterval && statusCheckTimeout && firstPollDelayTimer==null && !displayNotificationTrackingModal) {
            firstPollDelayTimer = setTimeout(() => {
                setDisplayNotificationTrackingModal(true);
                const startTimeStamp = dayjs();
                startMaxTimeLimitCheckTimer(startTimeStamp);
                startMultiInviteStatusCheckPolling(0);
            }, POLLING_START_DELAY);
        }

        return () => {
            clearTimeout(firstPollDelayTimer);
        }
    }, [multiInviteTrackingDetails]);

    // initialization logic
    useEffect(() => {
        dispatch(GuestAction.setGuestInviteType(MULTI_INVITE_TYPE));
        parseInviteeControls();
        dispatch({ type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: false });
        return () => cleanUpMultiInviteDependencies();
    }, []);

    return <div className='vve-invite-guest-text-container'>
                <p className='vve-multi-invite-header'>{headerText}</p>
                <p className='vve-info vve-medium'>{infoText}</p>
                <Form>
                    <Form.Group className="vve-form-label">
                        {getGuestInviteeControls()}
                        {isAnyInviteeInvalid && getValidationErrorElement()}
                    </Form.Group>
                </Form>
                { displayFailedNotificationsModal && 
                    <FailedMultiInvitesModal failedNotifications={failedNotifications} onCloseHandler={handleFailedInvitesModalClose} /> 
                }
                { displayNotificationServiceDownModal &&
                    <NotificationServiceDownModal onCloseHandler={handleNotificationServiceDownModalClose} />
                }
                { displayNotificationTrackingModal &&
                    <NotificationTrackingModal onCloseHandler={setDisplayNotificationTrackingModal} />
                }
            </div>
};

export default MultiParticipantInvite;