import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import Button from "../Button/Button";
import FilterModal from "../FilterModal/FilterModal";
import { FILTER_TITLE, CALENDAR_SETTINGS } from "../../constants/report.constant";
import { ACTION_TYPES } from "../../constants/store.constants";

const AttendeeReportFilter = () => {
    const dispatch = useDispatch();
    const [displayFilterModal, setDisplayFilterModal] = useState(false);
    const [resetEnable, setResetEnable] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startCalendarOpenStatus, setStartCalendarOpenStatus] = useState(false);
    const [endCalendarOpenStatus, setEndCalendarOpenStatus] = useState(false);

    // start date can't go beyond configured days in the past from current date and can't be greater than current date
    const getMinMaxStartDate = () => {
        const currentDateParsed = dayjs(new Date());
        // to include current date in the days calculation, subtract one from configured limit
        const calculatedPastDate = currentDateParsed.subtract(CALENDAR_SETTINGS.startDate.pastDaysLimit - 1, 'day');
        return {
            minDate: calculatedPastDate.toDate(),
            maxDate: currentDateParsed.toDate()
        };
    };

    // end date can't be greater than current date and can't go beyond start date value
    const getMinMaxEndDate = () => ({ minDate: startDate, maxDate: new Date() });

    const setStartEndDateToCurrent = () => {
        const currentDate = new Date();
        setStartDate(currentDate);
        setEndDate(currentDate);
    };

    // reset button is enabled if either start date or end date is not same current date 
    const updateResetButtonEnableStatus = () => {
        const currentDate = new Date();
        const startDateParsed = dayjs(startDate);
        const endDateParsed = dayjs(endDate);
        const isStartDateSameAsCurrentDate = startDateParsed.isSame(currentDate, 'day');
        const isEndDateSameAsCurrentDate = endDateParsed.isSame(currentDate, 'day');
        setResetEnable(!isStartDateSameAsCurrentDate || !isEndDateSameAsCurrentDate);
    };

    const updateFilterDates = (startDate, endDate) => {
        dispatch({ type: ACTION_TYPES.SET_START_END_DATE, payload: { startDate, endDate } });
    };

    const resetFilters = () => {
        setStartEndDateToCurrent();
        setStartCalendarOpenStatus(false);
        setEndCalendarOpenStatus(false);
    };

    const handleFilterButtonClick = () => {
        setStartEndDateToCurrent();
        setDisplayFilterModal(true);
    };

    const handleCancelButtonClick = () => {
        setDisplayFilterModal(false);
        resetFilters();
    };

    const handleResetFilterClick = () => {
        resetFilters();
    };

    const handleApplyFilterClick = () => {
        updateFilterDates(startDate, endDate);
        setDisplayFilterModal(false);
    };

    const handleStartDateChange = (selectedDate) => {
        setStartDate(selectedDate);
    };

    const handleEndDateChange = (selectedDate) => {
        setEndDate(selectedDate);
    };

    const handleStartDateCalendarToggle = () => {
        setStartCalendarOpenStatus(!startCalendarOpenStatus);
    };

    const handleEndDateCalendarToggle = () => {
        setEndCalendarOpenStatus(!endCalendarOpenStatus);
    };

    const customHeader = ({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
    }) => {
        return <div className="vve-calendar-header">
                    <div className="vve-calendar-month">{dayjs(date).format("MMM YYYY")}</div>
                    <div className="vve-calendar-navigation">
                        <Button onClick={decreaseMonth} class="vve-calendar-icon-prev vve-calendar-icon-nav" disabled={prevMonthButtonDisabled}></Button>
                        <div className="vve-calendar-icon-ellipse vve-calendar-icon-nav"></div>
                        <Button onClick={increaseMonth} class="vve-calendar-icon-next vve-calendar-icon-nav" disabled={nextMonthButtonDisabled}></Button>
                    </div>
                </div>
    };
 
    const customCalendarIcon = (forStartDate) => {
        const iconOnOffStatus = forStartDate ? startCalendarOpenStatus : endCalendarOpenStatus;
        const iconClass = iconOnOffStatus ? "vve-calendar-icon" : "vve-calendar-icon-off";
        return <i className={`${iconClass} vve-calendar-icon-custom`}></i>
    };

    const startDateProps = {
        selected: startDate,
        ...getMinMaxStartDate(),
        onChange: handleStartDateChange,
        onCalendarClose: handleStartDateCalendarToggle,
        onCalendarOpen: handleStartDateCalendarToggle
    };

    const startDatePickerOptions = {
        ...CALENDAR_SETTINGS.datePickerInput,
        ...CALENDAR_SETTINGS.calendarPopup,
        ...CALENDAR_SETTINGS.startDate,
        ...startDateProps,
        renderCustomHeader: customHeader,
        icon: customCalendarIcon(true)
    };

    const endDateProps = {
        selected: endDate,
        ...getMinMaxEndDate(),
        onChange: handleEndDateChange,
        onCalendarClose: handleEndDateCalendarToggle,
        onCalendarOpen: handleEndDateCalendarToggle
    };

    const endDatePickerOptions = {
        ...CALENDAR_SETTINGS.datePickerInput,
        ...CALENDAR_SETTINGS.calendarPopup,
        ...CALENDAR_SETTINGS.endDate,
        ...endDateProps,
        renderCustomHeader: customHeader,
        icon: customCalendarIcon(false)
    };

    const modalBody = <div className="vve-report-filter-date-range">
        <div className="vve-date-picker-start">
            <DatePicker {...startDatePickerOptions} />
        </div>
        <div className="vve-date-picker-separator">TO</div>
        <div className="vve-date-picker-end">
            <DatePicker {...endDatePickerOptions} />
        </div>
    </div>

    const modalOptions = {
        showModal: displayFilterModal,
        modalTitle: FILTER_TITLE,
        modalBody: modalBody,
        resetEnable: resetEnable,
        onClose: handleCancelButtonClick,
        onReset: handleResetFilterClick,
        onApply: handleApplyFilterClick,
    };

    useEffect(() => {
        updateResetButtonEnableStatus();
    }, [startDate, endDate]);
    
    useEffect(() => {
        // to update DefaultReportsPage date header text on first load
        const currentDate = new Date();
        updateFilterDates(currentDate, currentDate);
        return () => updateFilterDates(null, null);
    }, []);

    return <>
        <Button class="vve-date-filter" onClick={handleFilterButtonClick}></Button>
        { displayFilterModal && 
            <FilterModal {...modalOptions}/>
        }
    </>
};

export default AttendeeReportFilter;