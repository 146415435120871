import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {

    let additionalClass = '';
    if (props.modal) {
        additionalClass += 'modal-button';
    }
    let analyticsName = props.children;
    if (props.analyticsName) {
        analyticsName = props.analyticsName;
    }
    let disabledBtn = props.disabled;
    if (disabledBtn === undefined) {
        disabledBtn = false;
    }
    let tabIndexValue = props.tabIndex;
    if (tabIndexValue === undefined) {
        tabIndexValue = '0';
    }
    return (
        <button id={props.id} className={['button', additionalClass, props.class].join(' ')}
            onClick={props.onClick}
            onMouseDown={props.onMouseDown}
            onMouseUp={props.onMouseUp}
            onTouchStart={props.onTouchStart}
            onTouchEnd={props.onTouchEnd}
            data-analytics-type='button'
            data-analytics-click={analyticsName}
            disabled={disabledBtn}
            tabIndex={tabIndexValue}>
                {props.children}
        </button>
    );
}

Button.propTypes = {
    analyticsName: PropTypes.any,
    children: PropTypes.any,
    class: PropTypes.any,
    onClick: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    onTouchStart: PropTypes.func,
    onTouchEnd: PropTypes.func,
    modal: PropTypes.any,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    tabIndex: PropTypes.any,
    refValue: PropTypes.any
};

export default Button;