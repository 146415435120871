import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ListGroup from 'react-bootstrap/ListGroup';
import Image from '../Image/Image';
import avatar from "../../assets/images/dr-photo-avatar-small.svg";
import { NO_RESULTS_FOUND } from "../../constants/error.constants";
import { STORE_NAMES } from "../../constants/store.constants";
import { UpdateSelectedProvider } from "../../store/actions/ProviderListAction";
import { attachHoverforEllipsedText, formatDateOnCallFeature, removeCommaIfFirstNameEmpty } from "../../helper/oncallUtils";
import Loader from '../Loader/Loader';

const CareGiverList = ({ matrixindex, fwdbtnclick, loader }) => {
    const dispatch = useDispatch();
    const [selectedItemIndex, setSelectedItemIndex] = useState();
    const { providerListData = [], selectedProviderData } = useSelector(
        (state) => state?.[STORE_NAMES.ONCALL_DIRECTORY_PROVIDERLIST]
    );

    const onProviderListClicked = (index, clinician) => {
        setSelectedItemIndex(index);
        dispatch(UpdateSelectedProvider(clinician));
        fwdbtnclick(matrixindex);
    };

    const getDefaultSrc = (ev) => {
        ev.target.src = avatar;
    }

    useEffect(() => {
        attachHoverforEllipsedText("vve-fname-lname");
    }, [providerListData]);

    const providerList = providerListData?.map(
        (item, index) => {
            return <ListGroup.Item
                action="true"
                key={index}
                role='button'
                className={`${(selectedProviderData && index === selectedItemIndex) ? "vve-oncall-selected-caregiver" : ""}`}
            >
                <div className="d-flex align-items-center"
                    data-testid={`providerItem-${index}`}
                    onClick={() => {
                        onProviderListClicked(index, item);
                    }}
                >
                    <div className={`vve-caregiver-icon-container ${item.isCurrentlyOnCall ? 'vve-caregiver-oncall-live' : ''}`}>
                        <Image className="vve-caregiver-icon" src={item.imageURL ? item.imageURL : avatar} role="img" defaultImgSrc={getDefaultSrc} alt="caregiver-image.svg" />
                    </div>

                    <div className={`ms-3 vve-caregiver-panel ${item.isCurrentlyOnCall ? 'vve-caregiver-oncall-live' : ''}`}>
                        <div className="vve-caregiver-name">
                            <p className='vve-fname-lname'>{removeCommaIfFirstNameEmpty(item.lastName, item.firstName)}</p>
                            <p className='vve-caregiver-oncall'>{`ON CALL`}</p>
                        </div>
                        <div className='vve-caregiver-role-order-text'>
                            <p className="vve-caregiver-role">{`Role - ${item.role}`}</p>
                            <p className="vve-caregiver-callorder">{`Call order - ${item.order}`}</p>
                        </div>
                        <p className="vve-caregiver-oncalldatetime">{`${formatDateOnCallFeature(item.starDateTime)} - ${formatDateOnCallFeature(item.endDateTime)}`}</p>
                    </div>
                </div>
            </ListGroup.Item>
        }
    );

    return (
        <div className='vve-caregiver-list-container'>
            {
                loader ? <Loader showLoader={loader} /> : providerListData.length === 0 ? (//no results found
                    <div className="vve-caregiver-no-results-found" data-testid="noResult"><p>{NO_RESULTS_FOUND}</p></div>
                ) : (
                    <ListGroup id="vve-caregiver-list" data-testid="list-container" className="vve-caregiver-list" role="document">
                        {providerList}
                    </ListGroup>
                )
            }
        </div>
    );
}

CareGiverList.propTypes = {
    fwdbtnclick: PropTypes.any,
    matrixindex: PropTypes.any,
    loader: PropTypes.bool,
}

export default CareGiverList;