import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ABRIDGE_ACTIONS from '../../store/actions/AbridgeAction';
import { STORE_NAMES } from '../../constants/store.constants';
import Button from '../Button/Button';
import BUTTON_CONFIG from '../../constants/button.constants';
import { NONE } from "../../constants/abridgePatientConsent.constants";

const AbridgeConsentStatusRefreshButton = () => {
    const dispatch = useDispatch();

    const { abridgeConsentDetails: { status } } = useSelector(state => state?.[STORE_NAMES.ABRIDGE]);
    const { meetingID } = useSelector(state => state?.[STORE_NAMES.ZOOM_CLIENT]);
    const {
      appointmentRegion: region = null,
      appointmentVisitType: visitType = null,
      provider: { nuid = "" } = {},
      appointmentId = null
    } = useSelector((state) => state?.[STORE_NAMES.APPOINTMENT_DETAILS]);

    const fetchAbridgeConsent = () => {
        const payload = {
            zoomMeetingId: meetingID,
            region,
            visitType,
            appointmentId,
            providerId: nuid
        }
        dispatch(ABRIDGE_ACTIONS.fetchPatientAbridgeConsentAction(payload));
    };

    return  status === NONE &&
                <div className='vve-invite-btn-container'>
                    <Button onClick={fetchAbridgeConsent} class='btn btn-primary btn-lg'>
                        { BUTTON_CONFIG.REFRESH_BUTTON.title }
                    </Button>
                </div>;
}

export default AbridgeConsentStatusRefreshButton;