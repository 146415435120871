import React from 'react';
import PropTypes from 'prop-types';
import BUTTON_CONFIG from '../../constants/button.constants';
import BackButtonComponent from '../BackButton/BackButton';
import Calendar from '../Calendar/Calendar';

const ProviderList = ({ fwdbtnclick, backbtnclick, loadmatrix, matrixindex }) => {
    return (
        <>
            <BackButtonComponent
                onClick={() => backbtnclick(matrixindex)}
                buttonText={BUTTON_CONFIG.BACK_BUTTON.title}
            />
            <Calendar fwdbtnclick={fwdbtnclick} backbtnclick={backbtnclick} loadmatrix={loadmatrix} matrixindex={matrixindex} ></Calendar>
        </>
    );
}

ProviderList.propTypes = {
    fwdbtnclick: PropTypes.any,
    backbtnclick: PropTypes.any,
    loadmatrix: PropTypes.any,
    matrixindex: PropTypes.any,
}

export default ProviderList;