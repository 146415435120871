import React, { useEffect, useRef, useCallback } from 'react';
import { debounce } from '../../helper/commonHelper';

const ScrollToBottomDetector = ({ containerRefId, onCallBack }) => {
  const containerRef = useRef();
  const margin = 2;

  //Debouncing will be removed once adding loading wheel..
  const debounceScrolltoBottom = useCallback(debounce(() => onCallBack(),800), []);
 
  const handleScroll = () => {
    const container = containerRef.current;

    // Check if the user has scrolled to the bottom
    if (container.scrollTop + container.clientHeight + margin >= container.scrollHeight) {
      debounceScrolltoBottom();
    }
  };

  useEffect(() => {
    const element = document.getElementById(containerRefId);
    if (element) {
      containerRef.current = element;
    }
    const container = element;
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  });

  return null;
}

export default ScrollToBottomDetector;
