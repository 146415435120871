const INVITE_BUTTON_ID = "invite-btn";
const MODAL_ACTION_BUTTON_CLASSES = {
    continueButton: "vve-continue-button",
    cancelButton: "vve-cancel-button"
};
const SNOW_CONTACT_NOT_EMPTY_CLASS = "snow-contact-not-empty";
const REPORT_DOWNLOAD_CLASS = "report-download-container";

export {
    INVITE_BUTTON_ID,
    MODAL_ACTION_BUTTON_CLASSES,
    SNOW_CONTACT_NOT_EMPTY_CLASS,
    REPORT_DOWNLOAD_CLASS
};
