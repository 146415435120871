import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { downloadAttendeeReport } from '../../service/reports.service';
import { downloadFileResource } from '../../helper/domHelper';
import { PARAM_KEY_AUTH_TOKEN } from "../../constants/service.constants";
import { PARAM_KEY_ATTENDEE_REPORT_FILE, PARAM_KEY_MEETING_UUID } from '../../constants/app.constants';
import { REPORT_DOWNLOAD_CLASS } from '../../constants/dom.constants';

const DISPLAY_MESSAGES = {
  initial: "Attendee report download in progress...",
  invalidParam: "Invalid parameters.",
  downloadFailed: "Attendee report download failed.",
  downloadSuccess: "Attendee report successfully downloaded."
};

const GenerateAttendeeReport = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [displayMessage, setDisplayMessage] = useState(DISPLAY_MESSAGES.initial);

  const parseQueryParams = () => {
    const authToken = searchParams.has(PARAM_KEY_AUTH_TOKEN) ? decodeURI(searchParams.get(PARAM_KEY_AUTH_TOKEN)) : false;
    const zoomMeetingUuid = searchParams.has(PARAM_KEY_MEETING_UUID) ? decodeURI(searchParams.get(PARAM_KEY_MEETING_UUID)) : false;
    const fileName = searchParams.has(PARAM_KEY_ATTENDEE_REPORT_FILE) ? decodeURI(searchParams.get(PARAM_KEY_ATTENDEE_REPORT_FILE)) : false;
    return { authToken, zoomMeetingUuid, fileName }
  };

  const validateDownloadParams = ({ zoomMeetingUuid, authToken, fileName }) => {
    return zoomMeetingUuid && authToken && fileName;
  };

  const initiateReportDownload = async ({ fileName, ...downloadRequestParams }) => {
    try {
      const fileBlobResponse = await downloadAttendeeReport(downloadRequestParams);
      const fileUrl = window.URL.createObjectURL(fileBlobResponse);
      downloadFileResource(REPORT_DOWNLOAD_CLASS, fileUrl, fileName);
      window.URL.revokeObjectURL(fileUrl);
      setDisplayMessage(DISPLAY_MESSAGES.downloadSuccess);
    }
    catch(error) {
      setDisplayMessage(DISPLAY_MESSAGES.downloadFailed);
    }
  };

  useEffect(() => {
    const queryParams = parseQueryParams();
    if(validateDownloadParams(queryParams)) {
      initiateReportDownload(queryParams);
      return;
    }
    setDisplayMessage(DISPLAY_MESSAGES.invalidParam);
  }, []);

  return <div className={REPORT_DOWNLOAD_CLASS}>{displayMessage}</div>;
};

export default GenerateAttendeeReport;