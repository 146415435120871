import React from 'react';

const BackButton = ({ onClick, buttonText }) => {
  return (
    <div className='vve-back-btn-oncall' onClick={onClick}>
      <div className='vve-back-arrow-icon'></div>
      <div className='vve-back-arrow-text'>{buttonText}</div>
    </div>
  );
};

export default BackButton;
