import React from 'react';
import PropTypes from 'prop-types';

const Image = (props) => {
    
    return (
        <img src={props.src} onClick={props.onClick} id={props.id} className={props.className} role={props.role} alt={props.alt} onError={props.defaultImgSrc} loading={props.loading}/>
    );
}

Image.propTypes = {
    src: PropTypes.string,
    onClick: PropTypes.any,
    id: PropTypes.string,
    className: PropTypes.string,
    role: PropTypes.string,
    alt: PropTypes.string,
    onError :PropTypes.any,
    loading: PropTypes.string,
};

export default Image;
