import { ACTION_TYPES } from '../../constants/store.constants';

const CreateDepartmentList = (payload) => {
    return {
        type: ACTION_TYPES.CREATE_DEPARTMENTLIST,
        payload: payload
      };
}

const UpdateDepartmentList = (payload) => {
    return {
        type: ACTION_TYPES.UPDATE_DEPARTMENTLIST,
        payload: payload
      };
}

const UpdateSelectedDepartment = (payload) => {
    return {
        type: ACTION_TYPES.UPDATE_SELECTED_DEPARTMENTNAME,
        payload: payload
      };
}

const setDepartmentLoader = (payload) => {
    return {
        type: ACTION_TYPES.SET_DEPARTMENT_LOADER,
        payload: payload
      };
}

export {
    CreateDepartmentList,
    UpdateDepartmentList,
    UpdateSelectedDepartment,
    setDepartmentLoader
}
