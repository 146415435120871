import { ACTION_TYPES } from "../../constants/store.constants";

const initialState = {
	currentTabContext: null,
	featureMatrix: null,
	tabSettings: null,
	selectedTab: null
};

const TabReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.SET_CURRENT_TAB_CONTEXT:
			return {
				...state,
				currentTabContext: payload
			};
		case ACTION_TYPES.FETCH_APP_SETTINGS_SUCCESS:
			return {
				...state,
				featureMatrix: payload?.featureMatrix || null
			};
		case ACTION_TYPES.SET_TAB_SETTINGS:
			return {
				...state,
				tabSettings: payload
			};
		case ACTION_TYPES.UPDATE_MOBILEVIEW_MATRIX:
			return {
				...state,
				selectedTab: payload
			};

		default:
			return state;
	}
};

export default TabReducer;
