import apiClient from "../helper/apiClient";
import MOCK_DEPARTMENT_RESPONSE from "../mock/department-list.json";
import MOCK_PROVIDERLIST_RESPONSE from "../mock/provider-list.json";
import { SERVICE_ROUTES } from "../constants/service.constants";
import MOCK_PROVIDERDETAIL_RESPONSE from "../mock/providerDetail.json";

export const getDepartmentList = async (
  params,
  callBackFn,
  abortController
) => {
  if (process.env.NODE_ENV !== "development") {
    try {
      const httpClient = apiClient.getClincianAppBffInstance();
      const { region, ...otherParams } = params;
      const endPoint = SERVICE_ROUTES.FETCH_DEPARTMENT_LIST_ROUTE.replace('{region}', region);
      const response = await httpClient.get(endPoint, { params: otherParams, signal: abortController?.signal });
      if (response?.data) {
        callBackFn(response.data);
      }
    } catch (error) {
      return { error: error };
    }
  } else {
    callBackFn({}, MOCK_DEPARTMENT_RESPONSE);
  }
};

export const getMedicalCenters = async (callBackFn, region) => {
  try {
    const httpClient = apiClient.getClincianAppBffInstance();
    const endPoint = SERVICE_ROUTES.FETCH_MEDICAL_CENTERS_ROUTE.replace('{region}', region);
    const response = await httpClient.get(endPoint);
    if (response?.data) {
      callBackFn(response.data);
    }
  } catch (error) {
    return { error: error };
  }
};

export const getDepartmentsBasedOnMedicalCenters = async (
  medicalCenterId,
  callBackFn,
  region
) => {
  try {
    const httpClient = apiClient.getClincianAppBffInstance();
    const endPoint = SERVICE_ROUTES.FETCH_DEPARTMENT_BY_MEDICAL_CENTER_ROUTE.replace("{region}", region).replace('{medcenterid}', medicalCenterId);
    const response = await httpClient.get(endPoint);
    if (response?.data) {
      callBackFn(response.data);
    }
  } catch (error) {
    return { error: error };
  }
};

export const getProviderList = async (
  params,
  dept,
  callBackFn
) => {
  if (process.env.NODE_ENV !== "development") {
    try {
      const httpClient = apiClient.getClincianAppBffInstance();
      const { region, ...otherParams } = params;
      const endPoint = SERVICE_ROUTES.FETCH_PROVIDER_LIST_BY_DEPTID.replace('{region}', region).replace("{departmentid}", dept.id).replace("{medcenterid}", dept.mc.id);
      const response = await httpClient.get(endPoint, { params: otherParams });
      callBackFn(response.data);
    } catch (error) {
      return { error: error };
    }
  } else {
    callBackFn({}, MOCK_PROVIDERLIST_RESPONSE);
  }
};

export const getProviderDetailnextOncall = async (
  params,
  dept,
  callBackFn
) => {
  if (process.env.NODE_ENV !== "development") {
    try {
      const httpClient = apiClient.getClincianAppBffInstance();
      const { region, ...otherParams } = params;
      const endPoint = SERVICE_ROUTES.FETCH_NEXT_ONCALL_DEPTID.replace('{region}', region).replace("{departmentid}", dept.id).replace("{medcenterid}", dept.mc.id);
      const response = await httpClient.get(endPoint, { params: otherParams });
      callBackFn(response);
    } catch (error) {
      callBackFn({ error: error });
    }
  } else {
    callBackFn({}, MOCK_PROVIDERDETAIL_RESPONSE);
  }
};
