import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

const DialogBox = ({ showModal, modalTitle, modalBody, modalBody2, modalDescription, onClose, onContinue, classes = {}, useStackedActionButtons = false , displayFooter = true}) => {
  return (
    <Modal className={`vve-zoom ${classes.modalRoot}`} show={showModal} onHide={onClose}>
      <Modal.Header>
        <Modal.Title> {modalTitle} </Modal.Title>
      </Modal.Header>
      <Modal.Body> {modalBody}</Modal.Body>
      {modalBody2 ?
        <Modal.Body> {modalBody2}</Modal.Body> : null
      }
      {modalDescription ? modalDescription?.map((item, i) => {
        const descriptionIndex = `vve-description-container-${i}`;
        const leftDescriptionIndex = `vve-left-description-${i}`;
        const rightDescriptionIndex = `vve-right-description-${i}`;
        return <div key={descriptionIndex} className='vve-description-container'>
                <p key={leftDescriptionIndex} className='vve-left-description'> {item.descriptionLeft}</p>
                <p key={rightDescriptionIndex} className='vve-right-description'> {item.descriptionRight}</p>
              </div>
        }) : null
      }
      { displayFooter && 
        <Modal.Footer>
          <div className='vve-zoom-modal-footer'>
            {
              useStackedActionButtons ?
              <div className="d-grid col-6 mx-auto">
                <button className={`btn btn-primary ${classes?.continueButton ?? ""}`} type="button" onClick={onContinue}>Continue</button>
                <button className={`btn btn-primary ${classes?.cancelButton ?? ""}`} type="button" onClick={onClose}>Cancel</button>
              </div> :
              <button type="button" className={`btn btn-primary ${classes.button}`} onClick={onClose}>OK</button>
            }
          </div>
        </Modal.Footer>
      }
    </Modal>
  );
};
DialogBox.propTypes = {
  modalDescription: PropTypes.any,
  showModal: PropTypes.any,
  modalTitle: PropTypes.any, 
  modalBody: PropTypes.any, 
  modalBody2: PropTypes.any,
  onClose: PropTypes.any,
  onContinue: PropTypes.any,
  classes: PropTypes.any,
  useStackedActionButtons: PropTypes.any
};
export default DialogBox;