const PROVIDER_INFO = {
    role: {
        label: "Role"
    },
    callNumber: {
        label: "Call number"
    },
    office: {
        label: "Office",
        copyType: "office"
    },
    mobile: {
        label: "Mobile",
        copyType: "mobile"
    },
    email: {
        label: "Email",
        copyType: "email"
    },
    pager: {
        label: "Pager",
        copyType: "pager"
    },
    phone: {
        label: "Phone",
        copyType: "Phone"
    },
    onCallNow: {
        label: "ON CALL NOW"
    },
    onCall: {
        label: "On Call"
    },
    nextOnCall: {
        label: "Next On Call"
    },
    notes: {
        label: "Notes"
    }
};

const NUMBER_CHARS_ONLY_FOR = [PROVIDER_INFO.office.copyType, PROVIDER_INFO.mobile.copyType, PROVIDER_INFO.pager.copyType];

const DEFAULT_COPY_OPTIONS = {
    copiedStatus: false,
    label: null
};

const COPY_OPTIONS = {
    [PROVIDER_INFO.office.copyType]: {...DEFAULT_COPY_OPTIONS, label: "Office number copied!"},
    [PROVIDER_INFO.mobile.copyType]: {...DEFAULT_COPY_OPTIONS, label: "Mobile number copied!"},
    [PROVIDER_INFO.email.copyType]: {...DEFAULT_COPY_OPTIONS, label: "Email copied!"},
    [PROVIDER_INFO.pager.copyType]: {...DEFAULT_COPY_OPTIONS, label: "Pager number copied!"},
    [PROVIDER_INFO.phone.copyType]: {...DEFAULT_COPY_OPTIONS, label: "Phone number copied!"},
};

const COPIED_TEXT_TIMEOUT = 5000; // ms

export {
    PROVIDER_INFO,
    NUMBER_CHARS_ONLY_FOR,
    DEFAULT_COPY_OPTIONS,
    COPY_OPTIONS,
    COPIED_TEXT_TIMEOUT
};
