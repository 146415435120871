import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { STORE_NAMES } from '../../../constants/store.constants';

const DialPadHeader = ({ context }) => {
    const [headerTitle, setHeaderTitle] = useState('');
    const { headerText } = useSelector(state => state?.[STORE_NAMES.DIALPAD]);

    useEffect(() => {
        setHeaderTitle(headerText[context]);
    }, [context]);

    return (
        <div className="vve-dialpad-header">
            {headerTitle}
        </div>
      )

};

export default DialPadHeader;