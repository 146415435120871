import { ACTION_TYPES } from "../../constants/store.constants";

const initialState = {};

const AppointmentReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.FETCH_APP_SETTINGS_SUCCESS:
			return payload?.appointment || null
		default:
			return state;
	}
};

export default AppointmentReducer;
