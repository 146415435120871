import apiClient from "../helper/apiClient";
import { SERVICE_ROUTES } from "../constants/service.constants";
import MOCK_FAVORITELIST_RESPONSE from '../mock/favorite-list.json';

export const getFavoriteList = async (
    params,
) => {
    if (process.env.NODE_ENV !== 'development') {
        try {
            const httpClient = apiClient.getClincianAppBffInstance();
            const { region, ...otherParams } = params;
            const endPoint = SERVICE_ROUTES.FETCH_FAVORITE_LIST.replace('{region}', region);
            const response = await httpClient.get(endPoint, { params: otherParams });
            return response;
        } catch (error) {
            return { error: error };
        }
    }
    else {
        return params, MOCK_FAVORITELIST_RESPONSE;
    }
};

export const addFavoriteItem = async (
    params
) => {
    try {
        const httpClient = apiClient.getClincianAppBffInstance();
        const { region, favoriteIDs, ...otherParams } = params;
        const endPoint = SERVICE_ROUTES.ADD_FAVORITE.replace('{region}', region).replace("{favoriteIDs}", favoriteIDs);
        const response = await httpClient.post(endPoint, { params: otherParams });
        return response;
    } catch (error) {
        return { error: error };
    }
};

export const removeFavoriteItem = async (
    params
) => {
    try {
        const httpClient = apiClient.getClincianAppBffInstance();
        const { region, favoriteIDs, ...otherParams } = params;
        const endPoint = SERVICE_ROUTES.REMOVE_FAVORITE.replace('{region}', region).replace("{favoriteIDs}", favoriteIDs);
        const response = await httpClient.post(endPoint, { params: otherParams });
        return response;
    } catch (error) {
        return { error: error };
    }
};