const PAD_KEYS = [
    {
        name: 'one',
        value: '1',
        alpha: ' ',
        numeric: '1'
    },
    {
        name: 'two',
        value: '2',
        alpha: 'ABC',
        numeric: '2'
    },
    {
        name: 'three',
        value: '3',
        alpha: 'DEF',
        numeric: '3'
    },
    {
        name: 'four',
        value: '4',
        alpha: 'GHI',
        numeric: '4'
    },
    {
        name: 'five',
        value: '5',
        alpha: 'JKL',
        numeric: '5'
    },
    {
        name: 'six',
        value: '6',
        alpha: 'MNO',
        numeric: '6'
    },
    {
        name: 'seven',
        value: '7',
        alpha: 'PQRS',
        numeric: '7'
    },
    {
        name: 'eight',
        value: '8',
        alpha: 'TUV',
        numeric: '8'
    },
    {
        name: 'nine',
        value: '9',
        alpha: 'WXYZ',
        numeric: '9'
    },
    {
        name: 'star',
        value: '*',
        alpha: ' ',
        numeric: ' '
    },
    {
        name: 'zero',
        value: '0',
        alpha: ' ',
        numeric: '0'
    },
    {
        name: 'hash',
        value: '#',
        alpha: ' ',
        numeric: ' '
    }
];

const CONTEXT_ADD_GUEST = "add_guest";
const CONTEXT_ADD_INTERPRETER = "add_interpreter";
const DISABLE_INVITE_LIST = [ CONTEXT_ADD_GUEST, CONTEXT_ADD_INTERPRETER];
const LONG_PRESS_DELAY = 500;

const DEFAULT_PAD_OPTIONS = {
    inputType: "text", // OR number
    value: "", // value to prepopulate
    disableInput: false, // disable or open input
    maxLength: 14,
    minLength: 14,
    autoFocus: true,
    isInputValid: false
};

const DEFAULT_PAD_SETTINGS = {
    formatInputAs: "phoneNumber", // if number or for special chars then process differently
    hideSpecialChars: false, // to remove * or # 
    hideClearButton: false,
    disableClearButton: false,
    disablePhoneNumber: false
};

const ALLOWED_CHARS_FOR_DELETION = ["*", "#"];

export {
    PAD_KEYS,
    CONTEXT_ADD_GUEST,
    CONTEXT_ADD_INTERPRETER,
    LONG_PRESS_DELAY,
    DEFAULT_PAD_OPTIONS,
    DEFAULT_PAD_SETTINGS,
    ALLOWED_CHARS_FOR_DELETION,
    DISABLE_INVITE_LIST
};