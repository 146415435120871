import React from 'react'
import { connect } from 'react-redux'
import { keepAliveTheAppWithRetry, keepAliveTheApp } from '../../store/actions/SessionAction'
import { STORE_NAMES } from "../../constants/store.constants";
export class RefreshToken extends React.Component {
    keepAliveInterval

    componentDidMount() {
        this.startRefreshAccessTokenInterval(false)
    }

    componentWillUnmount() {
        this.clearIntervalCall()
    }

    clearIntervalCall = () => {
        clearInterval(this.keepAliveInterval)
    }

    startRefreshAccessTokenInterval = async (shouldRefreshAccessToken = true) => {
        this.clearIntervalCall()
        if (shouldRefreshAccessToken) {
            const refreshTokenWithRetryLogicEnabled = this.props.sessionIntervalInfo.refreshTokenWithRetryLogicEnabled || false;
            if (refreshTokenWithRetryLogicEnabled) {
                let allowedRetryCount = this.props.sessionIntervalInfo.allowedRetries;
                let attemptedRetries = this.props.sessionIntervalInfo.refreshTokenAttempts;
                await this.props.refreshAccessTokenReTryLogic(
                    allowedRetryCount,
                    attemptedRetries
                )
            } else {
                await this.props.refreshAccessToken();
            }
        }

        if (!this.props.sessionIntervalInfo.refreshTokenExpiry) return

        const timeUntilAccessTokenExpiry = (this.props.sessionIntervalInfo.refreshTokenExpiry) * 1000;

        this.keepAliveInterval = setInterval(() => {
            this.startRefreshAccessTokenInterval(true)
        }, timeUntilAccessTokenExpiry)
    }

    render() {
        return null
    }
}

/* istanbul ignore next */
export const mapStateToProps = (state) => ({
    sessionIntervalInfo: state[STORE_NAMES.SESSION_INTERVAL_DETAILS]
})

/* istanbul ignore next */
export const mapDispatchToProps = (dispatch) => ({
    refreshAccessTokenReTryLogic: (allowedRetryCount, attemptedRetries) => dispatch(keepAliveTheAppWithRetry(allowedRetryCount, attemptedRetries)),
    refreshAccessToken: () => dispatch(keepAliveTheApp())
})



export default connect(mapStateToProps, mapDispatchToProps)(RefreshToken)