import { ACTION_TYPES } from "../../constants/store.constants";

const initialState = {
	clinicianListData: [],
	selectedClinicianData: null,
	pageConfig: null,
	loader: false,
	isFavoriteToggle: false,
	showSystemErrorModal: false,
	isFilterResetRequired: false
};

const ClinicianListReducer = (state = initialState, { type, payload } = {}) => {

	switch (type) {
		case ACTION_TYPES.CREATE_CLINICIANLIST:
			return {
				...state,
				clinicianListData: payload
			};

		case ACTION_TYPES.UPDATE_FAVORITE_STATUS:
			const updatedData = state.clinicianListData.map(item => {
				if (item.acctno === payload.id) {
					return { ...item, isFavorite: payload.isFavorite };
				}
				return item;
			});
			return {
				...state,
				clinicianListData: updatedData
			};
		case ACTION_TYPES.UPDATE_CLINICIANLIST:
			return {
				...state,
				clinicianListData: [...state.clinicianListData, ...payload]
			};
		case ACTION_TYPES.UPDATE_SELECTED_CLINICIANLIST:
			return {
				...state,
				selectedClinicianData: payload
			};
		case ACTION_TYPES.SET_CLINICIAN_LOADER:
			return {
				...state,
				loader: payload
			};

		case ACTION_TYPES.UPDATE_PAGESETTINGS:
			return {
				...state,
				pageConfig: payload
			};
		case ACTION_TYPES.UPDATE_FAVORITE_TOGGLE_CLINCIAN:
			return {
				...state,
				isFavoriteToggle: payload
			};

		case ACTION_TYPES.SHOW_SYSTEM_ERROR_MODAL:
			return {
				...state,
				showSystemErrorModal: payload
			};


		case ACTION_TYPES.UPDATE_FILTER_RESET_ON_TOGGLE:
			return {
				...state,
				isFilterResetRequired: payload
			};
		default:

			return state;
	}
};

export default ClinicianListReducer;
