import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import translations from "../../translations";
import { ACTION_TYPES, STORE_NAMES } from "../../constants/store.constants";
import DialogBox from '../DialogBox/DialogBox';
import supportActions from '../../store/actions/SupportAction';
import actions from '../../store/actions/InviteAction';
import dialPadActions from '../../store/actions/DialPadAction.js';
import { INTERPRETER_AUDIO } from '../../constants/interpreter.constants';
import { DISABLE_INVITE_LIST } from '../../constants/dialpad.constants';
import { ADD_GUEST_KEY, ADD_SUPPORT_KEY, SERVICE_NOW_KEY } from '../../constants/tab.constants';
import { DISPATCHSTATUS } from '../../constants/support.constants';

const InviteModal = () => {
    const dispatch = useDispatch();
    const translation = translations;
    const { inviteButtonClicked, status: successStatus, loading} = useSelector(state => state?.[STORE_NAMES.INVITE_BUTTON]);
    const { snowIncidentResponse, snowIncidentStatus} = useSelector(state => state?.[STORE_NAMES.SUPPORT_TAB_INFO]);
    const { errorModal } = useSelector(state => state?.[STORE_NAMES.ERROR_HANDLER]);
    const { appConfig: { clientSettings: { modalTimeout } } } = useSelector((state) => state?.[STORE_NAMES.APP]);
    const { currentTabContext } = useSelector((state) => state?.[STORE_NAMES.TABS]);
    const { type: interpreterType } = useSelector((state) => state?.[STORE_NAMES.INTERPRETER]);
    const { contact_number: phoneNumbers } = useSelector((state) => state?.[STORE_NAMES.INTERPRETER_AUDIO_INFO]);
    const { type: guestInviteType } = useSelector((state) => state?.[STORE_NAMES.GUEST]);

    const [bShowModal, setShowModal] = useState(null);
    const [body, setBody] = useState(null);
    const [body2, setBody2] = useState(null);
    const [title, setTitle] = useState(null);
    const autoCloseModalTimerRef = useRef(null);
    const isServiceArea = Array.isArray(phoneNumbers);
    const isDialPad = (interpreterType===INTERPRETER_AUDIO && !isServiceArea) || (currentTabContext===ADD_GUEST_KEY && !guestInviteType);
    const { currentSupportTab } = useSelector(state => state?.[STORE_NAMES.SUPPORT_TAB_INFO]);

    const onClose = () =>  {
        clearAutoCloseModalTimer();
        setShowModal(false);
        prepareAndSetModalDetails(true);
        dispatch(actions.setInviteButtonClickedStatus(false, successStatus));
        if(DISABLE_INVITE_LIST.includes(currentTabContext) && !isDialPad) {
            dispatch({ type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload:true });
            dispatch({ type: ACTION_TYPES.DISABLE_LANGUAGE_LIST, payload:false });
        } else if( currentSupportTab === SERVICE_NOW_KEY) {
            if(snowIncidentResponse || (snowIncidentStatus == DISPATCHSTATUS)) {
                dispatch(supportActions.resetSnowForm(true));
            }
        }
    };

    const getModalDetails = (reset) => {
        let titleDetails = null;
        let bodyDetails = null;
        let bodyDetails2 = null;
        
        if(reset)
            return { titleDetails, bodyDetails, bodyDetails2 };

        // for dialpad (connecting scenario only)
        if(isDialPad && loading) {
            titleDetails = (
                <>
                    <div className="vve-loader"></div>
                    {translation.INVITE_CONNECTING}
                </>
            );

            bodyDetails = (
                <div className="vve-connecting-body">{translation.INVITE_CONNECTING_BODY}</div>
            );
        }
        else if(currentTabContext === ADD_SUPPORT_KEY && currentSupportTab === SERVICE_NOW_KEY) {
            titleDetails = translation.SNOW_SUCCESS;
            bodyDetails = translation.SNOW_SUCCESS_BODY;
            if(snowIncidentResponse) {
                bodyDetails2 = translation.SNOW_SUCCESS_BODY2 + snowIncidentResponse;
            }
            if(!successStatus) {
                titleDetails = translation.SUBMISSION_FAILED;
                bodyDetails = '';
                bodyDetails2 = null;
            }
        }
        else {
            // for other invite scenarios
            titleDetails = successStatus ? translation.INVITE_SUCCESS : translation.INVITE_FAILED;
            bodyDetails = successStatus ? translation.PARTICIPANT_JOINING_STATUS : '';
        }
        return { titleDetails, bodyDetails, bodyDetails2 };
    };

    const prepareAndSetModalDetails = (reset = false) => {
        const { titleDetails, bodyDetails, bodyDetails2 } = getModalDetails(reset);
        setTitle(titleDetails);
        setBody(bodyDetails);
        setBody2(bodyDetails2)
    };

    const startAutoCloseModalTimer = () => {
        if (modalTimeout && autoCloseModalTimerRef.current===null) {
            autoCloseModalTimerRef.current = setTimeout(() => {
                onClose();
            }, modalTimeout);
        }
    };

    const clearAutoCloseModalTimer = () => {
        if (autoCloseModalTimerRef.current) {
            clearTimeout(autoCloseModalTimerRef.current);
            autoCloseModalTimerRef.current = null;
        }
    };

    useEffect(() => {
        if(inviteButtonClicked===true) {
            prepareAndSetModalDetails();
            setShowModal(true);
            startAutoCloseModalTimer();
        }
        // close connecting modal for server exception for dialpad only
        else if(isDialPad===true && successStatus===false && loading===false) {
            clearAutoCloseModalTimer();
            setShowModal(false);
            prepareAndSetModalDetails(true);
        }
    }, [inviteButtonClicked]);

    useEffect(() => {
        if(!errorModal) {
            dispatch(dialPadActions.setDialPadClearButtonHiddenStatus(false));
            dispatch(dialPadActions.setDialPadDisablePhoneNumber(false));
            dispatch({type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: true});
            dispatch({ type: ACTION_TYPES.DISABLE_SERVICE_AREA, payload: false });
            dispatch({ type: ACTION_TYPES.DISABLE_LANGUAGE_LIST, payload: false });
        }
    }, [errorModal]);
    
    return (
        <DialogBox
            showModal={bShowModal}
            modalTitle={title}
            modalBody={body}
            modalBody2={body2}
            onClose={onClose}
            classes={isDialPad ? {
                button: "vve-loader-action",
                modalRoot: "vve-modal-root"
            } : {}}
        >
        </DialogBox>
    );
};

export default InviteModal;