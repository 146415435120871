import apiClient from "../helper/apiClient";
import { fetchMockAppSettings, fetchMockDirectoryConfig } from '../mock/setting.mock.service';
import { SERVICE_ROUTES } from "../constants/service.constants";

export const fetchAppSettings = async (meetingId, uid, isDirectory) => {
    if (process.env.NODE_ENV !== 'development') {
        const httpClient = apiClient.getZoomAppBffInstance();
        const forDirectoryAppParam = isDirectory ? 1 : 0;
        return httpClient.get(`${SERVICE_ROUTES.FETCH_SETTINGS}?zoomMeetingId=${meetingId}&uid=${uid}&forDirectoryApp=${forDirectoryAppParam}`);
    }
    else {
        return fetchMockAppSettings();
    }
};

export const fetchDirectoryConfig = async (isDirectory) => {
    if (process.env.NODE_ENV !== 'development' && isDirectory === true) {
        const httpClient = apiClient.getClincianAppBffInstance();
        return httpClient.get(SERVICE_ROUTES.FETCH_DIRECTORY_CONFIG);
    }
    else {
        return fetchMockDirectoryConfig();
    }
};