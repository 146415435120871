import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ListGroup from 'react-bootstrap/ListGroup';
import InterpreterAction from '../../store/actions/InterpreterAction';
import ServiceAreaAction from '../../store/actions/ServiceAreaAction';
import { focusTo } from '../../helper/domHelper';
import { STORE_NAMES } from '../../constants/store.constants';
import { INTERPRETER_VIDEO } from '../../constants/interpreter.constants';
import { INVITE_BUTTON_ID } from "../../constants/dom.constants";

const LanguageList = ({ interpreterType }) => {
  const trackFirstRender = useRef(true);
  const dispatch = useDispatch();
  const languageList = useSelector((state) => state?.[STORE_NAMES.INTERPRETER]);
  const { disableLanguageList } = useSelector((state) => state?.[STORE_NAMES.INTERPRETER]);
  const { searchQuery, language: selectedLanguage, displayList } =  useSelector(state => state?.[STORE_NAMES.INTERPRETER], shallowEqual);
  const { meetingID } = useSelector((state) => state?.[STORE_NAMES.ZOOM_CLIENT]);
  const { appointmentRegion: region, appointmentVisitType: visitType } = useSelector((state) => state?.[STORE_NAMES.APPOINTMENT_DETAILS]);

  const setActiveLanguage = (activeLanguage, lineItem) => {
    return (activeLanguage && lineItem && activeLanguage?.displayName == lineItem.displayName);
  }

  const setSelectedLanguage = (language) => {
    dispatch(InterpreterAction.setInterpreterLanguage(language));
  }

  const setDisplayLanguageList = (languages) => {
    dispatch(InterpreterAction.setDisplayLanguageList(languages));
  }

  const onLanguageItemClick = (languageItem) => {
    setSelectedLanguage(languageItem);
    focusTo(INVITE_BUTTON_ID);
  }

  const searchLanguages = (query) => {
    const data = languageList.interpreterLanguageList?.filter(item => item?.languageName?.toLowerCase().includes(query));
    const searchResult = data?.length > 0 ? data : [];
    setDisplayLanguageList(searchResult);
    if (searchResult && searchResult[0]) {
      setSelectedLanguage(searchResult[0]);
    } else {
      setSelectedLanguage(null);
    }
  }

  const fetchLanguages = async () => {
    dispatch(InterpreterAction.fetchInterpreterLanguages(languageList.interpreterLanguageList, { meetingID, region, visitType }));
  }

  useEffect(() => {
    if (trackFirstRender.current) {
      trackFirstRender.current = false;
      // do something here when component reneders first time
      dispatch(InterpreterAction.setInterpreterType(INTERPRETER_VIDEO));
      dispatch(ServiceAreaAction.setInterpreterServiceArea(null));
    }
  });

  useEffect(() => {
    if (languageList?.interpreterLanguageList?.length > 0 && searchQuery == '') {
      setDisplayLanguageList(languageList.interpreterLanguageList);
    }
  }, [searchQuery])

  useEffect(() => {
    if (interpreterType === INTERPRETER_VIDEO) {
      setSelectedLanguage(null);
      if (displayList?.length < 1 && !languageList?.interpreterLanguageList) {
        fetchLanguages();
      } else {
        searchLanguages(searchQuery);
        return;
      }
    }
  }, [searchQuery]);

  const langaugesListItems = displayList?.map(
    (item, index) => {
      const itemIndex = `list-item-${index}`;
      return <ListGroup.Item 
                action="true"
                id={item.displayName}
                key={itemIndex}
                role='button'
                as='button'
                eventKey={item.displayName}
                data-testid="list-item"
                active={setActiveLanguage(selectedLanguage,item)}
                disabled={disableLanguageList || false}
                onClick={() => onLanguageItemClick(item)}>
                  {item.languageName}
              </ListGroup.Item>
    }
  );

  return (
    <ListGroup className="vve-language-list" role="document">
      {langaugesListItems}
    </ListGroup>
  );
};

LanguageList.propTypes = {
  interpreterType: PropTypes.any
};

export default LanguageList;