import { ACTION_TYPES } from "../../constants/store.constants";
import { NONE } from "../../constants/abridgePatientConsent.constants";

const initialState = {
    abridgeConsentDetails: {
        status: NONE
    }
}

const AbridgeReducer = (state= initialState, { type, payload }) => {
    switch (type) {
        case ACTION_TYPES.SET_PATIENT_ABRIDGE_CONSENT:
            return {
                ...state,
                abridgeConsentDetails: payload?.abridgeConsentDetails || initialState.abridgeConsentDetails
            }
        default:
            return state;
    }
}

export default AbridgeReducer;
