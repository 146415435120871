import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BUTTON_CONFIG from '../../constants/button.constants';
import BackButtonComponent from '../BackButton/BackButton';
import OncallProviderInfo from '../OncallProviderInfo/OncallProviderInfo';
import { STORE_NAMES } from '../../constants/store.constants';

const OncallProviderDetails = ({ backbtnclick, loadmatrix, matrixindex }) => {

    const { selectedProviderData } = useSelector(state => state?.[STORE_NAMES.ONCALL_DIRECTORY_PROVIDERLIST]);
    useEffect(() => {
        if (selectedProviderData)
            loadmatrix(matrixindex);
    }, [selectedProviderData])
    return (
        <>
            {selectedProviderData !== null ?
                <div>
                    <BackButtonComponent
                        onClick={() => backbtnclick(matrixindex)}
                        buttonText={BUTTON_CONFIG.BACK_BUTTON.title}
                    />
                    <OncallProviderInfo loadmatrix={loadmatrix} matrixindex={matrixindex}></OncallProviderInfo>
                </div>
                : null}
        </>
    );
}

OncallProviderDetails.propTypes = {
    backbtnclick: PropTypes.any,
    loadmatrix: PropTypes.any,
    matrixindex: PropTypes.any,
}

export default OncallProviderDetails;