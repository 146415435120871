const REDIRECTION_HEADER_ZOOM = 'Redirecting to Zoom';
const REDIRECTION_HEADER_EMAIL = 'Redirecting to Email';
const REDIRECTION_BODY = "Please keep this page open until the call has been connected successfully.";
const REDIRECTION_ZOOMTEXT_ONE = "If presented with a pop-up on this page, click ‘Open Zoom Meetings’ to launch the desktop application.";
const REDIRECTION_ZOOMTEXT_TWO = "Check the box to always allow Zoom to open and eliminate future pop-up notifications.";
const REDIRECTION_ZOOMTEXT_THREE = "If this page was accidentally closed or cancelled from the pop-up, the call may not be connected.";
const REDIRECTION_ZOOMTEXT_FOUR = "To retry, please try calling again through KP Health Connect (KPHC)."
const REDIRECTION_EMAILTEXT_ONE = "If presented with a pop-up on this page, select your default email client, e.g. Outlook, Mail."
const REDIRECTION_EMAILTEXT_TWO = "You may close this page at any time."

const C2C_ZOOMTEXT_DETAILS = {
    HEADER: "Redirecting to Zoom",
    BODY_LINE_ONE: "Please keep this page open until the visit has been connected successfully.",
    BODY_LINE_TWO: "If presented with a pop-up on this page, click ‘Open Zoom Meetings’ to launch the desktop application.",
    BODY_LINE_THREE: "Check the box to always allow Zoom to open and eliminate future pop-up notifications.",
    BODY_LINE_FOUR: "If this page was accidentally closed or cancelled from the pop-up, the visit may not be connected.",
    BODY_LINE_FIVE: "To retry, please refresh this page or select the camera icon again."
};

const BEFORE_REDIRECT_DELAY = 3000;
const REDIRECT_ROUTE_PATH = "redirect";

export {
    REDIRECTION_HEADER_ZOOM,
    REDIRECTION_HEADER_EMAIL,
    REDIRECTION_BODY,
    REDIRECTION_ZOOMTEXT_ONE,
    REDIRECTION_ZOOMTEXT_TWO,
    REDIRECTION_ZOOMTEXT_THREE,
    REDIRECTION_ZOOMTEXT_FOUR,
    REDIRECTION_EMAILTEXT_ONE,
    REDIRECTION_EMAILTEXT_TWO,
    C2C_ZOOMTEXT_DETAILS,
    BEFORE_REDIRECT_DELAY,
    REDIRECT_ROUTE_PATH
};