import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ABRIDGE_ACTIONS from '../../store/actions/AbridgeAction';
import { STORE_NAMES } from '../../constants/store.constants';
import Image from '../Image/Image';
import { PATIENT_CONSENT, ABRIDGE_STATUS_OPTIONS} from "../../constants/abridgePatientConsent.constants";

const AbridgeConsent = () => {

  const { abridgeConsentDetails: { status } } = useSelector(state => state?.[STORE_NAMES.ABRIDGE]);
  const { meetingID } = useSelector(state => state?.[STORE_NAMES.ZOOM_CLIENT]);
  const {
    appointmentRegion: region = null,
    appointmentVisitType: visitType = null,
    provider: { nuid = "" } = {},
    appointmentId = null
  } = useSelector((state) => state?.[STORE_NAMES.APPOINTMENT_DETAILS]);

  const dispatch = useDispatch();

  const fetchAbridgeConsent = () => {
    const payload = {
      zoomMeetingId: meetingID,
      region,
      visitType,
      appointmentId,
      providerId: nuid
    }
    dispatch(ABRIDGE_ACTIONS.fetchPatientAbridgeConsentAction(payload));
  }

  useEffect(() => {
    fetchAbridgeConsent();
  }, []);

  return (
    <div className='vve-abridge-container'>
      <p className='vve-patient-consent'>{PATIENT_CONSENT}</p>
      <div className='vve-abridge-consent'>
        <div className='vve-abridge-consent-header'>
          <Image
              className=""
              src={ABRIDGE_STATUS_OPTIONS[status].icon}
              role="img"
              alt="patientNoneConsent.svg"
            />
          <p>
          {ABRIDGE_STATUS_OPTIONS[status].header}
          </p>
        </div>
        <div className='vve-abridge-consent-header'>
          <p>
          {ABRIDGE_STATUS_OPTIONS[status].subHeader}
          </p>
        </div>
        <p className='vve-abridge-consent-info'>
        {ABRIDGE_STATUS_OPTIONS[status].info}<br /><br />
        {ABRIDGE_STATUS_OPTIONS[status].body}
        </p>
      </div>
    </div>
  )
}

export default AbridgeConsent