
import React from 'react';

const InformationBlock = ({header, body}) => {
  return (
    <div className="vve-information-container">
      <div className="vve-information-header">{header}</div>
      <div className="vve-information-body">{body}</div>
    </div>
  )
}

export default InformationBlock;