import { ACTION_TYPES } from "../../constants/store.constants";

const initialState = {
	type: null,
	language: null,
	serviceArea: null,
	searchQuery: '',
	serviceAreaIsLoading: false,
	interpreterLanguageList: null,
	displayList: [],
	disableServiceArea: null,
	disableLanguageList: null,
};

const InterpreterReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.SET_INTERPRETER_TYPE:
			return {
				...state,
				type: payload
			};
		case ACTION_TYPES.SET_INTERPRETER_LANGUAGE:
			return {
				...state,
				language: payload
			};
		case ACTION_TYPES.SET_INTERPRETER_SERVICE_AREA:
			return {
				...state,
				serviceArea: payload
			};
		case ACTION_TYPES.SET_INTERPRETER_SEARCH_QUERY:
			return {
				...state,
				searchQuery: payload
			};
		case ACTION_TYPES.SET_SERVICE_AREA_IS_LOADING:
			return {
				...state,
				serviceAreaIsLoading: payload
			};
		case ACTION_TYPES.SET_INTERPRETER_LANGUAGE_LIST:
			return {
				...state,
				interpreterLanguageList: payload
			}
		case ACTION_TYPES.SET_INTERPRETER_DISPLAY_LIST: 
			return {
				...state,
				displayList: payload
			}
		case ACTION_TYPES.DISABLE_SERVICE_AREA:
			return {
				...state,
				disableServiceArea: payload
			}
		case ACTION_TYPES.DISABLE_LANGUAGE_LIST:
			return {
				...state,
				disableLanguageList: payload
			}
		default:
			return state;
	}
};

export default InterpreterReducer;
