import { ACTION_TYPES, ACTIONS_TO_WATCH_FOR_TO_RESET_INTERPRETER_OR_GUEST } from "../../constants/store.constants";
import { ADD_INTERPRETER_KEY, ADD_GUEST_KEY } from '../../constants/tab.constants';

const resetAnyOne = ( action, getState, dispatch) => {
    const { type: actionTriggered, payload } = action;
    if(ACTIONS_TO_WATCH_FOR_TO_RESET_INTERPRETER_OR_GUEST.some(item => item===actionTriggered)) {
        // when swithching tabs
        if(payload===ADD_INTERPRETER_KEY) {
            dispatch({ type: ACTION_TYPES.SET_GUEST_INVITE_TYPE, payload: null });
            dispatch({ type: ACTION_TYPES.SET_GUEST_INVITE_EMAIL, payload: null });
            dispatch({ type: ACTION_TYPES.SET_GUEST_INVITE_MOBILE, payload: null });
            dispatch({ type: ACTION_TYPES.SET_INTERPRETER_SEARCH_QUERY, payload: '' });
        }
        if(payload===ADD_GUEST_KEY) {
            dispatch({ type: ACTION_TYPES.SET_INTERPRETER_TYPE, payload: null });
            dispatch({ type: ACTION_TYPES.SET_INTERPRETER_LANGUAGE, payload: null });
            dispatch({ type: ACTION_TYPES.SET_INTERPRETER_SERVICE_AREA, payload: null });
        }
        // when switching interpreter type
        if(actionTriggered===ACTION_TYPES.SET_INTERPRETER_TYPE){
            dispatch({ type: ACTION_TYPES.SET_INTERPRETER_SEARCH_QUERY, payload: '' });
        }
    }
}

const resetInterpreterOrGuest = ({ getState, dispatch }) => {
    return next => action => {
        // Call the next dispatch method in the middleware chain.
        const returnValue = next(action);
        resetAnyOne(action, getState, dispatch);
        return returnValue;
    };
}

export default resetInterpreterOrGuest;