import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedPhoneNumber } from '../../helper/commonHelper';
import { copyToClipboard, updateCopiedStatus } from "../../helper/oncallUtils";
import Image from "../Image/Image";
import TextCopiedChip from "../TextCopiedChip/TextCopiedChip";
import CopySmall from '../../assets/images/copy-small.svg';
import { PROVIDER_INFO, COPY_OPTIONS, COPIED_TEXT_TIMEOUT } from '../../constants/providerInfo.constants';
import { ACTION_TYPES, STORE_NAMES } from '../../constants/store.constants';

const SupportNumbersList = ({ customerservicenumbers }) => {
  const dispatch = useDispatch();
  const regionKeys = Object.keys(customerservicenumbers);
  const [copyOptions, setCopyOptions] = useState(COPY_OPTIONS);
  const { copiedPhoneNumber } = useSelector(state => state?.[STORE_NAMES.SUPPORT_TAB_INFO]);
  const textCopiedTimerRef = useRef();
  const handleCopyClick = (copyType, phoneNumber) => {
    dispatch({ type: ACTION_TYPES.SUPPORT_COPY_NUMBER, payload: '' });
    copyToClipboard(copyType, phoneNumber);
    setCopyOptions(updateCopiedStatus(copyType, true, copyOptions));
    dispatch({ type: ACTION_TYPES.SUPPORT_COPY_NUMBER, payload: phoneNumber });
    startTimerToHideTextCopiedChip(copyType);
  };
  const startTimerToHideTextCopiedChip = (copyType) => {
    if (textCopiedTimerRef.current) {
      clearTimeout(textCopiedTimerRef.current);
      textCopiedTimerRef.current = null;
    }
    textCopiedTimerRef.current = setTimeout(
      () => setCopyOptions(updateCopiedStatus(copyType, false, copyOptions)),
      COPIED_TEXT_TIMEOUT
    );
  };
  return (
    <div className='vve-support-numbers-container'>
      <ul className='vve-phone-numbers'>
        {Object.entries(customerservicenumbers).map(([state, phoneNumber]) => (
          <li key={state} >

            <div className="row">
              <div className="col col-md-6 vve-support-content">
                {state}
              </div>
              <div className="col col-md-6 vve-phoneNumber ">
                {phoneNumber === copiedPhoneNumber &&
                  <div className='vve-copy-text'>
                    <TextCopiedChip
                      copiedStatus={
                        copyOptions[PROVIDER_INFO.phone.copyType].copiedStatus
                      }
                      label={copyOptions[PROVIDER_INFO.phone.copyType].label}
                    ></TextCopiedChip>
                  </div>
                }

                <Image
                  className="vve-copypaste-icon"
                  id="office-phone-copy-icon"
                  src={CopySmall}
                  role="img"
                  alt="copy-small.svg"
                  onClick={() =>
                    handleCopyClick(PROVIDER_INFO.phone.copyType, phoneNumber)
                  }
                />

                {getFormattedPhoneNumber(phoneNumber)}

              </div>
            </div>
            {regionKeys.length > 2 && <hr className='vve-ruler' />}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SupportNumbersList;