import React, { useState, useRef, useEffect, useCallback } from 'react';
import { SNOW_HEADER, SNOW_BACK_BOTTON } from '../../constants/interpreter.constants';
import { STORE_NAMES, ACTION_TYPES } from "../../constants/store.constants";
import { useDispatch, useSelector } from "react-redux";
import _get from 'lodash.get';
import translations from "../../translations";
import Button from '../../components/Button/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import { debounce, truncateSpecialChar, getFormattedPhoneNumber, isValidMobile, mapUnformattedCursorPosition, mapformattedCursorPosition } from '../../helper/commonHelper';
import { SNOW_TEXT_CHECK } from '../../constants/tab.constants';
import actions from '../../store/actions/SupportAction';
import { SNOW_CONTACT_NOT_EMPTY_CLASS } from '../../constants/dom.constants';
import { applyMobileClass } from '../../helper/global.utilities';
import TextCopiedChip from "../TextCopiedChip/TextCopiedChip";

const ServiceNow = () => {
  const dispatch = useDispatch();
  const { category } = useSelector(state => state?.[STORE_NAMES.SUPPORT_TAB_INFO]);
  const categoryList = Object.keys(category);
  const backBtnSelected = () => {
    dispatch({ type: ACTION_TYPES.FETCH_SUPPORT_CURRENT_TAB, payload: '' });
  };
  const inputRef = useRef(null);
  const appointmentDetailsStoreData = useSelector((state) => state?.[STORE_NAMES.APPOINTMENT_DETAILS]);
  const { deviceType } = useSelector((state) => state?.[STORE_NAMES.ZOOM_CLIENT]);
  const {resetSnowForm} = useSelector((state) => state?.[STORE_NAMES.SUPPORT_TAB_INFO]);
  const userRegion = _get(appointmentDetailsStoreData, 'appointmentRegion');
  const userName = _get(appointmentDetailsStoreData, 'provider.providerName');
  const [charCount, setCharCount] = useState(SNOW_TEXT_CHECK);
  const [contactPhoneValue, setContactPhoneValue] = useState('');
  const [categoryValue, setcategoryValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const { regionCodeMapList } = useSelector((state) => state?.[STORE_NAMES.APP]);
  const displayUserRegion = regionCodeMapList[userRegion]|| userRegion;

  const remainingValues = (e) => {
    const charsEntered = e.target.value;
    setDescriptionValue(charsEntered);
    const charsLeft = SNOW_TEXT_CHECK - charsEntered.length;
    dispatch({ type: ACTION_TYPES.SUPPORT_SELECTED_SNOW_DESCRIPTION, payload:  charsEntered});
    setCharCount(charsLeft);
  };

  const canSubmitSnowTicket = () => {
    const isValidContact = contactPhoneValue.length > 0 && isValidMobile(contactPhoneValue);
    const isValidProblemDescription = descriptionValue.length > 0 && (charCount >= 0 && charCount < 1000);
    return categoryValue.length > 0 && isValidContact && isValidProblemDescription;
  };

  const applySnowContactCancelButtonStyles = (snowContactControl, processedInputValue) => {
    snowContactControl.classList.remove(SNOW_CONTACT_NOT_EMPTY_CLASS);
    if(processedInputValue.length > 0) {
      snowContactControl.classList.add(SNOW_CONTACT_NOT_EMPTY_CLASS);
    }
  };

  const debounceCursorPosition = useCallback(debounce((nextValue) => inputRef.current?.setSelectionRange(nextValue, nextValue), 50), []);

  const handleContactPhoneOnChange = (event) => {
    const currentCursorPosition = inputRef.current?.selectionStart;
    const currentInputValue = inputRef.current?.value;
    let newCursorPosition = mapUnformattedCursorPosition(currentInputValue, inputRef.current?.selectionStart);

    const inputValue = event.target.value
    const truncatedvalue = truncateSpecialChar(inputValue);
    if (isValidMobile(truncatedvalue)) {
      const formattedPhoneNumber = getFormattedPhoneNumber(truncatedvalue);
      setContactPhoneValue(formattedPhoneNumber);
      dispatch({ type: ACTION_TYPES.SUPPORT_SELECTED_SNOW_PHONE_NUMBER, payload:  truncatedvalue
       });
    } else {
      setContactPhoneValue(truncatedvalue);
    }

    if(currentInputValue.length === 10 && truncateSpecialChar(currentInputValue).length === 10 ) {
      newCursorPosition = mapformattedCursorPosition(currentCursorPosition);
      debounceCursorPosition(newCursorPosition);
    } else if (truncateSpecialChar(currentInputValue).length === 10) {
      newCursorPosition = mapformattedCursorPosition(inputRef.current?.selectionStart);
      debounceCursorPosition(newCursorPosition);
    } else {
      debounceCursorPosition(newCursorPosition);
  }
    applySnowContactCancelButtonStyles(event.target, truncatedvalue);
  };

  const handleCategorySelect = (category) => {
    setcategoryValue(category);
    dispatch({ type: ACTION_TYPES.SUPPORT_SELECTED_SNOW_CATEGORY, payload: category});
  };

  useEffect(() => {
    dispatch({ type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: canSubmitSnowTicket() });
  }, [descriptionValue, contactPhoneValue, charCount, categoryValue]);

  useEffect(() => {
    if(resetSnowForm) {
      setcategoryValue('');
      setContactPhoneValue('');
      setDescriptionValue('');
      setCharCount(SNOW_TEXT_CHECK);
      dispatch(actions.resetSnowForm(false));
      dispatch({ type: ACTION_TYPES.INVITE_BUTTON_SNOW_INCIDENT, payload: null });
      dispatch({ type: ACTION_TYPES.INVITE_BUTTON_SNOW_STATUS, payload: null });
    }
  }, [resetSnowForm])

  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  
  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  
  return (
    <div className={`vve-service-now-container ${applyMobileClass(deviceType, 'vve-landscape-snowcontainer')}`}>
      <section className='vve-padding-bottom'>
        <div className="vve-snow-header">
          <p>{SNOW_HEADER} </p> 
          <div className='vve-bck-btn'>
          <Button class="vve-back-caret-btn" onClick={backBtnSelected}>
            <span className='vve-back-btn'>{SNOW_BACK_BOTTON}</span></Button>
          </div> 
        </div>
        <p className='vve-snow-content vve-snow-text'>
          {translations.SNOW_CONTENT1}
        </p>
      </section>
      <section>
        <Form>
                    
          <Form.Group className="vve-form-label" onMouseEnter={handleHover} onMouseLeave={handleHover}>
            <Form.Label className='vve-snow-content'>{translations.SNOW_HEADER_USER}</Form.Label>
            <Form.Control 
              className={`vve-snow-disable-content ${isClicked ? 'vve-text-copied' : ''}`}
              type="text"
              value={userName}
              disabled
              onClick={handleClick}
            />
            <div className='vve-tool-tip-container'>
            <TextCopiedChip copiedStatus={isHovered || isClicked} label={userName} />
            </div>
          </Form.Group>

          <Form.Group className="vve-form-label">
            <Form.Label className='vve-snow-content'>{translations.SNOW_HEADER_REGION}</Form.Label>
            <Form.Control type="text" className="vve-snow-disable-content"
              value={displayUserRegion}
              disabled
            />
          </Form.Group>

          <Form.Group className="vve-form-label">
            <Form.Label className='vve-snow-content'>{translations.SNOW_HEADER_PHONE}</Form.Label>
            <Form.Control type="search" className="vve-snow-content vve-snow-text"
              value={contactPhoneValue}
              ref={inputRef}
              placeholder='Enter phone number' 
              onChange={handleContactPhoneOnChange}
            />
          </Form.Group>

          <Form.Group className="vve-form-label vve-snow-content">
            <Form.Label className='vve-snow-content'>{translations.SNOW_HEADER_CATEGORY}</Form.Label>
            <Dropdown onSelect={handleCategorySelect}>
              <Dropdown.Toggle variant="success" bsPrefix="dropdown-toggle vve-snow-content vve-category-header">
                {categoryValue.length > 0 ? categoryValue : 'Select'}
              </Dropdown.Toggle>
              <Dropdown.Menu bsPrefix="dropdown-menu vve-snow-content vve-category-list">
                {categoryList.map((key) => (
                  <Dropdown.Item as="div"
                                 bsPrefix={`dropdown-item vve-snow-content vve-category-item ${applyMobileClass(deviceType, 'vve-category-item-mobile-landscape')}`}
                                 key={key} 
                                 eventKey={category[key]}
                                 active={categoryValue===category[key]}>{category[key]}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>

          <p className='vve-support-content'>
            <span className='vve-snow-header vve-show-inline'>{translations.SNOW_TEXT_CONTENT1}</span><span>{translations.SNOW_TEXT_CONTENT2}</span>
          </p>
          <Form.Group className="vve-form-label vve-snow-content vve-description-box">
            <Form.Label className='vve-snow-content'>{translations.SNOW_HEADER_DESCRIPTION}</Form.Label>
            <Form.Control
              className={`vve-snow-textarea vve-snow-content ${charCount < 0 ? 'vve-red-border' : ''}`}
              as="textarea" rows={3} onChange={remainingValues} value={descriptionValue}
            />
          </Form.Group>
          <p className={`vve-snow-content vve-textarea-right ${charCount < 0 ? 'vve-red-border' : ''}`}>
            {charCount > 0 ? `+${charCount}` : charCount}
          </p>

        </Form>
      </section>
    </div>
  );
};

export default ServiceNow;