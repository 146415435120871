const ERROR_TYPE_AUTH = "authenticated";
const ERROR_TYPE_PANEL_MSG = "in-panel-msg";
const ERROR_TYPE_INITIALIZATION_ERROR = "initialization-error";
const ERROR_TYPE_APP_UNAVAILABLE = "app-unavailable";
const NO_RESULTS_FOUND = "No results found.";
const INTEGRATED_APP_UNAVAILABLE = "integrated-app-unavailable";
const NON_INTEGRATED_APP_UNAVAILABLE = "non-integrated-app-unavailable";
const ERROR_STATUS_ROUTE_MAP = {
    initializationErrorStatus: "/error",
    appUnavailableStatus: "/appunavailable",
    integratedFlowUnavailableStatus: "/integratedunavailable",
    nonIntegratedFlowUnavailableStatus: "/nonintegratedunavailable",
    directoryFlowUnavailableStatus: "/directoryunavailable"
};

export {
    ERROR_TYPE_AUTH,
    ERROR_TYPE_PANEL_MSG,
    ERROR_TYPE_INITIALIZATION_ERROR,
    ERROR_TYPE_APP_UNAVAILABLE,
    NO_RESULTS_FOUND,
    INTEGRATED_APP_UNAVAILABLE,
    NON_INTEGRATED_APP_UNAVAILABLE,
    ERROR_STATUS_ROUTE_MAP
};