import { ACTION_TYPES } from "../../constants/store.constants";

const initialState = {
	clientId: "",
	contact_number: ""
};

const InterpreterAudioReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.FETCH_APP_SETTINGS_SUCCESS:
			return {
				...state,
				...payload.interpreterAudio || null
			}
		default:
			return state;
	}
};

export default InterpreterAudioReducer;
