import { ACTION_TYPES } from "../../constants/store.constants";

const UpdateProviderList = (payload) => {
    return {
        type: ACTION_TYPES.UPDATE_PROVIDERLIST,
        payload: payload,
    };
};

const UpdateSelectedProvider = (payload) => {
    return {
        type: ACTION_TYPES.UPDATE_SELECTED_PROVIDERLIST,
        payload: payload,
    };
};

export { 
    UpdateProviderList, 
    UpdateSelectedProvider 
};
