import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { STORE_NAMES } from '../../constants/store.constants';
import { ERROR_STATUS_ROUTE_MAP } from '../../constants/error.constants';

const ErrorPageDecisionMaker = () => {
    const navigate = useNavigate();

    const { 
        initializationErrorStatus, 
        appUnavailableStatus,
        integratedFlowUnavailableStatus,
        nonIntegratedFlowUnavailableStatus,
        directoryFlowUnavailableStatus
    } = useSelector(state => state?.[STORE_NAMES.APP]);

    const getErrorNavigationRoutePath = (statusValuesMap) => {
        let routePath = null;
        for (const errorType in statusValuesMap) {
            if(!routePath) {
                routePath = statusValuesMap[errorType]===true ? ERROR_STATUS_ROUTE_MAP[errorType] : null;
            }
        }
        return routePath;
    };

    useEffect(() => {
        const statusValuesMap = {
            initializationErrorStatus,
            appUnavailableStatus,
            integratedFlowUnavailableStatus,
            nonIntegratedFlowUnavailableStatus,
            directoryFlowUnavailableStatus
        };
        const routePath = getErrorNavigationRoutePath(statusValuesMap);
        if(routePath) {
            navigate(routePath);
        }
    }, [initializationErrorStatus, appUnavailableStatus, integratedFlowUnavailableStatus, nonIntegratedFlowUnavailableStatus, directoryFlowUnavailableStatus]);

    return <Outlet />;
};

export default ErrorPageDecisionMaker;