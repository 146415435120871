import React from "react";
import Button from "../../../../components/Button/Button";

const PadKey = ({ padKey, onClick }) => {
    return (
        <div className="vve-pad-key-wrapper">
            <Button onClick={() => onClick(padKey.value)} class={`vve-dial-button vve-dialpad-${padKey.name}`}>
                <div className="row vve-key-icon" >
                    <span className="vve-dial-number">{padKey.numeric}</span>
                    <span className="vve-dial-alpha">{padKey.alpha}</span>
                </div>
            </Button>
        </div>
    );
}

export default PadKey;