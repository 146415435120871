const isPhoneNumberValid = (value = '') => {
    const phoneRegex = /^((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
    return value.match(phoneRegex)!==null;
}

const removeNonNumericCharacters = (value) => value.replace(/\D/g, "");

export {
    isPhoneNumberValid,
    removeNonNumericCharacters
}