import { ACTION_TYPES } from "../../constants/store.constants";
import { CONTEXT_ADD_GUEST, CONTEXT_ADD_INTERPRETER } from '../../constants/dialpad.constants';

const initialState = {
    context: null,
    phoneNumber: null,
    unformattedPhoneNumber: null,
    isPhoneNumberValid: null,
    hideClearButton: null,
    disablePhoneNumber: false,
    headerText: {
        [CONTEXT_ADD_GUEST]: "Enter phone number and select Invite. The guest will join via audio only.",
        [CONTEXT_ADD_INTERPRETER]: "Click Invite to add an audio-only interpreter."
    }
};

const DialPadReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.SET_DIALPAD_DETAILS:
            const { context = null, isInputValid = null, inputValue = null, inputValueNumbersOnly = null } = payload;
			return {
				...state,
                context: context,
                phoneNumber: inputValue,
                unformattedPhoneNumber: inputValueNumbersOnly,
                isPhoneNumberValid: isInputValid,
                hideClearButton: null,
                disablePhoneNumber: false
			};
        case ACTION_TYPES.SET_DIALPAD_CLEAR_BTN_HIDDEN_STATUS:
            return {
                ...state,
                hideClearButton: payload
            };
        case ACTION_TYPES.SET_DIALPAD_DISABLE_PHONENUMBER:
            return {
                ...state,
                disablePhoneNumber: payload
            };
		default:
			return state;
	}
};

export default DialPadReducer;
