import { ACTION_TYPES } from '../../constants/store.constants';
import { getParsedTrackingDetails } from '../../helper/inviteHelper';
import { postGuestApi } from "../../service/guest.service";
import setErrorAction from "./errorActions";

const setGuestInviteType = (data) => ({
	type: ACTION_TYPES.SET_GUEST_INVITE_TYPE,
	payload: data
});

const setGuestInviteEmail = (data) => async (dispatch) => {
	dispatch({ type: ACTION_TYPES.SET_GUEST_INVITE_EMAIL, payload: data });
	const inviteButtonStatus = data ? true : false;
	dispatch({ type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: inviteButtonStatus });
};

const setGuestInviteMobile = (data) => async (dispatch) => {
	dispatch({ type: ACTION_TYPES.SET_GUEST_INVITE_MOBILE, payload: data });
	const inviteButtonStatus = data ? true : false;
	dispatch({ type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: inviteButtonStatus });
};

const postGuestAction = (guestPayload) => async (dispatch) => {
	try {
		dispatch({ type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: false });
		const inviteGuestResponse = await postGuestApi(guestPayload);
		const parsedDetails = getParsedTrackingDetails(inviteGuestResponse, guestPayload);
		if(parsedDetails===null) {
			// display failed modal if invalid response
			dispatch(setErrorAction.setErrorHandler({code: 14000}));
			return false;
		}
		dispatch(setMultiInviteTrackingDetails(parsedDetails));
	} catch (err) {
		dispatch(setErrorAction.setErrorHandler(err));
	}
};

const setMultiGuestInvitees = (data) => ({
	type: ACTION_TYPES.SET_MULTI_GUEST_INVITEES,
	payload: data
});

const setMultiInviteTrackingDetails = (data) => ({
	type: ACTION_TYPES.SET_MULTI_INVITE_TRACKING_DETAILS,
	payload: data
});

const clearMultiInviteTrackingDetails = () => ({
	type: ACTION_TYPES.CLEAR_MULTI_INVITE_TRACKING_DETAILS,
	payload: null
});

export default {
	setGuestInviteType,
	setGuestInviteEmail,
	setGuestInviteMobile,
	postGuestAction,
	setMultiGuestInvitees,
	clearMultiInviteTrackingDetails
};