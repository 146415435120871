import React from "react";
import PropTypes from "prop-types";

const TextCopiedChip = (props) => {
  const { copiedStatus, label, customStyle = '' } = props;
  return (
    <>
      {copiedStatus === true && <div className={`vve-text-copied ${customStyle}`}>{label}</div>}
    </>
  );
};

TextCopiedChip.propTypes = {
  copiedStatus: PropTypes.bool,
  label: PropTypes.string,
  customStyle: PropTypes.string
};

export default TextCopiedChip;
