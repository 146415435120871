/* Module Dependencies */
import React from "react";
import PropTypes from "prop-types";

/* Custom Components */
import DropDown from "../DropDown/DropDown";

/* Constants */
import { MEDICAL_CENTER_LABEL, SELECT_LIST_PLACEHOLDER, DEPARTMENT_LABEL } from "../../constants/filter.constants";

/* Declare the component */
const FilterBody = ({ ...props }) => {
  // Re-structure props
  const {
    medicalCenterDisplayValue,
    medicalCenter,
    medicalCenterData,
    handleMedicalCenter,
    departmentDisplayValue,
    department,
    departmentData,
    handleDepartment,
  } = props;

  // Return component
  return (
    <>
      <DropDown
        dataTestid={"medical-center"}
        id="medical-center"
        label={MEDICAL_CENTER_LABEL}
        placeholder={SELECT_LIST_PLACEHOLDER}
        getDisplayValue={medicalCenterDisplayValue}
        value={medicalCenter}
        listData={medicalCenterData}
        onValueChange={handleMedicalCenter}
      />
      <DropDown
        dataTestid={"department"}
        id="department"
        label={DEPARTMENT_LABEL}
        placeholder={SELECT_LIST_PLACEHOLDER}
        getDisplayValue={departmentDisplayValue}
        value={department}
        listData={departmentData}
        onValueChange={handleDepartment}
        disabled={!medicalCenter}
        capitalizeOption={true}
        classes={{
          dropdownMenu: "vve-department-dropdown",
        }}
      />
    </>
  );
};

/* PropTypes */
FilterBody.propTypes = {
  medicalCenterDisplayValue: PropTypes.func.isRequired,
  medicalCenter: PropTypes.string.isRequired,
  medicalCenterData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleMedicalCenter: PropTypes.func.isRequired,
  departmentDisplayValue: PropTypes.func.isRequired,
  department: PropTypes.string.isRequired,
  departmentData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleDepartment: PropTypes.func.isRequired,
};

/* Export component */
export default FilterBody;
