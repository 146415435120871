const ADD_INTERPRETER_KEY = 'add_interpreter';
const ADD_INTERPRETER_TITLE = 'Add Interpreter';
const ADD_GUEST_KEY  = 'add_guest';
const ADD_GUEST_TITLE  = 'Add Guest';
const ADD_SUPPORT_KEY  = 'support';
const ADD_SUPPORT_TITLE  = 'Support';
const SERVICE_NOW_KEY = "SNOW";
const SNOW_TEXT_CHECK = 1000;


const ONCALL_KEY = 'On_Call';
const ONCALL_TITLE = 'On Call';
const CLINICIAN_KEY = 'Clinician';
const CLINICIAN_TITLE = 'Clinician';
// used for tab settings only
const ADD_INTERPRETER_TAB = "Interpreter";
const ADD_GUEST_TAB = "Invite-Guest";
const ONCALL_CLINICIAN_TABS_REGION = ["MAS"];

/** 
 * Please make sure the feature texts and keys are same as directory config response. 
 * Continue adding and updating here as and when new menu is introduced  
 * */
const ONCALL_FEATURE = "oncall";
const DIRECTORY_FEATURE = "directory";
const REPORT_FEATURE = "reports";
const SUPPORT_FEATURE = "support";
const NEW_MENU_TABS = {
    [ONCALL_FEATURE]: { label: "ON CALL" },
    [DIRECTORY_FEATURE]: { label: "DIRECTORY" },
    [REPORT_FEATURE]: { label: "REPORTS" },
    [SUPPORT_FEATURE]: { label: "SUPPORT" }
}
const OLD_TAB_KEY_MAP_FOR_NEW_MENU = {
    [ONCALL_FEATURE]: ONCALL_KEY,
    [DIRECTORY_FEATURE]: CLINICIAN_KEY
};

/**
 * Following constants is for maintaining new menu for invisit experience
 * Update the following as and when new menu items are introduced for invisit experience
 */
const INVISIT_ABRIDGE_KEY = 'abridge';
const INVISIT_INTERPRETER_KEY = 'addInterpreter';
const INVISIT_ADD_GUEST_KEY = 'addGuest';
const INVISIT_SUPPORT_KEY = 'support';
const ALLOWED_INVISIT_MENU_ITEMS = ["abridge", "addInterpreter", "addGuest", "support"];
const INVISIT_MENU_OPTIONS = {
    [INVISIT_ABRIDGE_KEY]: { label: "Abridge" },
    [INVISIT_INTERPRETER_KEY]: { label: "Interpreter" },
    [INVISIT_ADD_GUEST_KEY]: { label: "Guest" },
    [INVISIT_SUPPORT_KEY]: { label: "Support" }
};
const OLD_TAB_KEY_MAP_FOR_INVISIT_MENU = {
    [INVISIT_ABRIDGE_KEY]: INVISIT_ABRIDGE_KEY,
    [INVISIT_INTERPRETER_KEY]: ADD_INTERPRETER_KEY,
    [INVISIT_ADD_GUEST_KEY]: ADD_GUEST_KEY,
    [INVISIT_SUPPORT_KEY]: ADD_SUPPORT_KEY
};

export {
    ADD_INTERPRETER_KEY,
    ADD_INTERPRETER_TITLE,
    ADD_GUEST_KEY,
    ADD_GUEST_TITLE,
    ADD_INTERPRETER_TAB,
    ADD_GUEST_TAB,
    ONCALL_KEY,
    ONCALL_TITLE,
    CLINICIAN_KEY,
    CLINICIAN_TITLE,
    ONCALL_CLINICIAN_TABS_REGION,
    ADD_SUPPORT_KEY,
    ADD_SUPPORT_TITLE,
    SERVICE_NOW_KEY,
    SNOW_TEXT_CHECK,
    ONCALL_FEATURE,
    DIRECTORY_FEATURE,
    REPORT_FEATURE,
    SUPPORT_FEATURE,
    NEW_MENU_TABS,
    OLD_TAB_KEY_MAP_FOR_NEW_MENU,
    ALLOWED_INVISIT_MENU_ITEMS,
    INVISIT_ABRIDGE_KEY,
    INVISIT_INTERPRETER_KEY,
    INVISIT_ADD_GUEST_KEY,
    INVISIT_SUPPORT_KEY,
    INVISIT_MENU_OPTIONS,
    OLD_TAB_KEY_MAP_FOR_INVISIT_MENU
};
