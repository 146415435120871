import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ClinicianSearchDirectory from '../ClinicianSearchDirectory/ClinicianSearchDirectory';
import ClinicianList from "../ClinicianList/ClinicianList";
import { CLINICIAN_KEY } from '../../constants/tab.constants';
import ClinicianDetails from "../ClinicianDetails/ClinicianDetails";
import TabAction from '../../store/actions/TabAction';
import { useDispatch } from 'react-redux';

const ClinicianDirectory = ({...props}) => {
  const { regionStyleClass } = props;
  const clinicianMobileView = [true, false];
  const [mobileViewMatrix, setMobileViewMatrix] = useState(clinicianMobileView);
  const dispatch = useDispatch();

  const loadmatrix = (columnIndex) => {
    const updatedMatrix = [...mobileViewMatrix];
    updatedMatrix[columnIndex - 1] = false;
    setMobileViewMatrix(updatedMatrix);
    dispatch(TabAction.UpdateMobileMatrix({ tab: CLINICIAN_KEY, matrix: updatedMatrix }));

  }

  const backbtnclick = (columnIndex) => {
    const updatedMatrix = [...mobileViewMatrix];
    updatedMatrix[columnIndex - 1] = true;
    updatedMatrix[columnIndex] = false;
    setMobileViewMatrix(updatedMatrix);
    dispatch(TabAction.UpdateMobileMatrix({ tab: CLINICIAN_KEY, matrix: updatedMatrix }));

  }

  const fwdbtnclick = (columnIndex) => {
    const updatedMatrix = [...mobileViewMatrix];
    updatedMatrix[columnIndex] = false;
    updatedMatrix[columnIndex + 1] = true;
    setMobileViewMatrix(updatedMatrix);
    dispatch(TabAction.UpdateMobileMatrix({ tab: CLINICIAN_KEY, matrix: updatedMatrix }));

  }

  useEffect(()=>{
    dispatch(TabAction.UpdateMobileMatrix({ tab: CLINICIAN_KEY, matrix: clinicianMobileView }));
  },[])

  return (
    <div className={`vve-clinician-directory${regionStyleClass ? ' vve-only-clinician' : ''}`}>
      <div className="vve-clinician-container">
        <div className={`vve-clinician-column  ${mobileViewMatrix[0] ? '' : 'mobile-hide'}`}>
          <ClinicianSearchDirectory/>
          <ClinicianList fwdbtnclick={fwdbtnclick} matrixindex={0}/>
        </div>
        <div className={`vve-clinician-column ${mobileViewMatrix[1] ? '' : 'mobile-hide'}`}>
          <ClinicianDetails backbtnclick={backbtnclick} loadmatrix={loadmatrix} matrixindex={1}/>
        </div>
      </div>
    </div>
  );
};

/* PropTypes */
ClinicianDirectory.propTypes = {
  regionStyleClass: PropTypes.bool,
}

export default ClinicianDirectory;