import React, { useState } from 'react';
import { useSelector } from "react-redux";
import Form from 'react-bootstrap/Form';
import MultiParticipantInvite from '../MultiParticipantInvite/MultiParticipantInvite';
import DialPadContainer from '../DialPadContainer/DialPadContainer';
import { GUEST_INVITE_TEXT, GUEST_INVITE_AUDIO } from "../../constants/guest.constants";
import { CONTEXT_ADD_GUEST } from "../../constants/dialpad.constants";
import { getRadioButtonsForGuest } from "../../helper/commonUtils";
import { STORE_NAMES } from "../../constants/store.constants";

const AddGuest = () => {

  const { tabSettings } = useSelector(state => state?.[STORE_NAMES.TABS]);
  const { RadioButtons, guestDefaultValue } = getRadioButtonsForGuest(tabSettings);

  const [guestInviteType, setGuestInviteType] = useState(guestDefaultValue);
  const onGuestInviteTypeSelect = (event) => setGuestInviteType(event.target.value);

  return (
    <div className="add-guest-container">
      <Form>
        <div key="default-radio" className="p-3">
          {RadioButtons.map((radioButton, index) => {
            const btnIndex = `radio-${index}`;
            return <Form.Check
              key={btnIndex}
              type="radio"
              label={radioButton.label}
              name={radioButton.name}
              value={radioButton.value}
              defaultChecked={radioButton.checked}
              onChange={onGuestInviteTypeSelect}
              inline
            />
          })}
        </div>
      </Form>

      {guestInviteType===GUEST_INVITE_TEXT &&
        <MultiParticipantInvite></MultiParticipantInvite>
      }

      {guestInviteType===GUEST_INVITE_AUDIO &&
        <DialPadContainer context={CONTEXT_ADD_GUEST}></DialPadContainer>
      }
    </div>
  );
};

export default AddGuest;