import { ACTION_TYPES } from "../../constants/store.constants";

const initialState = {
	category: {},
	currentSupportTab: '',
	supportNumbers: {
		clinician: {},
		patients: {}
	},
	snowEnabled: true,
	copiedPhoneNumber: null,
	selectedSnowContactPhoneNumber: null,
	selectedSnowCategory: null,
	selectedSnowDescription: null,
	snowIncidentResponse: null,
	snowIncidentStatus: null,
	resetSnowForm: false
	
};

const SupportReducer = (state = initialState, { type, payload }) => {
	switch (type) {

		case ACTION_TYPES.FETCH_SUPPORT_INFO:
			return {
				...state,
				supportNumbers: {
					clinician: payload?.clinician,
					patients: payload?.patients,
				}
			};
		case ACTION_TYPES.FETCH_SNOW_ENABLED:
			return {
				...state,
				snowEnabled: payload
			};
		case ACTION_TYPES.FETCH_SUPPORT_CURRENT_TAB:
			return {
				...state,
				currentSupportTab: payload
			};
		case ACTION_TYPES.FETCH_SUPPORT_CATEGORY:
			return {
				...state,
				category: payload
			};
		case ACTION_TYPES.SUPPORT_COPY_NUMBER:
			return {
				...state,
				copiedPhoneNumber: payload
			};
		case ACTION_TYPES.SUPPORT_SELECTED_SNOW_PHONE_NUMBER:
			return {
				...state,
				selectedSnowContactPhoneNumber: payload
			};
		case ACTION_TYPES.SUPPORT_SELECTED_SNOW_CATEGORY:
			return {
				...state,
				selectedSnowCategory: payload
			};
		case ACTION_TYPES.SUPPORT_SELECTED_SNOW_DESCRIPTION:
			return {
				...state,
				selectedSnowDescription: payload
			};
		case ACTION_TYPES.INVITE_BUTTON_SNOW_INCIDENT:
			return {
				...state,
				snowIncidentResponse: payload
			};
		case ACTION_TYPES.INVITE_BUTTON_SNOW_STATUS:
			return {
				...state,
				snowIncidentStatus: payload
			};
		case ACTION_TYPES.RESET_SNOW_FORM:
			return {
				...state,
				resetSnowForm: payload
			};
		default:
			return state;
	}
};

export default SupportReducer;
