const PARAM_KEY_UID = 'uid';
const PARAM_KEY_REDIRECT_LINK = "redirectTo";
const PARAM_KEY_MEETING_UUID = "zoomMeetingUuid";
const PARAM_KEY_ATTENDEE_REPORT_FILE = "fileName";
const VISIT_TYPES = {
    OP: "OP",
    GV: "GV",
    NI: "NI"
};
const NUID_ID_TYPE = "NUID";
const PARAM_KEY_C2C_REDIRECT = "clinicianToClinicianInviteRedirect";

export {
    PARAM_KEY_UID,
    PARAM_KEY_REDIRECT_LINK,
    PARAM_KEY_ATTENDEE_REPORT_FILE,
    PARAM_KEY_MEETING_UUID,
    VISIT_TYPES,
    NUID_ID_TYPE,
    PARAM_KEY_C2C_REDIRECT
};
