import { ACTION_TYPES } from "../../constants/store.constants";

const initialState = {
	departmentListData: [],
	selectedDepartment: '',
	loader: false
};

const DepartmentListReducer = (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ACTION_TYPES.CREATE_DEPARTMENTLIST:
			return {
				...state,
				departmentListData: payload
			};
		case ACTION_TYPES.UPDATE_DEPARTMENTLIST:
			return {
				...state,
				departmentListData: [...state.departmentListData, ...payload]
			};

		case ACTION_TYPES.UPDATE_SELECTED_DEPARTMENTNAME:
			return {
				...state,
				selectedDepartment: payload
			};

		case ACTION_TYPES.SET_DEPARTMENT_LOADER:
			return {
				...state,
				loader: payload
			};

		default:
			return state;
	}
};

export default DepartmentListReducer;
