import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "../../constants/store.constants";
import { COPY_OPTIONS, COPIED_TEXT_TIMEOUT } from "../../constants/providerInfo.constants";
import { getProviderDetailnextOncall } from "../../service/oncall.service";
import {
  formatOnCallDate,
  formatNextOncallDate,
  copyToClipboard,
  getDefaultSrc,
  updateCopiedStatus,
  handlePhoneClick,
  handleEmailClick,
} from "../../helper/oncallUtils";
import { isDeviceMobile } from "../../helper/global.utilities";
import Loader from "../Loader/Loader";
import DetailedInfo from "../DetailedInfo/DetailedInfo";

const OncallProviderInfo = () => {
  const [nextOnCallFormattedDate, setNextOnCallFormattedDate] = useState("");
  const [onCallFormattedDate, setOnCallFormattedDate] = useState("");
  const [copyOptions, setCopyOptions] = useState(COPY_OPTIONS);
  const textCopiedTimerRef = useRef();
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [loader, setLoader] = useState(false);

  const { selectedProviderData } = useSelector(
    (state) => state?.[STORE_NAMES.ONCALL_DIRECTORY_PROVIDERLIST]
  );
  const { deviceType } = useSelector(
    (state) => state?.[STORE_NAMES.ZOOM_CLIENT]
  );
  const { directoryRegion: region = null, featureFlags } = useSelector(
    (state) => state?.[STORE_NAMES.DIRECTORY_CONFIG]
  );
  const { selectedDepartment } = useSelector(
    (state) => state?.[STORE_NAMES.ONCALL_DIRECTORY_DEPARTMENTLIST]
  );

  const {
    department: { name: departmentName = "" } = {},
    isCurrentlyOnCall = null,
    imageURL = null,
    firstName = "",
    lastName = "",
    role = "",
    order = "",
    contactDetails: {
      officePhone = null,
      mobilePhone = null,
      email = null,
      pager: { pager: pagerNumber = null } = {},
    } = {},
    notes = null,
  } = selectedProviderData;

  const fetchProviderDetailnextOncall = ({
    starDateTime,
    endDateTime,
    acctno,
  }) => {
    setLoader(true);
    setCopyOptions(COPY_OPTIONS);
    setNextOnCallFormattedDate(""); //reset old state
    const params = {
      startDateTime: starDateTime,
      endDateTime: endDateTime,
      accountNumber: acctno,
      limit: 10,
      offset: 0,
      region,
    };
    const dept = selectedDepartment;
    getProviderDetailnextOncall(params, dept, callBackFn);
  };

  const callBackFn = (response) => {
    setLoader(false);
    const { data = null } = response;
    setNextOnCallFormattedDate(formatNextOncallDate(data));
  };

  const startTimerToHideTextCopiedChip = (copyType) => {
    if (textCopiedTimerRef.current) {
      clearTimeout(textCopiedTimerRef.current);
      textCopiedTimerRef.current = null;
    }
    textCopiedTimerRef.current = setTimeout(
      () => setCopyOptions(updateCopiedStatus(copyType, false, copyOptions)),
      COPIED_TEXT_TIMEOUT
    );
  };

  const handleCopyClick = (copyType, text) => {
    copyToClipboard(copyType, text);
    setCopyOptions(updateCopiedStatus(copyType, true, copyOptions));
    startTimerToHideTextCopiedChip(copyType);
  };

  useEffect(() => {
    const { starDateTime, endDateTime } = selectedProviderData;
    const format = formatOnCallDate(starDateTime, endDateTime);
    setOnCallFormattedDate(format);
    fetchProviderDetailnextOncall(selectedProviderData);
  }, [selectedProviderData]);

  useEffect(() => {
    setIsMobileDevice(isDeviceMobile(deviceType));
  }, []);

  return (
    <div
      className={`${loader ? "vve-container" : ""} vve-provider-info-container`}
    >
      {loader ? (
        <Loader showLoader={loader} />
      ) : (
        <DetailedInfo
          departmentName={departmentName}
          isCurrentlyOnCall={isCurrentlyOnCall}
          imageURL={imageURL}
          getDefaultSrc={getDefaultSrc}
          firstName={firstName}
          lastName={lastName}
          role={role}
          order={order}
          officePhone={officePhone}
          copyOptions={copyOptions}
          handleCopyClick={handleCopyClick}
          mobilePhone={mobilePhone}
          email={email}
          pagerNumber={pagerNumber}
          isMobileDevice={isMobileDevice}
          onCallFormattedDate={onCallFormattedDate}
          nextOnCallFormattedDate={nextOnCallFormattedDate}
          notes={notes}
          featureFlags={featureFlags}
          handlePhoneClick={handlePhoneClick}
          handleEmailClick={handleEmailClick}
        />
      )}
    </div>
  );
};

export default OncallProviderInfo;
