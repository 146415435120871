import apiClient from "../helper/apiClient";
import MOCK_LANGUAGE_RESPONSE from '../mock/languages-list.json';
import { SERVICE_ROUTES } from "../constants/service.constants";

export const getLanguages = async ({ meetingID, region, visitType }) => {
    if(process.env.NODE_ENV !== 'development') {
        try {
            const httpClient = apiClient.getZoomAppBffInstance();
            const serviceUrl = `${SERVICE_ROUTES.FETCH_LANGUAGE_ROUTE}`;
            const endPoint = serviceUrl.replace('{zoomMeetingID}', meetingID).replace('{region}', region).replace('{visitType}', visitType);
            const response = await httpClient.get(endPoint);
            return response?.languages;
        }
        catch(error) {
            return  { error: error };
        }
    }
    return MOCK_LANGUAGE_RESPONSE.languages;
}

export const postInterpreterApi = async (payload) => {
    const httpClient = apiClient.getZoomAppBffInstance();
    return httpClient.post(SERVICE_ROUTES.FETCH_INTERPRETER_SERVICE_ROUTE, payload);
}

export const sendAudioInvitation = async (payload) => {
    const httpClient = apiClient.getZoomAppBffInstance();
    return httpClient.post(SERVICE_ROUTES.INVITE_AUDIO_INTERPRETER_SERVICE_ROUTE, payload);
};