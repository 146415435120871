/* Module Dependencies */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

/* Store */
import { CreateClinicianList, UpdatePageSettings, setClinicianLoader, UpdateSelectedClinician } from "../../store/actions/ClinicianListAction";

/* Constants */
import { STORE_NAMES } from "../../constants/store.constants";
import { CLINICIAN_KEY, ONCALL_CLINICIAN_TABS_REGION } from "../../constants/tab.constants";
import { SELECT_LIST_PLACEHOLDER, CLINICIAN_FILTER_MODAL_TITLE, ALL } from "../../constants/filter.constants";

/* custom comp */
import FilterModal from "../FilterModal/FilterModal";
import ClinicianFilterBody from "./ClinicianFilterBody";

/* Utils */
import { ValidateSearchText } from "../../helper/oncallUtils";
import { getClinicianList, getClinicianDeptListOnMC, getFileteredClinicianList, getClinicianMedicalCenters } from "../../service/clinician.service";
import Search from "../Search/Search";
import { getCapitalizedName } from "../../helper/commonUtils";

/* Declare the component */
const ClinicianSearchDirectory = () => {
  const dispatch = useDispatch();
  const { currentTabContext } = useSelector(
    (state) => state?.[STORE_NAMES.TABS]
  );
  const { directoryRegion: region = null, clinicianFilterDetails } = useSelector((state) => state?.[STORE_NAMES.DIRECTORY_CONFIG]);
  const {
    isFavoriteToggle,
    isFilterResetRequired,
  } = useSelector(
    (state) => state?.[STORE_NAMES.CLINICIAN_DIRECTORY_CLINICIANLIST]
  );
  const {
    pageConfig,
  } = useSelector(
    (state) => state?.[STORE_NAMES.CLINICIAN_DIRECTORY_CLINICIANLIST]
  );
  const [medicalCentersListData, setMedicalCentersListData] = useState([])

  // Declare state variables
  const [query, setQuery] = useState("");
  const [ShowFilter, setShowFilter] = useState(false);
  const [medicalCenter, setMedicalCenter] = useState("");
  const [department, setDepartment] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  const [clinicianRegion, setClinicianRegion] = useState(region);
  const [clinicianRegionList, setClinicianRegionList] = useState([]);
  const [clinicianType, setClinicianType] = useState(ALL);
  const [clinicianTypeList, setClinicianTypeList] = useState([]);

  const abortController = new AbortController();
  const controllerRef = useRef(null);

  useEffect(() => {
    if (isFilterResetRequired && pageConfig) {
      const { region } = pageConfig;
      onFilterReset(region)
    }
  }, [isFilterResetRequired])

  // callback to get the respone from department API
  const callBackFn = (pageParams, data) => {
    dispatch(CreateClinicianList(data));
    dispatch(UpdatePageSettings(pageParams));
    dispatch(setClinicianLoader(false));
  };

  const getParams = (query = "", region = "", type = "", medCenter = "", dept = "") => ({
    limit: 200,
    offset: 0,
    searchString: query,
    employeeType: type,
    orderBy: "lastName",
    order: "ASC",
    region,
    department: dept,
    medicalCenter: medCenter
  })

  // Fetch Clinician list
  const fetchClinicianList = (query = "", dirRegion = region, type = ALL) => {
    dispatch(setClinicianLoader(true));
    const params = getParams(query, dirRegion, type);
    if (controllerRef.current) {
      controllerRef.current.abort(); // Cancel the previous request
    }

    controllerRef.current = new AbortController();
    getClinicianList(params, callBackFn, controllerRef.current);
  };

  // Fetch Clinician list
  const fetchFilteredClinicianList = (filteredParams, query = "") => {
    const params = {
      limit: 200,
      offset: 0,
      searchString: query,
      employeeType: clinicianType,
      orderBy: "lastName",
      order: "ASC",
    };

    const callBackFn = (params, data) => {
      dispatch(CreateClinicianList(data));
    }

    getFileteredClinicianList(filteredParams, params, callBackFn);
  };

  // Search to update the dept list based on user input
  const onSearchInput = (event) => {
    let { value } = event.target;
    value = ValidateSearchText(value);
    if (value === "" || value.length === 1) onFilterReset(region); // will call onFilterReset() only if the value length is 1 or empty
    setQuery(value);
    fetchClinicianList(value);
  };
  // clear search content when toggle is on
  useEffect(() => {
    if (isFavoriteToggle) {
      setQuery("");
    }
    if (pageConfig) {
      const { region } = pageConfig;
      fetchClinicianList(null, region, ALL);
    }
  }, [isFavoriteToggle]);

  // show/hide Filter
  const toggleFilterModal = () => setShowFilter((val) => !val);

  // Reset Filter
  const onFilterReset = (region) => {
    setMedicalCenter("");
    setDepartment("");
    setClinicianRegion(region);
    setClinicianType(ALL);
    setMedicalCentersListData([]);
    fetchMedicalCenterList(region);
  };

  // Medical center dropdown display value
  const getMedicalCenterDisplayValue = () => {
    if (medicalCenter) {
      const selectedMedicalCenter = medicalCentersListData.find(
        (val) => val.id === medicalCenter
      );
      return selectedMedicalCenter.name;
    }
    return SELECT_LIST_PLACEHOLDER;
  };

  // Handle Medical center dropdown
  const handleMedicalCenter = (val) => {
    setDepartmentList([])
    setMedicalCenter(val);
    setDepartment("");
    const callBackFn = (data) => {
      setDepartmentList((prevDataList) => [...prevDataList, ...data]);
    };
    if (val)
      getClinicianDeptListOnMC({ region: clinicianRegion, medicalCenter: val }, callBackFn);
  };

  // Department dropdown display value
  const getDepartmentDisplayValue = () => {
    if (department) {
      const selectedDepartment = departmentList.find(
        (val) => val.id === department
      );
      return selectedDepartment.name;
    }
    return SELECT_LIST_PLACEHOLDER;
  };

  const getClinicianRegionDisplayValue = () => {
    if (clinicianRegion) {
      const selected = clinicianRegionList.find(
        (val) => val.id === clinicianRegion
      );
      return selected.name;
    }
  }

  const getClinicianTypeDisplayValue = () => {
    if (clinicianType) {
      const selected = clinicianTypeList.find(
        (val) => val.id === clinicianType
      );
      return getCapitalizedName(selected.name);
    }
  }

  // Handle Department dropdown
  const handleDepartment = (val) => {
    setDepartment(val);
  }

  const handleClinicianRegion = (val) => {
    setClinicianRegion(val);
    setMedicalCenter("");
    setDepartment("");
    setMedicalCentersListData([]);
    fetchMedicalCenterList(val);
  }

  const fetchMedicalCenterList = (selectedRegion) => {
    const callBackFn = (data) => {
      setMedicalCentersListData((prevDataList) => [...prevDataList, ...data]);
    };
    getClinicianMedicalCenters(selectedRegion, callBackFn);
  }

  const handleClinicianType = (val) => {
    setClinicianType(val);
  }

  const onFilterApply = () => {
    const params = { region: clinicianRegion, medicalCenter: medicalCenter };
    if (clinicianRegion && clinicianType && medicalCenter && department) {
      const deptParams = { ...params, departmentid: department }
      fetchFilteredClinicianList(deptParams, '');
    }
    else if (clinicianRegion && clinicianType && medicalCenter) {
      fetchFilteredClinicianList(params, '');
    }
    else {
      fetchClinicianList(null, clinicianRegion, clinicianType);
    }
    setQuery("");
    toggleFilterModal();
    dispatch(UpdateSelectedClinician(null));
    dispatch(UpdatePageSettings(getParams('', clinicianRegion, clinicianType, medicalCenter, department)));
  }

  // Fetch Clinician list on every page load
  useEffect(() => {
    if (currentTabContext == CLINICIAN_KEY || !ONCALL_CLINICIAN_TABS_REGION.includes(region)) {
      fetchClinicianList();
      setClinicianRegionList(clinicianFilterDetails.region.options);
      setClinicianTypeList(clinicianFilterDetails.providerType.options);
      fetchMedicalCenterList(clinicianRegion);
    }
    return () => abortController.abort();
  }, []);

  // Return component
  return (
    <>
      <Search
        query={query}
        isFavoriteToggle={isFavoriteToggle}
        onSearchInput={onSearchInput}
        toggleFilterModal={toggleFilterModal}
      />
      <FilterModal
        showModal={ShowFilter}
        onClose={toggleFilterModal}
        modalTitle={CLINICIAN_FILTER_MODAL_TITLE}
        modalBody={
          <ClinicianFilterBody
            clinicianRegion={clinicianRegion}
            clinicianRegionData={clinicianRegionList}
            clinicianRegionDisplayValue={getClinicianRegionDisplayValue}
            handleClinicianRegion={handleClinicianRegion}

            clinicianType={clinicianType}
            clinicianTypeData={clinicianTypeList}
            clinicianTypeDisplayValue={getClinicianTypeDisplayValue}
            handleClinicianType={handleClinicianType}

            medicalCenterDisplayValue={getMedicalCenterDisplayValue}
            medicalCenter={medicalCenter}
            medicalCenterData={medicalCentersListData}
            handleMedicalCenter={handleMedicalCenter}
            departmentDisplayValue={getDepartmentDisplayValue}
            department={department}
            departmentData={departmentList}
            handleDepartment={handleDepartment}
          />
        }
        resetEnable={medicalCenter || department || clinicianRegion || clinicianType}
        onReset={() => onFilterReset(region)}
        onApply={onFilterApply}
      />
    </>
  );
};

/* Export component */
export default ClinicianSearchDirectory;
