import apiClient from "../helper/apiClient";
import MOCK_CARD_DATA from "../mock/groupclass-cardContent.json"
import { SERVICE_ROUTES } from "../constants/service.constants";
import MOCK_GROUP_CLASS_TABLE_RESPONSE from "../mock/group-class-table.json";

export const getGroupClassData =async (startDate, endDate, nuid, region) => {
    if(process.env.NODE_ENV !== 'development') {
        const httpClient = apiClient.getZoomAppBffInstance();
        const endPoint = SERVICE_ROUTES.FETCH_GROUP_LIST.replace('{nuid}', nuid).replace('{startdate}', startDate).replace('{enddate}', endDate).replace('{region}', region);
        return httpClient.get(endPoint);
    }
    return MOCK_CARD_DATA;
}

export const getGroupParticipantData = (zoomMeetingUUID) => {
    if (process.env.NODE_ENV !== 'development') {
        const httpClient = apiClient.getZoomAppBffInstance();
        const serviceUrl = `${SERVICE_ROUTES.FETCH_GROUP_PARTICIPANT_LIST}`;
        const endPoint = serviceUrl.replace('{zoomMeetingUUID}', encodeURIComponent(zoomMeetingUUID));
        const response = httpClient.get(endPoint);
        return response;
    }
    return MOCK_GROUP_CLASS_TABLE_RESPONSE;
}

export const downloadAttendeeReport = ({ zoomMeetingUuid, authToken }) => {
    const httpClient = apiClient.getReportDownloadInstance();
    const endPoint = SERVICE_ROUTES.DOWNLOAD_ATTENDEE_REPORT.replace('{zoomMeetingUuid}', zoomMeetingUuid);
    return httpClient.get(endPoint, { headers: { Authorization: `Bearer ${authToken}` }, responseType: "blob" });
};
