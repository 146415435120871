import apiClient from '../helper/apiClient';
import { SERVICE_ROUTES } from '../constants/service.constants';

export const fetchPingTokenDetails = async() => {
    const httpClient = apiClient.getZoomAppBffInstance();
    return httpClient.post(SERVICE_ROUTES.FETCH_PING_TOKEN_DETAILS);
}

export const fetchAttendeeReportDownloadToken = async () => {
    const httpClient = apiClient.getZoomAppBffInstance();
    return httpClient.get(SERVICE_ROUTES.FETCH_ATTENDEE_REPORT_DOWNLOAD_TOKEN);
}


