import {
  NUMBER_CHARS_ONLY_FOR
} from "../constants/providerInfo.constants";
import { copyTextToClipboard } from "./commonHelper";
import { removeNonNumericCharacters } from "./dialPadHelper";
import photo from "../assets/images/dr-photo-avatar-medium.svg";
import ZoomAppClient from "./zoomAppClient";
import { PARAM_KEY_REDIRECT_LINK } from "../constants/app.constants";

const REDIRECT_LINK = `${location.origin}/redirect`;

export const ValidateSearchText = (value) => {
  // Remove any characters that are not letters, numbers, -, space, ., ', comma
  value = value.replace(/[^a-zA-Z0-9\-.\'\s]/g, "");

  // Ensure the length does not exceed 50 characters
  if (value.length > 50) {
    value = value.slice(0, 50);
  }
  return value;
};

const isEllipsisActive = (e) => {
  return e.offsetWidth < e.scrollWidth;
};

export const attachHoverforEllipsedText = (value) => {
  //Attaching hover for Ellipsised text
  let elementList = document.getElementsByClassName(value);
  for (let idx = 0; idx < elementList.length; idx++) {
    if (isEllipsisActive(elementList.item(idx))) {
      elementList.item(idx).title = elementList.item(idx).innerHTML;
    }
  }
};

const localToUtcTimestamp = (localDate) => {
  // Get the UTC timestamp by calling getTime() method
  const utcTimestamp = localDate.getTime();

  return utcTimestamp;
};

const getStartOfDay = (date) => {
  // Clone the date to avoid modifying the original date
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0); // Set time to midnight (00:00:00.000)

  return startOfDay;
};

const getEndOfDay = (date) => {
  // Clone the date to avoid modifying the original date
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999); // Set time to one millisecond before midnight (23:59:59.999)

  return endOfDay;
};

export const getUTCTimestamp = (localDate) => {
  const startTime = getStartOfDay(localDate);
  const endTime = getEndOfDay(localDate);

  const startDateTimeUTC = localToUtcTimestamp(startTime);
  const endDateTimeUTC = localToUtcTimestamp(endTime);

  return {
    startDateTimeUTC,
    endDateTimeUTC,
  };
};

export const isCallNow = (startDate, endDate) => {
  // Convert the date strings to Date objects
  const now = new Date();
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  // Check if the dateToCheck is between startDate and endDate
  return now >= startDateObj && now <= endDateObj;
};

export const formatDateOnCallFeature = (dateTimeStampUTC) => {
  // 09/31 04:30 PM
  let date = new Date(dateTimeStampUTC);
  return (
    date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
    }) + ", " + date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // Use AM/PM
    })
  );
};

export const formatOnCallDate = (starDateTime, endDateTime) => {
  const isOnCall = isCallNow(starDateTime, endDateTime);
  const startDate = formatDateOnCallFeature(starDateTime);
  const endDate = formatDateOnCallFeature(endDateTime);
  const format = isOnCall ? `Now -${endDate}` : `${startDate} - ${endDate}`;
  return format;
};

export const formatDates = (startDate, endDate) => {
  const options = {
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };

  const options2 = {
    hour: "2-digit",
    minute: "2-digit",
  };

  const startDateFormatted = new Date(startDate).toLocaleString("en-US", options);
  let endDateFormatted = new Date(endDate).toLocaleString("en-US", options);

  // Check if the dates have the same day
  if (
    new Date(startDate).getDate() === new Date(endDate).getDate() &&
    new Date(startDate).getMonth() === new Date(endDate).getMonth() &&
    new Date(startDate).getFullYear() === new Date(endDate).getFullYear()
  ) {
    // If they have the same day, only display the date once
    endDateFormatted = new Date(endDate).toLocaleString("en-US", options2);
    return `${startDateFormatted} - ${endDateFormatted}`;
  } else {
    return `${startDateFormatted} - ${endDateFormatted}`;
  }
};

export const formatNextOncallDate = (data) => {
  if (data && Array.isArray(data) && data.length > 0) {
    const { starDateTime, endDateTime } = data.shift();
    return formatDates(starDateTime, endDateTime);
  }
  return "";
};

export const removeCommaIfFirstNameEmpty = (lastName, firstName) => {
  if (!firstName) {
    return lastName; // If firstname is empty, return only the lastname
  } else {
    return `${lastName}, ${firstName}`; // Otherwise, return firstname and lastname with a comma
  }
};

export const copyToClipboard = (copyType, text) => {
  const textToCopy = NUMBER_CHARS_ONLY_FOR.includes(copyType)
    ? removeNonNumericCharacters(text)
    : text;
  copyTextToClipboard(textToCopy);
};

export const getDefaultSrc = (ev) => (ev.target.src = photo);

export const updateCopiedStatus = (copyType, status, copyOptions) => {
  const newCopyOptions = JSON.parse(JSON.stringify(copyOptions));
  for (const key in newCopyOptions) {
    newCopyOptions[key].copiedStatus = key === copyType ? status : false;
  }
  return newCopyOptions;
};

export const handlePhoneClick = async (number) => {
  try {
      await ZoomAppClient.MakePhoneCall(number);
  }
  catch(error) {
    console.log(error)
  }
};

export const handleEmailClick = async (redirectTo) => {
  try {
      const url = `${REDIRECT_LINK}?${PARAM_KEY_REDIRECT_LINK}=${redirectTo}`;
      await ZoomAppClient.openExternalUrl(url);
  }
  catch(error) {
    console.log(error)
  }
};