import React, { useRef, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Image from "../Image/Image";
import CameraIcon from "../../assets/images/video-icon.svg";
import { NotificationServiceDownModal, FailedMultiInvitesModal, NotificationTrackingModal } from "../MultiParticipantInvite/MultiInviteModals";
import ZoomAppClient from '../../helper/zoomAppClient';
import {
    checkForAllNotificationsSentStatus as checkNotificationsSentStatus,
    filterTrackingDetailsByStatus,
    getParsedTrackingDetailsForC2CInvite,
    prepareMultiInviteStatusCheckRequest,
    updateTrackingDetailsStatus
} from '../../helper/inviteHelper';
import { sendC2CInvitation, checkC2CInviteStatus } from '../../service/guest.service';
import errorActions from "../../store/actions/errorActions";
import { ACTION_TYPES, STORE_NAMES } from '../../constants/store.constants';
import { BEFORE_REDIRECT_DELAY, REDIRECT_ROUTE_PATH } from '../../constants/redirect.constants';
import { PARAM_KEY_C2C_REDIRECT, PARAM_KEY_REDIRECT_LINK } from '../../constants/app.constants';
import { ALLOWED_POLLING_STATUSES, DISPLAY_FAILED_NOTIFICATIONS_STATUSES, MAX_TIME_LIMIT_CHECKER_INTERVAL, POLLING_START_DELAY } from '../../constants/guest.constants';

const ClinicianToClinicianInvite = ({ invitee, notification }) => {
    const dispatch = useDispatch();
    const {
        provider: inviter,
        appConfig: {
            clientSettings: {
                dnp: {
                    statusCheckLimit = null,
                    pollingRetryInterval = null,
                    statusCheckTimeout = null
                } = {}
            } = {}
        } 
    } = useSelector((state) => state?.[STORE_NAMES.APP]);

    const trackInviteTimerRef = useRef([]);
    const statusRequestControllerRef = useRef([]);
    const maxTimeLimitCheckTimerRef = useRef(null);
    const meetingLaunchDelayTimerRef = useRef(null);

    const [failedNotifications, setFailedNotifications] = useState(null);

    // modal toggles
    const [displayNotificationTrackingModal, setDisplayNotificationTrackingModal] = useState(false);
    const [displayNotificationServiceDownModal, setDisplayNotificationServiceDownModal] = useState(false);
    const [displayFailedNotificationsModal, setDisplayFailedNotificationsModal] = useState(false);

    const displayInviteSuccessModal = () => {
        dispatch({ type: ACTION_TYPES.INVITE_BUTTON_CLICKED, payload: { inviteButtonClicked: true, status: true } });
    };

    const hideAllModals = () => {
        setDisplayNotificationTrackingModal(false);
        setDisplayNotificationServiceDownModal(false);
        setDisplayFailedNotificationsModal(false);
    };

    const startMaxTimeLimitCheckTimer = (startTimeStamp, trackingDetails) => {
        clearMaxTimeLimitCheckerTimer();
        maxTimeLimitCheckTimerRef.current = setInterval(() => {
            const currentTimeStamp = dayjs();
            const maxTimeLimitForPolling = pollingRetryInterval*statusCheckLimit;
            if(currentTimeStamp.diff(startTimeStamp, "ms") >= maxTimeLimitForPolling) {
                displayC2CInviteModal("maxTimeLimitReached", trackingDetails);
            }
        }, MAX_TIME_LIMIT_CHECKER_INTERVAL);
    };

    const clearMaxTimeLimitCheckerTimer = () => {
        if(maxTimeLimitCheckTimerRef.current) {
            clearInterval(maxTimeLimitCheckTimerRef.current);
        }
        maxTimeLimitCheckTimerRef.current = null;
    };

    const cancelAllStatusRequests = () => {
        statusRequestControllerRef.current.forEach(abortRequestController => abortRequestController.abort());
        statusRequestControllerRef.current = [];
    };

    const clearStatusCheckTimers = () => {
        trackInviteTimerRef.current.forEach(timerId => clearTimeout(timerId));
        trackInviteTimerRef.current = [];
    };

    const resetMeetingLaunchDelayTimerRef = () => {
        if(meetingLaunchDelayTimerRef.current) {
            clearTimeout(meetingLaunchDelayTimerRef.current);
            meetingLaunchDelayTimerRef.current = null;
        }
    };

    const startMeeting = (zoomMeetingStartUrl) => {
        const baseUrl = location.origin;
        const redirectRoutePath = `${baseUrl}/${REDIRECT_ROUTE_PATH}?${PARAM_KEY_REDIRECT_LINK}=${zoomMeetingStartUrl}&${PARAM_KEY_C2C_REDIRECT}=true`;
        meetingLaunchDelayTimerRef.current = setTimeout(() => ZoomAppClient.openExternalUrl(redirectRoutePath), BEFORE_REDIRECT_DELAY);
    };

    const displayC2CInviteModal = (type, trackingDetails = null) => {
        setFailedNotifications(null);
        hideAllModals();

        cancelAllStatusRequests();
        clearStatusCheckTimers();
        clearMaxTimeLimitCheckerTimer();
        resetMeetingLaunchDelayTimerRef();

        switch (type) {
            case "allInvitesSent":
                    // display successfull invitation modal
                    displayInviteSuccessModal();
                break;
            case "maxTimeLimitReached": // same as failedNotificationsError case so fallthrough
            case "noMoreNotificationsToProcess": // same as failedNotificationsError case so fallthrough
            case "failedNotificationsError":
                    const failedTrackingDetails = filterTrackingDetailsByStatus(trackingDetails, DISPLAY_FAILED_NOTIFICATIONS_STATUSES);
                    if(failedTrackingDetails.length > 0) {
                        setFailedNotifications(failedTrackingDetails);
                        setDisplayFailedNotificationsModal(true);
                        return;
                    }
                    displayInviteSuccessModal();
                break;
            case "serviceDownError":
                    setDisplayNotificationServiceDownModal(true);
                break;
            default:
                break;
        }
    };

    const handleNoticaitionStatusSuccess = ({successResponse, trackingDetails, zoomMeetingDetails }) => {

        // update the main notification response delivery status
        updateTrackingDetailsStatus(trackingDetails, successResponse);

        const allInvitesSentStatus = checkNotificationsSentStatus(trackingDetails);
        if(allInvitesSentStatus===true) {
            displayC2CInviteModal("allInvitesSent");
            startMeeting(zoomMeetingDetails.zoomMeetingStartUrl);
            return;
        }

        const notificationsToProcess = filterTrackingDetailsByStatus(trackingDetails, ALLOWED_POLLING_STATUSES);
        if(notificationsToProcess.length === 0) {
            displayC2CInviteModal("noMoreNotificationsToProcess", trackingDetails);
            return;
        }
    };

    const handleNotificationStatusError = ({ exception, isLastStatusCheck }) => {
        if(isLastStatusCheck) {
            const serverErrors = exception?.response?.data?.errors;
            if (serverErrors && Array.isArray(serverErrors) && serverErrors.length > 0) {
                displayC2CInviteModal("serviceDownError");
            }
        }
    };

    const parseNotificationStatusResponse = (params) => {
        const { 
            successResponse = null,  
            exception = null,
            isLastStatusCheck,
            dependencies
        } = params;


        if(successResponse && Array.isArray(successResponse) && successResponse.length > 0) {
            handleNoticaitionStatusSuccess({ successResponse, ...dependencies });
        }

        if(exception) {
            handleNotificationStatusError({ exception, isLastStatusCheck });
        }
    };

    const checkNotificationStatus = async ({ c2cInviteStatusCheckRequest, isLastStatusCheck, dependencies }) => {
        const responseBody = {};
        try {
            const abortRequestController = new AbortController();
            statusRequestControllerRef.current.push(abortRequestController);
            responseBody.successResponse = await checkC2CInviteStatus({ payload: c2cInviteStatusCheckRequest, customTimeout: statusCheckTimeout, abortRequestController });
        }
        catch(exception) {
            responseBody.exception = exception;
        }
        finally {
            parseNotificationStatusResponse({ ...responseBody, isLastStatusCheck, dependencies });
        }
    };

    const startC2CInviteStatusCheckPolling = ({ iterationCount, trackingDetails, zoomMeetingDetails }) => {
        iterationCount++;
        const isLastStatusCheck = iterationCount===+statusCheckLimit;

        const c2cInviteStatusCheckRequest = prepareMultiInviteStatusCheckRequest(trackingDetails, isLastStatusCheck);
        if(c2cInviteStatusCheckRequest.trackingIds.length === 0) {
            displayC2CInviteModal("noMoreNotificationsToProcess", trackingDetails);
            return;
        }

        // Trigger polling call here
        checkNotificationStatus({ c2cInviteStatusCheckRequest, isLastStatusCheck, dependencies: { trackingDetails, zoomMeetingDetails } });
        
        // stop recursion for safety
        if(isLastStatusCheck) {
            return;
        }

        // create next polling interval
        const pollingTimerId = setTimeout(startC2CInviteStatusCheckPolling, pollingRetryInterval, { iterationCount, trackingDetails, zoomMeetingDetails });
        trackInviteTimerRef.current.push(pollingTimerId);
    };

    const prepareDepndencieAndStartPolling = (parsedInviteResponse) => {
        const { validTrackingDetails: trackingDetails, ...zoomMeetingDetails } = parsedInviteResponse;
        setDisplayNotificationTrackingModal(true);
        setTimeout(() => {
            const startTimeStamp = dayjs();
            startMaxTimeLimitCheckTimer(startTimeStamp, trackingDetails);
            startC2CInviteStatusCheckPolling({iterationCount: 0, trackingDetails, zoomMeetingDetails}); 
        }, POLLING_START_DELAY);
    };

    const sendC2CInvite = async () => {
        try {
            const inviteClinicianRequstBody = {
                inviter,
                invitee,
                notification
            }
            const  inviteGuestResponse = await sendC2CInvitation(inviteClinicianRequstBody);

            const parsedInviteResponse = getParsedTrackingDetailsForC2CInvite(inviteGuestResponse, notification);
            if(parsedInviteResponse===null || pollingRetryInterval===null || statusCheckLimit===null || statusCheckTimeout===null) {
                // display failed modal if invalid response or polling dependencies missing
                dispatch(errorActions.setErrorHandler({ code: 14000 }));
                return;
            }
            prepareDepndencieAndStartPolling(parsedInviteResponse);
        }
        catch(exception) {
            dispatch(errorActions.setErrorHandler({ code: 14000 }));
        }
    };

    const handleCameraIconClick = () => {
        if(!displayNotificationTrackingModal) {
            sendC2CInvite();
        }
    };

    const handleFailedInvitesModalClose = () => {
        // clear failed notifications and modal close logic
        setFailedNotifications(null);
        setDisplayFailedNotificationsModal(false);
    };

    const handleNotificationServiceDownModalClose = () => {
        // clear failed notifications and modal close logic
        setFailedNotifications(null);
        setDisplayNotificationServiceDownModal(false);
    };

    const cleanUpInviteDependencies = () => {
        cancelAllStatusRequests();
        clearStatusCheckTimers();
        clearMaxTimeLimitCheckerTimer();
        resetMeetingLaunchDelayTimerRef();
        setFailedNotifications(null);
        hideAllModals();
    };

    useEffect(() => {
        return () => cleanUpInviteDependencies();
    }, []);

    return  <>
            <Image
                className="vve-video-icon"
                id="call-video-icon"
                src={CameraIcon}
                alt="video-icon.svg"
                onClick={handleCameraIconClick}
            />
            { displayFailedNotificationsModal && 
                <FailedMultiInvitesModal failedNotifications={failedNotifications} onCloseHandler={handleFailedInvitesModalClose} /> 
            }
            { displayNotificationServiceDownModal &&
                <NotificationServiceDownModal onCloseHandler={handleNotificationServiceDownModalClose} />
            }
            { displayNotificationTrackingModal &&
                <NotificationTrackingModal onCloseHandler={setDisplayNotificationTrackingModal} />
            }
    </>

};

/* PropTypes */
ClinicianToClinicianInvite.propTypes = {
    invitee: PropTypes.any,
    notification: PropTypes.any
};

export default ClinicianToClinicianInvite;