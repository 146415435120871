
import patientAgreeConsent from '../assets/images/patient-agree-consent.svg';
import patientDisagreeConsent from '../assets/images/patient-disagree-consent.svg'
import patientNoneConsent from '../assets/images/patient-none-consent.svg'

const AGREE = "AGREE";
const DISAGREE = "DISAGREE"
const NONE =  "NONE";
const PATIENT_CONSENT = "Patient consent";

const ALLOWED_ABRIDGE_STATUSES = [AGREE, DISAGREE, NONE];

const ABRIDGE_STATUS_OPTIONS = {
    [AGREE]: {
        header: "The patient has consented to recording",
        subHeader: "their voice for transcription.",
        info: "Please launch the Abridge application now.",
        body: "Abridge is a third-party service that will record the visit's audio and convert it into a transcription. The transcription can be integrated into notes in KP HealthConnect.",
        icon: patientAgreeConsent
    },
 
    [DISAGREE]: {
        header: "The patient has NOT consented to recording",
        subHeader: "their voice for transcription.",
        info: "DO NOT launch the Abridge application at this time.",
        body: "Abridge is a third-party service that would have recorded the visit's audio and converted it into a transcription. Since consent was not given, the transcription cannot be used in KP HealthConnect.",
        icon: patientDisagreeConsent
    },
 
    [NONE]: {
        header: "The patient has NOT joined the video visit",
        subHeader: "and has not provided consent to use Abridge",
        info: 'Please select the "Refresh" button once the patient has joined.',
        body: "Abridge is a third-party service that records the visit's audio and converts it into a transcription. The transcription can be used for notes within KP HealthConnect.",
        icon: patientNoneConsent
    }
}

export {
    AGREE,
    DISAGREE,
    NONE,
    ALLOWED_ABRIDGE_STATUSES,
    PATIENT_CONSENT,
    ABRIDGE_STATUS_OPTIONS
}