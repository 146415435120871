import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PARAM_KEY_C2C_REDIRECT, PARAM_KEY_REDIRECT_LINK } from '../../constants/app.constants';
import {
    REDIRECTION_HEADER_ZOOM,
    REDIRECTION_HEADER_EMAIL,
    REDIRECTION_BODY,
    REDIRECTION_ZOOMTEXT_ONE,
    REDIRECTION_ZOOMTEXT_TWO,
    REDIRECTION_ZOOMTEXT_THREE,
    REDIRECTION_ZOOMTEXT_FOUR,
    REDIRECTION_EMAILTEXT_ONE,
    REDIRECTION_EMAILTEXT_TWO,
    C2C_ZOOMTEXT_DETAILS
} from '../../constants/redirect.constants';

const RedirectTo = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [zoomTextTemplate, setZoomTextTemplate] = useState(null);

    const prepareZoomTextTemplate = ({ isEmailtoRedirect, isClinicianToClinicianInvite }) => {
        let zoomTemplate = "";
        if(isEmailtoRedirect) {
            zoomTemplate = <>
                <div className="vve-redirection-header">{REDIRECTION_HEADER_EMAIL}</div>
                <div className="vve-redirection-body">{REDIRECTION_EMAILTEXT_ONE}</div>
                <div className="vve-redirection-body">  {REDIRECTION_EMAILTEXT_TWO}</div>
            </>;
        }
        else if(isClinicianToClinicianInvite) {
            zoomTemplate = <>
                <div className="vve-redirection-header">{C2C_ZOOMTEXT_DETAILS.HEADER}</div>
                <div className="vve-redirection-body">{C2C_ZOOMTEXT_DETAILS.BODY_LINE_ONE}</div>
                <div className="vve-redirection-body">
                    {C2C_ZOOMTEXT_DETAILS.BODY_LINE_TWO}<br />
                    {C2C_ZOOMTEXT_DETAILS.BODY_LINE_THREE}
                </div>
                <div className="vve-redirection-body">
                    {C2C_ZOOMTEXT_DETAILS.BODY_LINE_FOUR}<br />
                    {C2C_ZOOMTEXT_DETAILS.BODY_LINE_FIVE}
                </div>
            </>;
        }
        else {
            zoomTemplate = <>
                <div className="vve-redirection-header">{REDIRECTION_HEADER_ZOOM}</div>
                <div className="vve-redirection-body">{REDIRECTION_BODY}</div>
                <div className="vve-redirection-body">
                    {REDIRECTION_ZOOMTEXT_ONE}<br />
                    {REDIRECTION_ZOOMTEXT_TWO}
                </div>
                <div className="vve-redirection-body">
                    {REDIRECTION_ZOOMTEXT_THREE}<br />
                    {REDIRECTION_ZOOMTEXT_FOUR}
                </div>
            </>;
        }
        setZoomTextTemplate(zoomTemplate);
    };

    useEffect(() => {
        const redirectUrl = searchParams.get(PARAM_KEY_REDIRECT_LINK);
        const isClinicianToClinicianInvite = searchParams.has(PARAM_KEY_C2C_REDIRECT);
        const isEmailtoRedirect = redirectUrl && redirectUrl.includes("mailto");
        prepareZoomTextTemplate({isEmailtoRedirect, isClinicianToClinicianInvite});

        if (redirectUrl && redirectUrl !== "") {
            location.href = redirectUrl;
        }
    }, []);

    return (
        <div className="vve-join-container">
            <div className="vve-header-Logo" role="img" tabIndex="0" />
            <div className="vve-kpgroup-logo vve-redirection-logo"></div>
            {zoomTextTemplate}
        </div>
    );
};

export default RedirectTo;