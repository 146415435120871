import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ACTION_TYPES, STORE_NAMES } from "../../constants/store.constants";
import DialogBox from '../DialogBox/DialogBox';
import actions from '../../store/actions/errorActions';

const ErrorHandler = () => {
    const dispatch = useDispatch();
    const { errorModal, modalProps } = useSelector(state => state?.[STORE_NAMES.ERROR_HANDLER]);
    let modalDescriptionArray = [];
    if (modalProps?.descriptionleft && modalProps?.descriptionright) {
        modalDescriptionArray.push({descriptionLeft: modalProps?.descriptionleft, descriptionRight: modalProps?.descriptionright});
    }
    if (modalProps?.descriptionleft2 && modalProps?.descriptionright2)  {
        modalDescriptionArray.push({descriptionLeft: modalProps?.descriptionleft2, descriptionRight: modalProps?.descriptionright2});
    }
    const onClose = () => {
        dispatch(actions.setErrorHandlerOff());
    }

    useEffect(() => {
        if(errorModal && !modalProps) {
            onClose();
            dispatch({ type: ACTION_TYPES.APP_INITIALIZATION_FAILURE });
        }
    }, [errorModal, modalProps]);

    return (
        <DialogBox
            showModal={errorModal}
            modalTitle={modalProps?.title || ""}
            modalBody={modalProps?.body || ""}
            modalDescription={modalDescriptionArray || ""}
            onClose={onClose}
        >
        </DialogBox>
    );
};

export default ErrorHandler;