import React from 'react';
import Button from '../Button/Button';
import { useDispatch, useSelector } from "react-redux";
import GuestAction from '../../store/actions/GuestAction';
import SupportAction from '../../store/actions/SupportAction'; 
import InterpreterAction from '../../store/actions/InterpreterAction';
import ServiceAreaAction from '../../store/actions/ServiceAreaAction';
import BUTTON_CONFIG from '../../constants/button.constants';
import { STORE_NAMES } from "../../constants/store.constants";
import { GUEST_VIDEO_INVITATION, GUEST_AUDIO_INVITATION, MULTI_INVITE_TYPE } from "../../constants/guest.constants";
import { INTERPRETER_VIDEO, INTERPRETER_AUDIO, INVITE_INTERPRETER_AUDIO_ROLE, INVITE_INTERPRETER_DIALOUT_ROLE } from "../../constants/interpreter.constants";
import { INVITE_BUTTON_ID } from '../../constants/dom.constants';
import { ADD_INTERPRETER_KEY, ADD_GUEST_KEY, ADD_SUPPORT_KEY } from '../../constants/tab.constants';
import { VISIT_TYPES } from '../../constants/app.constants';

const InviteButton = () => {
    const dispatch = useDispatch();
    const { enableInviteButton } = useSelector(state => state?.[STORE_NAMES.INVITE_BUTTON]);
    const { unformattedPhoneNumber } = useSelector(state => state?.[STORE_NAMES.DIALPAD]);
    const { contact_number } = useSelector(state => state?.[STORE_NAMES.INTERPRETER_AUDIO_INFO]);
    const { appConfig } = useSelector(state => state?.[STORE_NAMES.APP]);
    const zoomClientDetails = useSelector(state => state?.[STORE_NAMES.ZOOM_CLIENT]);
    const interpreterStoreData = useSelector((state) => state?.[STORE_NAMES.INTERPRETER]);
    const {
        appointmentRegion: region = null, 
        appointmentVisitType: visitType = null,
        provider: { nuid = "" } = {},
        appointmentId = null
    } = useSelector((state) => state?.[STORE_NAMES.APPOINTMENT_DETAILS]);

    const guestStoreData = useSelector((state) => state?.[STORE_NAMES.GUEST]);
    const { currentTabContext } = useSelector((state) => state?.[STORE_NAMES.TABS]);
    const { currentSupportTab } = useSelector(state => state?.[STORE_NAMES.SUPPORT_TAB_INFO]);
    const supportDetailsStoreData = useSelector((state) => state?.[STORE_NAMES.SUPPORT_TAB_INFO]);
    const prepareInvitationPayload = (context) => {
        let invitationPayload = {};
        if(context === INTERPRETER_VIDEO) {
            const { language: { languageName = "", sipUri = "" } } = interpreterStoreData;
            const zoomPayloadForVideo = prepareZoomDetailsPayload(zoomClientDetails, INTERPRETER_VIDEO);
            invitationPayload = {
                ...zoomPayloadForVideo,
                region: region,
                visitType: visitType,
                nuid: nuid,
                interpreter: {
                    languageName: languageName,
                    sipUri: sipUri
                }
            };
        }

        // payload prepping is same for both interpreter and guest audio
        if(context === INTERPRETER_AUDIO || context === GUEST_AUDIO_INVITATION) {
            const dialOutNumber = interpreterStoreData?.serviceArea?.number ?? unformattedPhoneNumber;
            const dialoutName = interpreterStoreData?.serviceArea?.name ?? "";
            const dialoutRole = context === INTERPRETER_AUDIO ? INVITE_INTERPRETER_AUDIO_ROLE : INVITE_INTERPRETER_DIALOUT_ROLE;
            const zoomPayloadForAudio = prepareZoomDetailsPayload(zoomClientDetails, INTERPRETER_AUDIO);
            invitationPayload = {
                ...zoomPayloadForAudio,
                region: region,
                visitType: visitType,
                dialout: {
                    number: `${dialOutNumber}`,
                    name: dialoutName,
                    role: dialoutRole
                }
            };
        }

        if(context===GUEST_VIDEO_INVITATION) {
            const zoomPayloadForMultiGuestInvite = prepareZoomDetailsPayload(zoomClientDetails, GUEST_VIDEO_INVITATION);
            const { multiGuestInvitees } = guestStoreData;
            invitationPayload = {
                ...zoomPayloadForMultiGuestInvite,
                region: region,
                visitType: visitType,
                invitee: multiGuestInvitees
            }
        }
        return invitationPayload;
    };

    const prepareZoomDetailsPayload = (zoomClientDetailsPayload, invitationContext) => {
        const { meetingID, meetingJoinUrl, userContext } = zoomClientDetailsPayload;
        const { participantId, screenName } = userContext;
        const payload = {
            zoomMeetingId: meetingID,
            zoomMeetingParticipantId: participantId,
            zoomMeetingUrl: meetingJoinUrl,
            zoomMeetingParticipantName: screenName
        };
        if(invitationContext==INTERPRETER_VIDEO) {
            const { zoomMeetingUrl, ...interpreterVideoZoomDetails } = payload;
            return interpreterVideoZoomDetails;
        }
        else if(invitationContext==INTERPRETER_AUDIO) {
            const { zoomMeetingUrl, ...interpreterAudioZoomDetails } = payload;
            return interpreterAudioZoomDetails;
        }
        else if(invitationContext==="support") {
            const { zoomMeetingUrl, ...supportZoomDetails } = payload;
            return supportZoomDetails;
        }
        else {
            const { zoomMeetingParticipantName, ...guestVideoZoomDetails } = payload;
            return guestVideoZoomDetails;
        }
    };

    const prepareSnowPayload = () => {
        const { 
            selectedSnowContactPhoneNumber = null, 
            selectedSnowCategory = null,
            selectedSnowDescription = null 
        } = supportDetailsStoreData;

        const { browserOSInfo = null } = zoomClientDetails
        const zoomPayloadForSnow = prepareZoomDetailsPayload(zoomClientDetails, "support");
        let snowPayload = {
            ...zoomPayloadForSnow,
            region: region,
            visitType: visitType,
            appointmentId: appointmentId,
            snowTicket: {
                userNuid: nuid,
                category: selectedSnowCategory,
                description: selectedSnowDescription,
                providerContactNumber: selectedSnowContactPhoneNumber,
                ...browserOSInfo
            }
        };
        return snowPayload;
    }

    const onInviteButtonClick = () => {
        if(currentTabContext==ADD_INTERPRETER_KEY) {
            const { type: interpreterType = "" } = interpreterStoreData;
            if(interpreterType===INTERPRETER_VIDEO) {
                const payload = prepareInvitationPayload(INTERPRETER_VIDEO);
                dispatch(InterpreterAction.postInterpreterAction(payload));
            }
            else if(interpreterType===INTERPRETER_AUDIO) {
                const forServiceArea = !!interpreterStoreData?.serviceArea?.number;
                const payload = prepareInvitationPayload(INTERPRETER_AUDIO);
                const invitationDetails = { forServiceArea, appConfig, contact_number };
                dispatch(ServiceAreaAction.inviteAudioInterpreter(payload, invitationDetails));
            }
        }
        else if(currentTabContext==ADD_GUEST_KEY) {
            const { type: guestInviteType } = guestStoreData;
            if(guestInviteType===MULTI_INVITE_TYPE) {
                const payload = prepareInvitationPayload(GUEST_VIDEO_INVITATION);
                dispatch(GuestAction.postGuestAction(payload));
            }
            else {
                const payload = prepareInvitationPayload(GUEST_AUDIO_INVITATION);
                const invitationDetails = { forServiceArea: false, appConfig, contact_number: unformattedPhoneNumber };
                dispatch(ServiceAreaAction.inviteAudioInterpreter(payload, invitationDetails));
            }
        } else if (currentTabContext==ADD_SUPPORT_KEY) {
            const payload = prepareSnowPayload();
            dispatch(SupportAction.postSupportAction(payload));
        }
    }

    return (
        <div className='vve-invite-btn-container'>
            <Button disabled={!enableInviteButton} onClick={onInviteButtonClick} class='btn btn-primary btn-lg' id={INVITE_BUTTON_ID}>
                {(currentSupportTab && currentTabContext === ADD_SUPPORT_KEY) ? 'Submit' : BUTTON_CONFIG.INVITE_BUTTON.title}
            </Button>
        </div>
    );
};

export default InviteButton;