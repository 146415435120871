import { ACTION_TYPES } from "../../constants/store.constants";

const initialState = {
	providerListData: [],
	selectedProviderData: null
};

const ProviderListReducer = (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ACTION_TYPES.UPDATE_PROVIDERLIST:
			return {
				...state,
				providerListData: payload
			};

		case ACTION_TYPES.UPDATE_SELECTED_PROVIDERLIST:
			return {
				...state,
				selectedProviderData: payload
			};

		default:
			return state;
	}
};

export default ProviderListReducer;
