/* Module Dependencies */
import React from "react";
import PropTypes from "prop-types";

/* Custom Components */
import DropDown from "../DropDown/DropDown";

/* Constants */
import { MEDICAL_CENTER_LABEL, SELECT_LIST_PLACEHOLDER, DEPARTMENT_LABEL, CLINICIAN_REGION_LABEL, CLINICIAN_PROVIDERTYPE_LABEL } from "../../constants/filter.constants";

/* Declare the component */
const ClinicianFilterBody = ({ ...props }) => {
  // Re-structure props
  const {
    clinicianRegionDisplayValue,
    clinicianRegion,
    clinicianRegionData,
    handleClinicianRegion,


    clinicianTypeDisplayValue,
    clinicianType,
    clinicianTypeData,
    handleClinicianType,

    medicalCenterDisplayValue,
    medicalCenter,
    medicalCenterData,
    handleMedicalCenter,
    departmentDisplayValue,
    department,
    departmentData,
    handleDepartment,
  } = props;

  // Return component
  return (
    <>
      <DropDown
        dataTestid={"clinician-region"}
        id="clinician-region"
        label={CLINICIAN_REGION_LABEL}
        value={clinicianRegion}
        getDisplayValue={clinicianRegionDisplayValue}
        onValueChange={handleClinicianRegion}
        listData={clinicianRegionData}
      />

      <DropDown
        dataTestid={"clinician-type"}
        id="clinician-type"
        label={CLINICIAN_PROVIDERTYPE_LABEL}
        value={clinicianType}
        getDisplayValue={clinicianTypeDisplayValue}
        onValueChange={handleClinicianType}
        listData={clinicianTypeData}
      />

      <DropDown
        dataTestid={"medical-center"}
        id="medical-center"
        label={MEDICAL_CENTER_LABEL}
        placeholder={SELECT_LIST_PLACEHOLDER}
        getDisplayValue={medicalCenterDisplayValue}
        value={medicalCenter}
        listData={medicalCenterData}
        onValueChange={handleMedicalCenter}
      />
      <DropDown
        id="department"
        dataTestid={"department"}
        label={DEPARTMENT_LABEL}
        placeholder={SELECT_LIST_PLACEHOLDER}
        getDisplayValue={departmentDisplayValue}
        value={department}
        listData={departmentData}
        onValueChange={handleDepartment}
        disabled={!medicalCenter}
        capitalizeOption={false}
        classes={{
          dropdownMenu: "vve-department-dropdown",
        }}
      />
    </>
  );
};

/* PropTypes */
ClinicianFilterBody.propTypes = {
  clinicianRegionDisplayValue: PropTypes.func.isRequired,
  clinicianRegion: PropTypes.string.isRequired,
  clinicianRegionData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleClinicianRegion: PropTypes.func.isRequired,



  clinicianTypeDisplayValue:  PropTypes.func.isRequired,
  clinicianType: PropTypes.string.isRequired,
  clinicianTypeData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleClinicianType: PropTypes.func.isRequired,


  medicalCenterDisplayValue: PropTypes.func.isRequired,
  medicalCenter: PropTypes.string.isRequired,
  medicalCenterData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleMedicalCenter: PropTypes.func.isRequired,
  departmentDisplayValue: PropTypes.func.isRequired,
  department: PropTypes.string.isRequired,
  departmentData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleDepartment: PropTypes.func.isRequired,
};

/* Export component */
export default ClinicianFilterBody;
