
const SUPPORT_OPTIONS_LIST = [
    "support"
]

const SNOW_INVITE_TYPE = "snow_invitation";
const MACOSNAME = "Mac";
const WINDOWSOSNAME = "Windows";
const LINUXOSNAME = "Linux";
const DISPATCHSTATUS = "Dispatched";

export {
    SUPPORT_OPTIONS_LIST,
    SNOW_INVITE_TYPE,
    MACOSNAME,
    WINDOWSOSNAME,
    LINUXOSNAME,
    DISPATCHSTATUS
};
