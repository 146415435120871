import { ACTION_TYPES } from "../../constants/store.constants";

const initialState = {
	medicalCentersListData: []
};

const MedicalCentersListReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.CREATE_MEDICALCENTERSLIST:
			return {
				...state,
				medicalCentersListData: payload
			};
		default:
			return state;
	}
};

export default MedicalCentersListReducer;
