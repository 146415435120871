import { ACTION_TYPES } from '../../constants/store.constants';

const setCurrentTabContext = (data) => ({
	type: ACTION_TYPES.SET_CURRENT_TAB_CONTEXT,
	payload: data
});

const UpdateMobileMatrix = (payload) => ({
	type: ACTION_TYPES.UPDATE_MOBILEVIEW_MATRIX,
	payload: payload
});


export default {
	setCurrentTabContext,
	UpdateMobileMatrix
};