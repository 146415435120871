import { sendAudioInvitation } from '../../service/interpreter.service';
import { ACTION_TYPES } from '../../constants/store.constants';
import DialPadAction from './DialPadAction';
import setErrorAction from "./errorActions";

const setInterpreterServiceArea = (data) => (dispatch) => {
	dispatch({ type: ACTION_TYPES.SET_INTERPRETER_SERVICE_AREA, payload: data });
	const inviteButtonStatus = data ? true : false;
	dispatch({ type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: inviteButtonStatus });
	dispatch({ type: ACTION_TYPES.DISABLE_SERVICE_AREA, payload: null });
};

/**
 * Will be triggered for Audio Interpreter(Service Area, Dialpad), Guest Audio Invitation (Dialpad)
 * @param {payload} required - backend request payload 
 * @param {appConfig, forServiceArea, contact_number} appConfig - required for defaultViewTimer config, 
 * forServiceArea - required to distinguish between service area vs dialpad, contact_number to reset dialpad default view
 */
const inviteAudioInterpreter = (payload, { appConfig, forServiceArea, contact_number }) => async (dispatch) => {
	try {
		// initiate default view
		if(forServiceArea) {
			dispatch(setServiceAreaDefaultView(appConfig));
		}
		else {
			dispatch({ type: ACTION_TYPES.INVITE_BUTTON_CLICKED, payload: {inviteButtonClicked: true, status: false, loading: true} });
			dispatch(DialPadAction.setDialPadDefaultView({contact_number: contact_number, appConfig: appConfig}));
		}

		// trigger backend service call
		const response = await sendAudioInvitation(payload);
		if(response?.status) {
			dispatch({ type: ACTION_TYPES.INVITE_BUTTON_CLICKED, payload: { inviteButtonClicked: forServiceArea, status: true } });
		}
	}
	catch(err) {
		dispatch({ type: ACTION_TYPES.INVITE_BUTTON_CLICKED, payload: {inviteButtonClicked: false, status: false, loading: false} });
		dispatch(setErrorAction.setErrorHandler(err));
	}
};

let resetServiceAreaTimer = null;
const setServiceAreaDefaultView = (appConfig) => (dispatch) => {
	if(resetServiceAreaTimer) {
		clearInterval(resetServiceAreaTimer);
	}
    dispatch({type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: false});
	dispatch({ type: ACTION_TYPES.DISABLE_SERVICE_AREA, payload:true });
	const { clientSettings: { defaultViewTimer = null } } = appConfig;
	if(defaultViewTimer) {
		resetServiceAreaTimer = setTimeout(() => {
			dispatch({ type: ACTION_TYPES.DISABLE_SERVICE_AREA, payload: false });
		}, defaultViewTimer);
	}
};

const disableServiceArea = (data) => ({
	type: ACTION_TYPES.DISABLE_SERVICE_AREA,
	payload: data
});

export default {
	setInterpreterServiceArea,
	inviteAudioInterpreter,
	setServiceAreaDefaultView,
	disableServiceArea
};