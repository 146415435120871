import React from 'react';
import DialogBox from '../DialogBox/DialogBox';
import { REPORT_DOWNLOAD_FAILED_MODAL_OPTIONS } from '../../constants/report.constant';

export const FailedReportDownloadModal = ({ onCloseHandler }) => {
    const dialogboxProps = { 
        showModal: true, 
        modalTitle: REPORT_DOWNLOAD_FAILED_MODAL_OPTIONS.title,
        modalBody: REPORT_DOWNLOAD_FAILED_MODAL_OPTIONS.body,
        onClose: onCloseHandler, 
        displayFooter: true 
    };
    return <DialogBox {...dialogboxProps}/>
};