import { ACTION_TYPES } from "../../constants/store.constants";

const initialState = {
	type: null,
	email: null,
	mobile: null,
	multiGuestInvitees: [],
	multiInviteTrackingDetails: null
};

const GuestReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.SET_GUEST_INVITE_TYPE:
			return {
				...state,
				type: payload
			};
		case ACTION_TYPES.SET_GUEST_INVITE_EMAIL:
			return {
				...state,
				email: payload
			};
		case ACTION_TYPES.SET_GUEST_INVITE_MOBILE:
			return {
				...state,
				mobile: payload
			};
		case ACTION_TYPES.SET_MULTI_GUEST_INVITEES:
			return {
				...state,
				multiGuestInvitees: payload
			};
		case ACTION_TYPES.SET_MULTI_INVITE_TRACKING_DETAILS: 
			return {
				...state,
				multiInviteTrackingDetails: payload
			};
		case ACTION_TYPES.CLEAR_MULTI_INVITE_TRACKING_DETAILS: 
			return {
				...state,
				multiInviteTrackingDetails: null
			};
		default:
			return state;
	}
};

export default GuestReducer;
