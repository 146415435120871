import { APP_DEVICE_TYPE } from "../constants/zoomApp.constants";


const applyMobileClass = (deviceType, mobileClass) => {
    const isMobile = (APP_DEVICE_TYPE[deviceType] === APP_DEVICE_TYPE.mobile);
    return isMobile ? mobileClass : '';
};

const applyDesktopClass = (deviceType, desktopClass) => {
    const isDesktop = (APP_DEVICE_TYPE[deviceType] === APP_DEVICE_TYPE.desktop);
    return isDesktop ? desktopClass : '';
};

const isDeviceMobile = (deviceType) => {
    return APP_DEVICE_TYPE[deviceType] === APP_DEVICE_TYPE.mobile;
};

const isDeviceDesktop = (deviceType) => {
    return APP_DEVICE_TYPE[deviceType] === APP_DEVICE_TYPE.desktop;
};

export {
    applyMobileClass,
    applyDesktopClass,
    isDeviceMobile,
    isDeviceDesktop
}
