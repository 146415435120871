import React from 'react';
import PropTypes from 'prop-types';

const IfCondition = ({ ...props }) => {
  const { condition, children } = props;

  return (
    <>
      {!condition && null}
      {condition && children}
    </>
  );
};

IfCondition.propTypes = {
  condition: PropTypes.any,
  children: PropTypes.any,
};

export default IfCondition;
