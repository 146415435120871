import React from 'react';
import { SUPPORT_TAB_HEADER } from '../../constants/interpreter.constants';
import { STORE_NAMES } from "../../constants/store.constants";
import { useSelector } from "react-redux";
import ServiceNow from '../ServiceNow/ServiceNow';
import SupportContainer from '../SupportContainer/SupportContainer';
import { SERVICE_NOW_KEY } from '../../constants/tab.constants';

const AddSupport = () => {
  const { currentSupportTab } = useSelector(state => state?.[STORE_NAMES.SUPPORT_TAB_INFO]);

  return (

    <div className="vve-add-support-container vve-container-topmargin">
      <header>
        <div className='vve-padding-bottom vve-support-main-header'>{SUPPORT_TAB_HEADER}</div>
      </header>
      <hr className="row vve-padding-top" />
      <div>
        {currentSupportTab !== SERVICE_NOW_KEY && <SupportContainer></SupportContainer>}
        {currentSupportTab === SERVICE_NOW_KEY && <ServiceNow></ServiceNow>}
      </div>
    </div>

  );

};

export default AddSupport;
