import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import InterpreterAction from '../../store/actions/InterpreterAction';
import { debounce } from '../../helper/commonHelper';
import { INTERPRETER_VIDEO, SEARCH_INTERPRETER_VIDEO, SEARCH_INTERPRETER_AUDIO } from "../../constants/interpreter.constants";

const SearchInterpreter = ({interpreterType}) => {
  const dispatch = useDispatch();
  const debounceSearchInputChange = useCallback(debounce((nextValue) => setSearchQuery(nextValue)), []);
  const setSearchQuery = (query) => dispatch(InterpreterAction.setInterpreterSearchQuery(query.toLowerCase()));
  const onSearchInput = (event) => debounceSearchInputChange(event.target.value);

  return (
    <div className="p-1 vve-search-container">
      <div className="input-group">
        {/* <span className="input-group-append">
        </span> */}
        <input className="form-control border vve-search-input vve-magnifying-icon" 
              type="search" 
              placeholder={interpreterType===INTERPRETER_VIDEO ? SEARCH_INTERPRETER_VIDEO : SEARCH_INTERPRETER_AUDIO}
              onChange={onSearchInput}/>
      </div>
    </div>
  );
};

export default SearchInterpreter;