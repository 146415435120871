import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchDirectory from '../SearchDirectory/SearchDirectory';
import DepartmentList from '../DepartmentList/DepartmentList';
import { getMedicalCenters } from '../../service/oncall.service';
import { CreateMedicalCentersList } from '../../store/actions/MedicalCentersAction';
import TabAction from '../../store/actions/TabAction';
import ProviderList from '../ProviderList/ProviderList';
import OncallProviderDetails from '../OncallProviderDetails/OncallProviderDetails';
import { ONCALL_KEY } from '../../constants/tab.constants';
import { STORE_NAMES } from '../../constants/store.constants';


const OncallDirectory = () => {
  const onCallMobileView = [true, false, false];
  const [mobileViewMatrix, setMobileViewMatrix] = useState(onCallMobileView);
  const dispatch = useDispatch();
  const { directoryRegion: region = null } = useSelector((state) => state?.[STORE_NAMES.DIRECTORY_CONFIG]);

  const loadmatrix = (columnIndex) => {
    const updatedMatrix = [...mobileViewMatrix];
    updatedMatrix[columnIndex - 1] = false;
    setMobileViewMatrix(updatedMatrix);
    dispatch(TabAction.UpdateMobileMatrix({ tab: ONCALL_KEY, matrix: updatedMatrix }));
  }

  const backbtnclick = (columnIndex) => {
    const updatedMatrix = [...mobileViewMatrix];
    updatedMatrix[columnIndex - 1] = true;
    updatedMatrix[columnIndex] = false;
    setMobileViewMatrix(updatedMatrix);
    dispatch(TabAction.UpdateMobileMatrix({ tab: ONCALL_KEY, matrix: updatedMatrix }));

  }

  const fwdbtnclick = (columnIndex) => {
    const updatedMatrix = [...mobileViewMatrix];
    updatedMatrix[columnIndex] = false;
    updatedMatrix[columnIndex + 1] = true;
    setMobileViewMatrix(updatedMatrix);
    dispatch(TabAction.UpdateMobileMatrix({ tab: ONCALL_KEY, matrix: updatedMatrix }));

  }

  useEffect(() => {
    const callBackFn = (data) => {
      dispatch(CreateMedicalCentersList(data));
    }
    getMedicalCenters(callBackFn, region);
    dispatch(TabAction.UpdateMobileMatrix({ tab: ONCALL_KEY, matrix: onCallMobileView }));
  }, []);

  return (
    <div className="vve-directory-container">
      <div className="vve-oncall-container">
        <div className={`vve-oncall-column vve-oncall-department ${mobileViewMatrix[0] ? '' : 'mobile-hide'}`}>
          <SearchDirectory></SearchDirectory>
          <DepartmentList fwdbtnclick={fwdbtnclick} matrixindex={0}></DepartmentList>
        </div>
        <div className={`vve-oncall-column vve-oncall-provider-list ${mobileViewMatrix[1] ? '' : 'mobile-hide'}`}>
          <ProviderList fwdbtnclick={fwdbtnclick} backbtnclick={backbtnclick} loadmatrix={loadmatrix} matrixindex={1} ></ProviderList>
        </div>
        <div className={`vve-oncall-column vve-oncall-provider-details ${mobileViewMatrix[2] ? '' : 'mobile-hide'}`}>
          <OncallProviderDetails fwdbtnclick={fwdbtnclick} backbtnclick={backbtnclick} loadmatrix={loadmatrix} matrixindex={2}></OncallProviderDetails>
        </div>
      </div>
    </div>
  );
};

export default OncallDirectory;