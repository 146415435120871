import { ACTION_TYPES } from "../../constants/store.constants";

const initialState = {
	meetingID: null,
	meetingJoinUrl: null,
	meetingUUID: null,
	userContext: null,
	deviceType: null,
	participantList: [],
	browserOSInfo: null
};

const ZoomClientReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.SET_ZOOM_CLIENT_DETAILS:
			const { meetingID = null, meetingJoinUrl = null, meetingUUID = null, userContext = null, participantList = [], deviceType = null, browserOSInfo = null} = payload;
			return {
				...state,
				meetingID: meetingID,
				meetingJoinUrl: meetingJoinUrl,
				meetingUUID: meetingUUID,
				userContext: userContext,
				deviceType: deviceType,
				participantList: participantList,
				browserOSInfo: browserOSInfo
			};
		default:
			return state;
	}
};

export default ZoomClientReducer;
