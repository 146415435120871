import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from 'react-router-dom';
import TabAction from '../../store/actions/TabAction';
import { intialiseAppSetup } from '../../store/actions/AppAction';
import { ONCALL_KEY, ONCALL_TITLE, CLINICIAN_KEY, CLINICIAN_TITLE, ONCALL_CLINICIAN_TABS_REGION } from '../../constants/tab.constants';
import { PARAM_KEY_UID } from '../../constants/app.constants';
import { STORE_NAMES } from "../../constants/store.constants";
import OncallDirectory from "../OncallDirectory/OncallDirectory";
import ClinicianDirectory from "../ClinicianDirectory/ClinicianDirectory";
import RefreshToken from '../RefreshToken/RefreshToken';
import { UpdateSelectedDepartment } from '../../store/actions/DepartmentListAction';
import { UpdateSelectedProvider } from "../../store/actions/ProviderListAction";
import { UpdateSelectedClinician } from "../../store/actions/ClinicianListAction";
import NewDirectoryMenu from '../NewDirectoryMenu/NewDirectoryMenu';
import InviteModal from '../InviteModal/InviteModal';
import ErrorHandler from '../ErrorHandler/ErrorHandler';

const DirectoryTabs = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTabKey, setCurrentTabKey] = useState(null);
  const { tabSettings, selectedTab } = useSelector(state => state?.[STORE_NAMES.TABS]);
  const { directoryRegion: region = null, hideOncall, menuDetails: { displayOldMenu, menuItems }  } = useSelector((state) => state?.[STORE_NAMES.DIRECTORY_CONFIG]);

  const onTabClick = (tabKey) => {
    setCurrentTabKey(tabKey);
    dispatch(TabAction.setCurrentTabContext(tabKey));
    dispatch(UpdateSelectedDepartment(''));
    dispatch(UpdateSelectedProvider(null));
    dispatch(UpdateSelectedClinician(null));
  };

  useEffect(() => {
    const uidParam = searchParams.get(PARAM_KEY_UID);
    if (uidParam !== null && uidParam.length > 0) {
      dispatch(intialiseAppSetup(uidParam, true));
    }
    else {
      console.log("uid not available");
      navigate("/error");
    }
  }, []);

  useEffect(() => {
    if(hideOncall!==null) {
      const defaultTabKey = hideOncall ? CLINICIAN_KEY : ONCALL_KEY;
      setCurrentTabKey(defaultTabKey);
      dispatch(TabAction.setCurrentTabContext(defaultTabKey));
    }
  }, [tabSettings, hideOncall]);
  
  const renderContent = () => {
    if (region && currentTabKey !== null) {
      return <>
        {ONCALL_CLINICIAN_TABS_REGION.includes(region) && !hideOncall ? (
          <div className='vve-directory'>
            <div className={`vve-directory-tab-content-container ${selectedTab?.tab == currentTabKey && selectedTab?.matrix[0] ? '' : 'mobile-hide'}`}>
              <Tabs defaultActiveKey={currentTabKey} onSelect={onTabClick} className="vve-add-tabs" fill="true">
                <Tab eventKey={ONCALL_KEY} title={ONCALL_TITLE} tabClassName="vve-add-tabitem" className="vve-tab-panel"></Tab>
                <Tab eventKey={CLINICIAN_KEY} title={CLINICIAN_TITLE} tabClassName="vve-add-tabitem" className="vve-tab-panel"></Tab>
              </Tabs>
            </div>
            {currentTabKey === ONCALL_KEY &&
              <OncallDirectory></OncallDirectory>
            }
            {currentTabKey === CLINICIAN_KEY &&
              <ClinicianDirectory></ClinicianDirectory>
            }
          </div>
        ) : (
          <>
            <div className='vve-directory'>
              <ClinicianDirectory regionStyleClass={true}></ClinicianDirectory>
            </div>
          </>
        )}
        <RefreshToken />
      </>
    }
    return "";
  };

  const renderContentWithNewMenu = () => {
    const menuOptions = { menuItems, directoryRegion: region }
    return <>
      <NewDirectoryMenu {...menuOptions}></NewDirectoryMenu>
      { menuItems &&
        <>
          <InviteModal />
          <ErrorHandler />
        </>
      }
    </>
  };

  return <>
    {displayOldMenu ? (renderContent()) : (renderContentWithNewMenu())}
  </>;
}

export default DirectoryTabs;
