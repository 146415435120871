/* Module Dependencies */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

/* Store */
import { UpdateSelectedProvider } from "../../store/actions/ProviderListAction";
import { CreateDepartmentList, UpdateSelectedDepartment, setDepartmentLoader } from "../../store/actions/DepartmentListAction";

/* Constants */
import { FILTER_MODAL_TITLE, SELECT_LIST_PLACEHOLDER } from "../../constants/filter.constants";
import { STORE_NAMES } from "../../constants/store.constants";

/* Utils */
import { ValidateSearchText } from "../../helper/oncallUtils";
import { getCapitalizedName } from "../../helper/commonUtils";

/* Custom Components */
import Search from "../Search/Search";
import FilterModal from "../FilterModal/FilterModal";
import FilterBody from "./FilterBody";

import { getDepartmentList, getDepartmentsBasedOnMedicalCenters } from "../../service/oncall.service";

/* Declare the component */
const SearchDirectory = () => {
  const dispatch = useDispatch();
  const { medicalCentersListData } = useSelector(
    (state) => state[STORE_NAMES.ONCALL_DIRECTORY_MEDICALCENTERLIST]
  );
  const { directoryRegion: region = null } = useSelector((state) => state?.[STORE_NAMES.DIRECTORY_CONFIG]);

  // Declare state variables
  const [query, setQuery] = useState("");
  const [ShowFilter, setShowFilter] = useState(false);
  const [medicalCenter, setMedicalCenter] = useState("");
  const [department, setDepartment] = useState("");
  const [departmentList, setDepartmentList] = useState([]);

  const abortController = new AbortController();

  // List of params to fetch the department list
  const getParams = (params) => ({
    searchString: params,
    region
  });

  // callback to get the respone from department API
  const callBackFn = (data) => {
    dispatch(CreateDepartmentList(data));
    dispatch(setDepartmentLoader(false));
  };

  // Fetch Department list
  const fetchDeptList = (query = "") => {
    dispatch(setDepartmentLoader(true));
    getDepartmentList(getParams(query), callBackFn, abortController);
  };

  // Fetch Department list on every page load
  useEffect(() => {
    fetchDeptList();
    return () => abortController.abort();
  }, []);

  // Search to update the dept list based on user input
  const onSearchInput = (event) => {
    let { value } = event.target;
    value = ValidateSearchText(value); //not allow more than 50 characters
    if (value) onFilterReset();
    setQuery(value);
    fetchDeptList(value);
  };

  // show/hide Filter
  const toggleFilterModal = () => setShowFilter((val) => !val);

  // Reset Filter
  const onFilterReset = () => {
    setMedicalCenter("");
    setDepartment("");
  };

  // Medical center dropdown display value
  const getMedicalCenterDisplayValue = () => {
    if (medicalCenter) {
      const selectedMedicalCenter = medicalCentersListData.find(
        (val) => val.id === medicalCenter
      );
      return selectedMedicalCenter.name;
    }
    return SELECT_LIST_PLACEHOLDER;
  };

  // Handle Medical center dropdown
  const handleMedicalCenter = (val) => {
    setMedicalCenter(val);
    setDepartment("");
    const callBackFn = (data) => {
      setDepartmentList(data);
    };
    getDepartmentsBasedOnMedicalCenters(val, callBackFn, region);
  };

  // Department dropdown display value
  const getDepartmentDisplayValue = () => {
    if (department) {
      const selectedDepartment = departmentList.find(
        (val) => val.id === department
      );
      return getCapitalizedName(selectedDepartment.name);
    }
    return SELECT_LIST_PLACEHOLDER;
  };

  // Handle Department dropdown
  const handleDepartment = (val) => setDepartment(val);

  // Apply Filter
  const onFilterApply = () => {
    let data = [];
    if (department) {
      const selectedDept = departmentList.find((val) => val.id === department);
      data.push(selectedDept);
      setQuery("");
      dispatch(CreateDepartmentList(data));
    } else if (!department && medicalCenter) {
      data = departmentList;
      setQuery("");
      dispatch(CreateDepartmentList(data));
    } else {
      setQuery("");
      fetchDeptList();
    }
    dispatch(UpdateSelectedDepartment(""));
    dispatch(UpdateSelectedProvider(null));
    toggleFilterModal();
  };

  // Return component
  return (
    <>
      <Search
        query={query}
        onSearchInput={onSearchInput}
        toggleFilterModal={toggleFilterModal}
      />
      <FilterModal
        showModal={ShowFilter}
        onClose={toggleFilterModal}
        modalTitle={FILTER_MODAL_TITLE}
        modalBody={
          <FilterBody
            medicalCenterDisplayValue={getMedicalCenterDisplayValue}
            medicalCenter={medicalCenter}
            medicalCenterData={medicalCentersListData}
            handleMedicalCenter={handleMedicalCenter}
            departmentDisplayValue={getDepartmentDisplayValue}
            department={department}
            departmentData={departmentList}
            handleDepartment={handleDepartment}
          />
        }
        resetEnable={medicalCenter || department}
        onReset={onFilterReset}
        onApply={onFilterApply}
      />
    </>
  );
};

/* Export component */
export default SearchDirectory;
