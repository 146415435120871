import React from "react";
import BUTTON_CONFIG from "../../constants/button.constants";
import {
  DATE_TIME,
  DURATION,
  KPHC_CSN_ID,
  START_TIME,
  TOTAL_PARTICIPANTS,
} from "../../constants/report.constant";
import DownloadReportButton from "../Reports/DownloadReportButton";

const GroupDetailCard = (groupClassDetailProp) => {

  const { 
    groupClassDetails: {
      zoomMeetingId="",
      zoomMeetingUuid="",
      visitTypeName="",
      scheduleDateTime="",
      displayActualStartTime="",
      appointmentCSNId="",
      actualStartDateTime=""
    },
    duration="",
    participants=[]
  } = groupClassDetailProp;

  const downloadButtonProps = {
    btnClass: "vve-download-btn",
    btnLabel: BUTTON_CONFIG.DOWNLOAD_BUTTON.title,
    zoomMeetingId,
    zoomMeetingUuid,
    title: visitTypeName,
    dateTime: actualStartDateTime
  };

  return (
    <div className="vve-group-detail-card">
      <div className="row vve-card-detail">
        <div className="col-md-8 col-sm-8 col-8">
          <span className="vve-group-class-text vve-date-text">
            <span className="vve-text-bold">{DATE_TIME} </span> {scheduleDateTime}
          </span>
          <br />
          <span className="vve-group-class-text">
            <span className="vve-text-bold">{START_TIME} </span> {displayActualStartTime}
          </span>
          <br />
          <span className="vve-group-class-text">
            <span className="vve-text-bold">{KPHC_CSN_ID} </span> {appointmentCSNId}
          </span>
          <br />
          <span className="vve-group-class-text">
            <span className="vve-text-bold">{TOTAL_PARTICIPANTS} </span> {participants?.length}
          </span>
          <br />
          <span className="vve-group-class-text">
            <span className="vve-text-bold">{DURATION} </span> {duration}
          </span>
        </div>
        <div className="col-md-4 col-sm-4 col-4 vve-download-btn-div">
          <DownloadReportButton {...downloadButtonProps}></DownloadReportButton>
        </div>
      </div>
    </div>
  );
};

export default GroupDetailCard;
