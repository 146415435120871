import React from 'react'

const HeaderSection = () => {
    return (
        <div className="header-container">
            <div className="vve-launcher-bg"></div>
            {/* <div className="vve-header-Logo" role="img" tabIndex="0" /> */}
        </div>
    )
}

export default HeaderSection;