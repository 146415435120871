import React from "react";
import DownloadReportButton from "../Reports/DownloadReportButton";
import {
  DATE_TIME,
  KPHC_CSN_ID
} from "../../constants/report.constant";

const GroupClassCard = (props) => {
  const { appointmentCSNId, scheduleDateTime, visitTypeName, zoomMeetingId, actualStartDateTime, zoomMeetingUuid } = props;

  const downloadButtonProps = {
    btnClass: "vve-download-icon",
    zoomMeetingId,
    zoomMeetingUuid,
    title: visitTypeName,
    dateTime: actualStartDateTime
  };

  return (
    <div className="vve-groupclass-cards-container">
      <div className="card row vve-card">
        <div className="card-body">
          <h5 className="card-title">{visitTypeName}</h5>
          <div className="card-text row">
            <div className="vve-group-class-text col-md-8 col-sm-8 col-8">
              <span>
                <span className="vve-text-bold">{DATE_TIME}</span>
                {scheduleDateTime}
              </span>
              <br />
              <span className="vve-text-bold">{KPHC_CSN_ID}</span>
              <span>{appointmentCSNId}</span>
            </div>
            <div className="vve-text-right col-md-4 col-sm-4 col-4">
              <DownloadReportButton {...downloadButtonProps}></DownloadReportButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default GroupClassCard;
