import apiClient from '../helper/apiClient';
import { SERVICE_ROUTES } from '../constants/service.constants';

export const postGuestApi = (payload) => {
    const httpClient = apiClient.getZoomAppBffInstance();
    return httpClient.post(SERVICE_ROUTES.FETCH_GUEST_SERVICE_ROUTE, payload);
}

export const checkMultiInviteStatus = ({ payload, customTimeout, abortRequestController }) => {
    const httpClient = apiClient.getZoomAppBffInstance();
    const requestConfig = {
        timeout: customTimeout,
        signal: abortRequestController.signal
    };
    return httpClient.post(SERVICE_ROUTES.TRACK_MULTI_INVITE, payload, requestConfig);
};

export const sendC2CInvitation = (payload) => {
    const httpClient = apiClient.getZoomAppBffInstance();
    return httpClient.post(SERVICE_ROUTES.SEND_C2C_INVITE, payload);
};
  
export const checkC2CInviteStatus = ({ payload, customTimeout, abortRequestController }) => {
    const httpClient = apiClient.getZoomAppBffInstance();
    const requestConfig = {
        timeout: customTimeout,
        signal: abortRequestController.signal
    };
    return httpClient.post(SERVICE_ROUTES.TRACK_C2C_INVITE, payload, requestConfig);
};
