import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import RootReducer from "./reducers/RootReducer";
import resetInterpreterOrGuest from "./middlewares/resetInterpreterOrGuestDetailsMiddleware";

const configureStore = () => {
	const middlewares = [resetInterpreterOrGuest, thunk];
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	const enhancers = composeEnhancers(applyMiddleware(...middlewares));
	return createStore(RootReducer(), enhancers);
};

export default configureStore;