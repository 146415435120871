import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListGroup from 'react-bootstrap/ListGroup';
import { setAppSettings } from '../../store/actions/AppAction';
import Button from '../Button/Button';
import BUTTON_CONFIG from '../../constants/button.constants';
import { SELECT_REGION_PLACEHOLDER } from '../../constants/filter.constants';
import { STORE_NAMES } from '../../constants/store.constants';
import { focusTo } from '../../helper/domHelper';

const RegionList = () => {
  const dispatch = useDispatch();
  const [selectedRegion, setRegion] = useState(null);
  const { enableRegionList, displayRegionList } = useSelector(state => state?.[STORE_NAMES.APP]);

  useEffect(() => {
    if(!selectedRegion) {
      setRegion(displayRegionList[0]);
    }
  }, [selectedRegion, setRegion]);

  const setActiveRegion = (activeRegion, lineItem) => {
    return (activeRegion && lineItem && activeRegion === lineItem);
  }

  const onRegionSelection = () => {
    let appSettings = {};
    enableRegionList.forEach(val => {
      val[selectedRegion] ? appSettings = val[selectedRegion] : null;
    });
    dispatch(setAppSettings(appSettings));
  }

  const onRegionItemClick = val => {
    setRegion(val);
    focusTo("vve-select-btn");
  }

  const regionListItems = displayRegionList?.map(
    (item, index) => {
      const itemIndex = `list-item-${index}`;
      return <ListGroup.Item 
                action="true"
                id={item}
                key={itemIndex}
                role='button'
                as='button'
                eventKey={item}
                data-testid="list-item"
                active={setActiveRegion(selectedRegion,item)}
                onClick={() => onRegionItemClick(item)}>
                  {item}
              </ListGroup.Item>
    }
  );

  return (
    <div className='vve-regionlist-container'>
      <div className="region-list-header">{SELECT_REGION_PLACEHOLDER}</div>
      <ListGroup className="vve-region-list" role="document">
        {regionListItems}
      </ListGroup>
      <div className="vve-section-divider"></div>
        <div className="vve-select-btn-container">
            <Button id="vve-select-btn" disabled={!selectedRegion} onClick={onRegionSelection} >
                {BUTTON_CONFIG.REGION_SELECTION.title}
            </Button>
        </div>
    </div>
  );
};

export default RegionList;