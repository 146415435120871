import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { STORE_NAMES } from "../../constants/store.constants";
import { MONTH_SHORT_NAMES, DAYS_OF_WEEK } from "../../constants/calender.constants";
import CareGiverList from "../CareGiverList/CareGiverList";
import { getProviderList } from "../../service/oncall.service";
import { UpdateProviderList, UpdateSelectedProvider } from "../../store/actions/ProviderListAction";
import { getUTCTimestamp } from "../../helper/oncallUtils";

const Calendar = ({ loadmatrix, matrixindex, fwdbtnclick, backbtnclick }) => {
  const dispatch = useDispatch();
  const { selectedDepartment } = useSelector(
    (state) => state?.[STORE_NAMES.ONCALL_DIRECTORY_DEPARTMENTLIST]
  );
  const { directoryRegion: region = null } = useSelector((state) => state?.[STORE_NAMES.DIRECTORY_CONFIG]);

  const formatDate = (date) => {
    const now = new Date(date);
    const dayOfWeek = now.toLocaleDateString("en-US", { weekday: "long" }); // Full weekday name
    let dayOfMonth = now.getDate(); // Day of the month (1-31)
    dayOfMonth = String(dayOfMonth).padStart(2, "0");
    const month = MONTH_SHORT_NAMES[now.getMonth()];
    const year = now.getFullYear(); // Full year (e.g., 2023)

    return `${dayOfWeek}, ${month} ${dayOfMonth}, ${year}`;
  };
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dateToDisplay, setDateToDisplay] = useState(formatDate(selectedDate));
  const [loader, setLoader] = useState(false);

  const get15DaysWithCurrentDayInCenter = () => {
    const today = new Date();
    const daysBefore = Math.floor(15 / 2);
    const currentDayIndex = daysBefore;
    const daysAgo = new Date(today);
    daysAgo.setDate(today.getDate() - daysBefore);
    const days = Array.from({ length: 15 }, (_, i) => {
      const date = new Date(daysAgo);
      date.setDate(daysAgo.getDate() + i);
      return date;
    });
    return { currentDayIndex, days };
  };

  const { currentDayIndex, days } = get15DaysWithCurrentDayInCenter();

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setDateToDisplay(formatDate(date));
    fetchProviderList(selectedDepartment, date);
    dispatch(UpdateSelectedProvider(null));
  };

  const fetchProviderList = (dept, date) => {
    setLoader(true);
    const { startDateTimeUTC, endDateTimeUTC } = getUTCTimestamp(date);
    const params = {
      startDateTime: startDateTimeUTC,
      endDateTime: endDateTimeUTC,
      limit: 100,
      offset: 0,
      orderBy: "firstName",
      order: "ASC",
      region
    };
    
    getProviderList(params, dept, callBackFn);
  };

  const callBackFn = (data) => {
    dispatch(UpdateProviderList(data));
    setLoader(false);
  };

  useEffect(() => {
    if (selectedDepartment && selectedDepartment.name) {
      setSelectedDate(new Date());
      setDateToDisplay(formatDate(new Date()));
      fetchProviderList(selectedDepartment, new Date());
      if (selectedDepartment) loadmatrix(matrixindex);
    }
  }, [selectedDepartment]);

  return (
    <>
      {selectedDepartment !== "" ? (
        <div className="vve-selected-department-container" data-testid="selected-department">
          <div className="vve-oncall-calendar-container">
            <div className="vve-oncall-clinicianlist-header">
              <p>{selectedDepartment?.name}</p>
            </div>
            <div className="vve-oncall-current-date-header">
              <p>{dateToDisplay}</p>
            </div>
            <div className="vve-oncall-calendar">
              {days.map((date, index) => {
                // Extract year, month, and day from the date objects
                const dateYear = date.getFullYear();
                const dateMonth = date.getMonth();
                const dateDay = date.getDate();

                // Extract year, month, and day from the selectedDate object (if it exists)
                const selectedYear = selectedDate?.getFullYear();
                const selectedMonth = selectedDate?.getMonth();
                const selectedDay = selectedDate?.getDate();

                // Check if the year, month, and day match
                const isSelected =
                  dateYear === selectedYear &&
                  dateMonth === selectedMonth &&
                  dateDay === selectedDay;
                return (
                  <div
                    key={index}
                    className="vve-oncall-calendar-cell"
                    data-testid={"calendar-cell-"+index}
                    onClick={() => handleDateClick(date)}
                  >
                    <div className="vve-oncall-day">
                      {DAYS_OF_WEEK[date.getDay()]}
                    </div>
                    <div
                      key={index}
                      className={`vve-oncall-date-month ${index === currentDayIndex
                          ? "vve-oncall-current-day"
                          : ""
                        } ${isSelected ? "vve-oncall-selected-date" : ""}`}
                    >
                      <div className="vve-oncall-month">
                        {date
                          .toLocaleString("default", { month: "short" })
                          .substr(0, 3)}
                      </div>
                      <div className="vve-oncall-date">{date.getDate()}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <CareGiverList
            fwdbtnclick={fwdbtnclick}
            backbtnclick={backbtnclick}
            loadmatrix={loadmatrix}
            matrixindex={matrixindex}
            loader={loader}
          ></CareGiverList>{" "}
        </div>
      ) : null}
    </>
  );
};

Calendar.propTypes = {
  fwdbtnclick: PropTypes.any,
  backbtnclick: PropTypes.any,
  loadmatrix: PropTypes.any,
  matrixindex: PropTypes.any,
}

export default Calendar;
