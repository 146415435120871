import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { STORE_NAMES } from "../../constants/store.constants";
import { ShowSystemErrorModal, UpdateFavoriteClinician, UpdateSelectedClinician, UpdateFilterResetOnToggle } from '../../store/actions/ClinicianListAction';
import info from "../../assets/images/info.svg";
import Image from "../Image/Image";
import { getFavoriteList } from '../../service/favorite.service';
import DialogBox from '../DialogBox/DialogBox';
import translations from "../../translations";

const Toggle = ({ setFavoriteData, setLoaderScroll, fetchClinicianList }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {
        pageConfig,
    } = useSelector(
        (state) => state?.[STORE_NAMES.CLINICIAN_DIRECTORY_CLINICIANLIST]
    );

    useEffect(() => {
        return () => {
            dispatch(UpdateFavoriteClinician(false));
        }
    }, [])

    const onClose = () => {
        setIsModalOpen(false);
    }

    const {
        isFavoriteToggle,
    } = useSelector(
        (state) => state?.[STORE_NAMES.CLINICIAN_DIRECTORY_CLINICIANLIST]);
    const dispatch = useDispatch();

    const handleToggleFavorite = async (e) => {
        const { region } = pageConfig;
        const param = {
            region: region,
            limit: 200,
            offset: 0,
            orderBy: "lastName",
            order: "ASC",
        }
        dispatch(UpdateFilterResetOnToggle(e.target.checked))
        if (e.target.checked) {
            setLoaderScroll(true)
            const response = await getFavoriteList(param);
            setLoaderScroll(false);

            if (response?.error) {
                dispatch(ShowSystemErrorModal(true));
                return;
            }

            const data = response.data.filter((item) => item.region === region);
            setFavoriteData(data);
            if (data.length > 0) {
                dispatch(UpdateFavoriteClinician(!e.target.checked))
                setIsModalOpen(false)
            } else {
                setIsModalOpen(true)
            }

        } else {
            setFavoriteData([])
            dispatch(UpdateFavoriteClinician(e.target.checked))
            fetchClinicianList()
        }
        dispatch(UpdateSelectedClinician(null));
    }
    return (
        <div className='vve-toggle-container'>
            {isFavoriteToggle && (
                <div className='vve-partition'>
                    <Image
                        className="vve-img-Vertical"
                        src={info}
                        role="img"
                        alt="info.svg"
                    />
                    {translations.FAVORITE_SEARCH_FILTER_DISABLE_TEXT}
                </div>
            )}
            <div className='vve-fav-div' data-testid="vve-toggle-component">

                <label className='vve-fav-label'>{translations.FAVORITE}</label>

                <label className="vve-switch">
                    <input type="checkbox" checked={isFavoriteToggle} onChange={handleToggleFavorite} />
                    <span className="vve-slider vve-round"></span>
                </label>
            </div>
            {isModalOpen && <DialogBox
                classes={{
                    modalRoot: "vve-nofav-modal",
                }}
                showModal={isModalOpen}
                modalTitle={translations.NO_FAVORITE_TITLE}
                modalBody={translations.No_FAVORITE_BODY}
                onClose={onClose}

            />
            }
        </div>
    );
};

export default Toggle;
