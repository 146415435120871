import React, { useState, useEffect, useRef } from 'react';
import DialPadControl from './DialPadControl/DialPadControl';
import DialPadHeader from './DialPadHeader/DialPadHeader';
import { useDispatch, useSelector } from 'react-redux';
import { CONTEXT_ADD_GUEST, CONTEXT_ADD_INTERPRETER } from '../../constants/dialpad.constants';
import { STORE_NAMES } from '../../constants/store.constants';
import { INTERPRETER_AUDIO } from '../../constants/interpreter.constants';
import DialPadAction from '../../store/actions/DialPadAction';
import GuestAction from '../../store/actions/GuestAction';
import InterpreterAction from '../../store/actions/InterpreterAction';
import { applyDesktopClass } from '../../helper/global.utilities';

const DialPadContainer = ({ context }) => {
    const trackFirstRender = useRef(true);
    const dispatch = useDispatch();

    const [controlOptions] = useState({});
    const [controlSettings, setControlSettings] = useState({hideSpecialChars: false});

    const { hideClearButton = null } = useSelector(state => state?.[STORE_NAMES.DIALPAD]);
    const { contact_number: phoneNumber = "" } = useSelector((state) => state?.[STORE_NAMES.INTERPRETER_AUDIO_INFO]);
    const { deviceType } = useSelector((state) => state?.[STORE_NAMES.ZOOM_CLIENT]);
    const handleDialPadEmittedDetails = (details) => {
        const dialPadPayload = { ...details, context: context };
        dispatch(DialPadAction.setDialPadDetails(dialPadPayload));
    };

    useEffect(() => {
        if(context===CONTEXT_ADD_GUEST) {
            dispatch(GuestAction.setGuestInviteType(null));
        }
        if(context===CONTEXT_ADD_INTERPRETER) {
            dispatch(InterpreterAction.setInterpreterType(INTERPRETER_AUDIO));
        }
    }, [context]);

    useEffect(() => {
        setControlSettings({hideClearButton: hideClearButton});
    }, [hideClearButton]);

    useEffect(() => {
        if (trackFirstRender.current) {
            trackFirstRender.current = false;
            if((context===CONTEXT_ADD_GUEST || (context===CONTEXT_ADD_INTERPRETER && typeof phoneNumber==="string"))) {
                // reset dialpad details
                dispatch(DialPadAction.setDialPadDetails({}));
            }
        }
    });

    return (
        (context===CONTEXT_ADD_GUEST || (context===CONTEXT_ADD_INTERPRETER && typeof phoneNumber==="string")) && (
            <div className={`${applyDesktopClass(deviceType, 'vve-smalldesk-dialpad-container')} vve-dialpad-container`}>
                <DialPadHeader context={context}></DialPadHeader>
                <DialPadControl options={controlOptions} settings={controlSettings} emitDetails={handleDialPadEmittedDetails}></DialPadControl>
            </div>
        )
      )

};

export default DialPadContainer;