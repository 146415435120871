import React from "react";
import PropTypes from 'prop-types';
import dayjs from "dayjs";
import AttendeeReportFilter from "../AttendeeReportFilter/AttendeeReportFilter";
import { LEFT_SIDE_REPORT_HEADER_DATE_FORMAT as DATE_HEADER_FORMAT } from "../../constants/report.constant";

const DefaultReportsPage = ({startDate, endDate}) => {

  const renderDateText = () => {
    const startDateParsed = dayjs(startDate);
    const endDateParsed = dayjs(endDate);
    let dateText = <div className="vve-date-header">{startDateParsed.format(DATE_HEADER_FORMAT)} - {endDateParsed.format(DATE_HEADER_FORMAT)}</div>;
    if(startDateParsed.isSame(endDateParsed, 'day')) {
      dateText = <div className="vve-date-header">{startDateParsed.format(DATE_HEADER_FORMAT)}</div>;
    }
    return dateText;
  };

  return (
    <div className="vve-groupclass-list-container">
      <div className="vve-date-header-container">
        <i className="vve-calendar-icon"></i>
        {renderDateText()}
      </div>
      <div className="vve-report-filter-container">
        <AttendeeReportFilter />
      </div>
    </div>
  );
};

DefaultReportsPage.propTypes = {
  startDate: PropTypes.any,
  endDate: PropTypes.any
};

export default DefaultReportsPage;