import React from 'react';
import { SHARE_POINT_HEADER, SERVICE_NOW_HEADER, CUSTOMER_SERVICE_CLINICIAN_HEADER, CUSTOMER_SERVICE_PATIENT_HEADER } from '../../constants/interpreter.constants';
import SupportNumbersList from '../SupportNumbersList/SupportNumbersList';
import { STORE_NAMES, ACTION_TYPES } from "../../constants/store.constants";
import { useSelector, useDispatch } from "react-redux";
import translations from "../../translations";
import Button from '../../components/Button/Button';

/** Share Point Logic Start */
import ZoomAppClient from '../../helper/zoomAppClient';
/** Share Point Logic end */

const PortalAppSupportContainer = () => {
    const dispatch = useDispatch();
    const { supportNumbers, snowEnabled } = useSelector(state => state?.[STORE_NAMES.SUPPORT_TAB_INFO]);
    const patientNumbers = supportNumbers?.patients;
    const clinicianNumbers = supportNumbers?.clinician;
    const serviceNowTabSelected = () => {
        dispatch({ type: ACTION_TYPES.FETCH_SUPPORT_CURRENT_TAB, payload: 'SNOW' });
    };
    /** Share Point Logic Start */
    const { sharepointLink } = useSelector(state => state?.[STORE_NAMES.APP]);
    const handleSharePointModalContinue = () => {
        ZoomAppClient.openExternalUrl(sharepointLink);
    };
    /** Share Point Logic end */

    return (
        <div className="vve-portal-app-add-support-container">
            <section className='vve-padding-bottom'>

                <div className="row">
                    <div className="col col-md-10 vve-portal-app-add-support-header">{SHARE_POINT_HEADER}</div>
                    <div className="col col-md-2 vve-portal-app-text-right">
                        <Button onClick={handleSharePointModalContinue} class="vve-caret-btn"></Button>
                    </div>
                </div>
                <p className='vve-portal-app-support-content vve-portal-app-text-alignment'>
                    {translations.SHARE_POINT_CONTENT}
                </p>
            </section>
            {snowEnabled && (
                <>
                    <hr className="row vve-padding-top" />
                    <section className='vve-padding-bottom'>

                        <div className="row">
                            <div className="col col-md-10  vve-portal-app-add-support-header">{SERVICE_NOW_HEADER}</div>
                            <div className="col col-md-2 vve-portal-app-text-right">
                                <Button class="vve-caret-btn" onClick={serviceNowTabSelected}></Button>
                            </div>

                        </div>
                        <p className='vve-portal-app-support-content vve-portal-app-text-alignment'>
                            {translations.SERVICE_NOW_CONTENT}
                        </p>
                    </section >
                </>
            )}
            <hr className="row vve-padding-top" />
            <section className='vve-padding-bottom'>

                <div className="row">
                    <div className="col col-md-12  vve-portal-app-add-support-header">{CUSTOMER_SERVICE_CLINICIAN_HEADER}</div>
                    <div className="">
                    </div>
                </div>
                <p className='vve-portal-app-support-content'>
                    {translations.CUSTOMER_SERVICE_CLINICIAN_CONTENT}
                    <span className='vve-portal-app-support-info'>
                        {translations.CUSTOMER_SERVICE_CLINICIAN_CONTENT2}
                    </span>
                </p>
                <SupportNumbersList customerservicenumbers={clinicianNumbers} />
            </section>
            <hr className="row vve-padding-top" />
            <section className='vve-padding-bottom'>

                <div className="row">
                    <div className="col col-md-12  vve-portal-app-add-support-header">{CUSTOMER_SERVICE_PATIENT_HEADER}</div>
                    <div className="">

                    </div>

                </div>
                <p className='vve-portal-app-support-content'>
                    {translations.CUSTOMER_SERVICE_PATIENT_CONTENT1}
                    <br />
                    <span className='vve-portal-app-support-info'>
                        {translations.CUSTOMER_SERVICE_PATIENT_CONTENT2} {translations.CUSTOMER_SERVICE_PATIENT_CONTENT3}
                    </span>
                </p>

                <SupportNumbersList customerservicenumbers={patientNumbers} />
            </section>
        </div>
    );
};

export default PortalAppSupportContainer;