import { combineReducers } from "redux";
import AppReducer from "./AppReducer";
import TabReducer from "./TabReducer";
import InviteReducer from "./InviteReducer";
import InterpreterReducer from "./InterpreterReducer";
import GuestReducer from "./GuestReducer";
import ErrorHandlerReducer from "./ErrorHandlerReducer";
import DialPadReducer from "./DialPadReducer";
import ZoomClientReducer from "./ZoomClientReducer";
import AppointmentReducer from "./AppointmentReducer";
import InterpreterAudioReducer from "./InterpreterAudioReducer";
import SessionReducer from "./SessionReducer";
import DepartmentListReducer from "./DepartmentListReducer";
import MedicalCentersListReducer from "./MedicalCentersListReducer";
import ProviderListReducer from "./ProviderListReducer";
import ClinicianListReducer from "./ClinicianListReducer";
import DirectoryConfigReducer from "./DirectoryConfigReducer";
import SupportReducer from './SupportReducer';
import ReportsReducer from "./ReportsReducer";
import { STORE_NAMES } from "../../constants/store.constants";
import AbridgeReducer from "./AbridgeReducer";

const RootReducer = () =>
	combineReducers({
		[STORE_NAMES.APP]: AppReducer,
		[STORE_NAMES.TABS]: TabReducer,
		[STORE_NAMES.ERROR_HANDLER]: ErrorHandlerReducer,
		[STORE_NAMES.INVITE_BUTTON]: InviteReducer,
		[STORE_NAMES.INTERPRETER]: InterpreterReducer,
		[STORE_NAMES.GUEST]: GuestReducer,
		[STORE_NAMES.DIALPAD]: DialPadReducer,
		[STORE_NAMES.ZOOM_CLIENT]: ZoomClientReducer,
		[STORE_NAMES.INTERPRETER_AUDIO_INFO]: InterpreterAudioReducer,
		[STORE_NAMES.APPOINTMENT_DETAILS]: AppointmentReducer,
		[STORE_NAMES.SESSION_INTERVAL_DETAILS]: SessionReducer,
		[STORE_NAMES.ONCALL_DIRECTORY_DEPARTMENTLIST]: DepartmentListReducer,
		[STORE_NAMES.ONCALL_DIRECTORY_MEDICALCENTERLIST]: MedicalCentersListReducer,
		[STORE_NAMES.ONCALL_DIRECTORY_PROVIDERLIST]: ProviderListReducer,
		[STORE_NAMES.CLINICIAN_DIRECTORY_CLINICIANLIST]: ClinicianListReducer,
		[STORE_NAMES.DIRECTORY_CONFIG]: DirectoryConfigReducer,
		[STORE_NAMES.SUPPORT_TAB_INFO]: SupportReducer,
		[STORE_NAMES.REPORTS]: ReportsReducer,
		[STORE_NAMES.ABRIDGE]: AbridgeReducer
	});

export default RootReducer;