const FILTER_MODAL_TITLE = 'Filter On Call Directory';
const CLINICIAN_FILTER_MODAL_TITLE = 'Filter Directory';
const MEDICAL_CENTER_LABEL = 'Medical Center / Service Area';
const SELECT_LIST_PLACEHOLDER = 'Select from list';
const DEPARTMENT_LABEL = 'Department / Facility';
const SELECT_REGION_PLACEHOLDER = 'Select your region';
const CLINICIAN_REGION_LABEL = 'Region';
const CLINICIAN_PROVIDERTYPE_LABEL = 'Provider Type';
const ALL = 'ALL';

export {
    FILTER_MODAL_TITLE,
    MEDICAL_CENTER_LABEL,
    SELECT_LIST_PLACEHOLDER,
    DEPARTMENT_LABEL,
    SELECT_REGION_PLACEHOLDER,
    CLINICIAN_REGION_LABEL,
    CLINICIAN_PROVIDERTYPE_LABEL,
    CLINICIAN_FILTER_MODAL_TITLE,
    ALL
};
